/**
 * @description Remove some special characters, replace space to underscore and make everything lowercase
 * @param  {String} str Input string
 * @return {String} Cleaned up string
 * @example
 * cleanArtistFileName("Screen Shot 2019-11-08.png")
 * // screen_shot_2019-11-08.png
 */
export const cleanFileName = (str) => {
  return str
    .replace(/[&/\\#,+()$~%'":*?<>{}]/g, "")
    .replace(/ /g, "_")
    .toLowerCase();
};

/**
 * @description Convert Mariadb time into English readable time
 * @param  {String} str Time from mariadb
 * @return {String} Cleaned up string
 * @example
 * convertTime("2020-05-01T00:07:20.000Z")
 * // '5/1/2020, 12:07:00 AM'
 */

export const convertTime = (time) => {
  const spiltTime = time.split(":");
  spiltTime.pop();
  const timeString = spiltTime.join(":");
  const americaTime = new Date(timeString).toLocaleString("en-US");
  return americaTime;
}

/**
 * @description Convert Mariadb time into English readable time
 * @param  {String} str Time from mariadb
 * @return {String} Cleaned up string
 * @example
 * convertTime("2020-05-01T00:07:20.000Z")
 * // '5/1/2020,'
 */

export const justDate = (time) => {
  const spiltTime = time.split(":");
  spiltTime.pop();
  const timeString = spiltTime.join(":");
  const americaTime = new Date(timeString).toLocaleDateString("en-US");
  return americaTime;
}

export const dateToLocaleDateString = (date, options = {}) => {
  const dateString =  date.toLocaleDateString("en-CA",  { ...options });

  if (dateString.includes('/')) {
    const [month, date, year] = dateString.split('/');
    return `${year}-${month}-${date}`;
  }

  return dateString;
}

export const formatedDateString = (dateString) => {
    if (!dateString) {
      return '';
    }
    
    const [year, month, date] = dateString.slice(0, 10).split('-');
    return `${month}/${date}/${year}`;
}

export const capitalizeSentence = (sentence) => {
  const words = sentence.toLowerCase().split(" ");

  for (let i = 0; i < words.length; i++) {
    if(words[i][0]) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
  }

  return words.join(" ");
}