import React from "react";

import TagSettings from "./tag-settings.component";
import EmailSettings from "./email-template-settings.component";
import TermsAndConditionEditor from "./terms-and-conditions.component";
import ArtStatusExternalSettings from "./art-status-external-settings.component";

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabArea,
  TabSubLink,
  TabSubTitle,
} from "../SharedStyle/styled";

const AdminSettings = () => {
  return (
    <SubmissionContainer>
      <TabHeader>
        <TabTitle>Admin Settings</TabTitle>
        <TabSubLink to={`/admin/settings/configuration`}>
          <TabSubTitle>Configuration Settings</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/settings/dashboard`}>
          <TabSubTitle>Dashboard Settings</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/settings/designer-tips`}>
          <TabSubTitle>Designer Tips</TabSubTitle>
        </TabSubLink>
      </TabHeader>
      <TabArea>
        <TagSettings />
        <EmailSettings />
        <ArtStatusExternalSettings />
        {/* <TermsAndConditionEditor/> */}
      </TabArea>
    </SubmissionContainer>
  );
};

export default AdminSettings;