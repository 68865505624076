import React, { useEffect, useState, } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserJWTToken,
} from "../../redux/user/user.selector";
import { SubmissionContainer } from "../SharedStyle/styled";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import AdminArtApproval from "../AdminArtApproval";
import interactionPlugin from '@fullcalendar/interaction'
import { DatesSetArg, EventChangeArg, EventClickArg, EventContentArg, EventSourceInput } from "@fullcalendar/core";
import './admin-calendar.style.css';
import { Loader } from "../Common";
import { dateToLocaleDateString } from "../../utils";
import { BtnArtSubmitStyled } from "../Button/button.styles";
import { SITE_BACKEND_API_URL, SITE_BACKEND_FULL_URL } from "../../server";
import {
  TabArea,
  TabHeader,
} from "../SharedStyle/art-submissions.styles";

interface MyEventInterface {
  id: string;
  title: string;
  start: Date;
  imageUrl: string
}

const AdminCalendar = ({token}: any) => {

  const [events, setEvents] = useState<MyEventInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [scheduleType, setScheduleType] = useState('');
  const [calenderInitialDate, setCalenderInitialDate] = useState(new Date());
  const [clickedEventId, setClickedEventId] = useState<number|string|null>(null);

  useEffect(() => {
    // load events
    _fetchEvents();
  }, []);

  useEffect(() => {
    // load events
    _fetchEvents(scheduleType);
  }, [scheduleType]);

  const _fetchEvents = (type: string = '') => {
    setLoading(true);

    fetch(`/api/admin/calendar/events?type=${type}`, {
      headers: { Authorization: `JWT ${token}` },
    })
    .then((res) => res.json())
    .then(({events}) => {
        const calenderEvents = events.map((e: any) => {
          return {
            id: e.id,
            title: e.title,
            start: new Date(e.automation_schedule_date),
            type: e.automation_creation_type,
            imageUrl: e.preview_art.substring(20),
            primaryColor: `#${e.primary_color}`,
            className: "selected-event",
          };
        })

        // console.log('calenderEvents: ');
        // console.log(calenderEvents);

        setEvents(calenderEvents);
        setLoading(false);
    });
  }

  // a custom render function
  function renderEventContent({event}: any) {

    const { type, imageUrl, primaryColor } = event.extendedProps;


    if (type == 'ODAD') {
      return (
        <div data-event-id={event.id} className="event-odad">
          <div className="event-image" style={ { backgroundColor: primaryColor, width: '90px', height: '120px', textAlign: 'center'}}>
            <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={`/api/art-submissions-thumb/?src=${imageUrl}&w=90`}/>
          </div>
      </div>
      )
    } else {
      return <div data-event-id={event.id}>
        <div className={`event-name ${type.toLowerCase()}`}> {event.title}</div>
      </div>
    }
  }

  async function handleDateChange({ event, revert }: EventChangeArg) {
    
    Swal.fire({
      title: "",
      text: "Are you sure you want to change schedule date?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (response) => {
      if (response.value) {
        const requetData = {
          eventId: event.id,
          newDate: dateToLocaleDateString(event.start),
        }
    
        const {
          data
        } = await axios({
            url: `/api/admin/calendar/event/change-date`,
            method: "POST",
            data: requetData,
            headers: { Authorization: `JWT ${token}` },
        });
    
        // Removed to Avoid too many popups
        // Swal.fire({
        //   title: "The schedule date has been changed!",
        //   icon: "success",
        // });
        console.log(data);
      } else {
        revert();
      }
    });
  }

  const exportCalendar = async () => {
      const {
        data: { csvPath },
    } = await axios({
        url: SITE_BACKEND_API_URL+"/admin/calendar/export",
        method: "POST",
        data: { type: scheduleType },
        headers: { Authorization: `JWT ${token}` },
    });

    window.open(SITE_BACKEND_FULL_URL+"/"+csvPath, '_blank');
  }

  const handleMonthChange = ({view}: DatesSetArg) => {
    setCalenderInitialDate(view.currentStart)
  }

  const handleEventClick = ({event}: EventClickArg) => {
    setClickedEventId(event.id);
  }

  return <SubmissionContainer style={{ maxWidth: "86vw", width: "98%" }}>
    <TabHeader></TabHeader>
    <TabArea style={{position: "relative"}}>
      <h1 style={{textAlign: 'center', marginTop: 0}}>Scheduling Calendar</h1>
      <div style={{position: 'relative'}}>
        <div style={{position: "absolute", right: "190px", top: "0"}}>
          <div style={{display: 'flex'}}>
            <FormControlLabel
              control={
                <Checkbox color="default"
                  checked={scheduleType == 'WEEKLY'}
                  onChange={() => { 
                    scheduleType == 'WEEKLY' ? setScheduleType('') : setScheduleType('WEEKLY');
                  }}
                />
              }
              label="Weekly Only"
            />

            <FormControlLabel
              control={
                <Checkbox color="default"
                  checked={scheduleType == 'ODAD'}
                  onChange={() => { 
                    scheduleType == 'ODAD' ? setScheduleType('') : setScheduleType('ODAD');
                  }}
                />
              }
              label="ODAD Only"
            />

            <BtnArtSubmitStyled
              type="submit"
              style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px", marginTop: 0 }}
              onClick={exportCalendar}
            >
              Export
            </BtnArtSubmitStyled>
          </div>

        </div>
      </div>
      { loading ? <>
        <Loader />
        <p style={{textAlign: 'center', fontWeight: 'bold'}}>Please wait...</p>
      </> :
        <FullCalendar
          timeZone='UTC'
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          editable={true}
          selectable={true}
          events={events}
          eventContent={renderEventContent}
          eventChange={handleDateChange}
          dayMaxEvents={7}
          moreLinkText="More..."
          initialDate={calenderInitialDate}
          datesSet={handleMonthChange}
          eventClick={handleEventClick}
          eventOrder="type"
        />
      }

      { clickedEventId && 
        <AdminArtApproval
          id={clickedEventId as number}
          closeAdminArtApproval={() => setClickedEventId(null)}
          flipLeft={null}
          flipRight={null}
          isFlipLeftDisabled={false}
          isFlipRightDisabled={false}
        />
      }
    </TabArea>
  </SubmissionContainer>
}

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(AdminCalendar);
