import React from "react";

import { StyledSelect, StyledMultiSelectCheckbox } from "../FormInput";
import { MenuItem } from "@material-ui/core";

import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const CustomMultiSelectCheckbox = ({
    label,
    inputName,
    list,
    selectedValues,
    onChange
}) => (
    <div>
        <span style={{ paddingRight: "10px" }}>{label}:</span>
        <StyledSelect 
            name={inputName} 
            multiple
            value={selectedValues}
            disableUnderline={true}
            displayEmpty
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return "All";
                }

                return selected.length+" Selected ";
            }}
            onChange={onChange} >
                <MenuItem disabled value="">None</MenuItem>
                {list.map((value) => (
                    <MenuItem key={value} value={value}>
                        <StyledMultiSelectCheckbox 
                            icon={<CircleUnchecked />} 
                            checkedIcon={<CircleCheckedFilled />} 
                            checked={selectedValues.indexOf(value) > -1} />
                        {value}
                    </MenuItem>
                ))}
        </StyledSelect>
    </div>
);

export default CustomMultiSelectCheckbox;