import React from "react";

import { Tooltip, IconButton } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { CSVLink } from "react-csv";

const CustomCSVLink = ({
    title,
    filename,
    csvBodyData,
    csvHeaders,
}) => (
    <Tooltip title={title}>
        <IconButton size="small">
        <ListAltIcon fontSize={"large"} />
                <CSVLink 
                filename={filename} 
                data={csvBodyData} 
                headers={csvHeaders} 
                style={{color: "#000", textDecoration: "none"}}>
                        {title}
                </CSVLink>
        </IconButton>
    </Tooltip>
);

export default CustomCSVLink;