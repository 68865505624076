import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";
import axios from "axios";
import { SITE_BACKEND_API_URL } from "../../server";

import { selectUserJWTToken, selectUserAccount } from "../../redux/user/user.selector";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import { SubmissionContainer, TabArea } from "../SharedStyle/styled";
import {
  FormInputTitleStyled,
  TextAreaStyled,
} from "../ArtistSubmitArt/artist-submit-art.styles";

import { ReactComponent as Loading } from "../../assets/loading.svg";

import { BtnArtSubmit, InputArtPreview } from "../Button";

const Help = (props: any) => {
  const { token, userAccount } = props;

  const [ helpHasSubmitted, setHelpHasSubmitted ] = useState(false);
  const [ isDisableSubmit, setIsDisableSubmit ] = useState(false);
  const [ isSubmitted, setisSubmitted ] = useState(false);

  const helpForm = React.createRef<HTMLFormElement>();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsDisableSubmit(true);
    setHelpHasSubmitted(true);

    if(helpForm.current) {

      const { elements } = helpForm.current;
    
      const inputsDOM = Array.from(elements);

      const formData = new FormData();

      inputsDOM.forEach((el) => {
        const { name, value } = el as HTMLInputElement;

        if (value) {
          formData.append(name, value);
        }
      });

      // attachment should be at last to handle in node js backend
      inputsDOM.forEach((el) => {
        const { name, files } = el as HTMLInputElement;

        if (files && files[0]) {
          formData.append(name, files[0]);
        }
      });

     await axios.post(SITE_BACKEND_API_URL+"/artist/submissions/help", formData, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `JWT ${token}`,
        },
      });

      setIsDisableSubmit(false);
      setHelpHasSubmitted(false);
      setisSubmitted(true);
    }
  };
  

  return (
    <SubmissionContainer style={{minHeight: "800px"}}>
      <TabArea>
        <h2 style={{textAlign: "center"}}>HELP</h2>

        {!isSubmitted ?
          (<form style={{textAlign: "center"}} onSubmit={handleSubmit} ref={helpForm}>
          
            <FormInputTitleStyled
              type="text"
              name="artistName"
              label="Artist Name"
              placeholder="Artist Name"
              data-lpignore="true"
              required
              style={{width: "400px"}}
            />

            <FormInputTitleStyled
              type="text"
              name="artistEmail"
              label="Email address"
              placeholder="Email address"
              data-lpignore="true"
              autoComplete="off"
              maxlength="180"
              required
              style={{width: "400px"}}
            />
            
            <TextAreaStyled
              type="text"
              name="description"
              label="Description of Issue"
              placeholder="Description of Issue"
              data-lpignore="true"
              autoComplete="off"
              maxlength="255"
              required
              style={{width: "400px"}}
            />

            <div style={{margin: "25px 0"}}>
              <div style={{fontWeight: "bold", paddingBottom: "10px"}}>Attach screenshot (upload file): </div>
              <input type="file" name="attachment" />
            </div>

            {helpHasSubmitted ? (
                <BtnArtSubmit
                  type="submit"
                  name="submit"
                  disabled={true}
                  textAlign="center"
                  style={{
                    backgroundColor: "#0B7C80",
                    cursor: "pointer",
                    width: "95px",
                    height: "45px",
                    padding: "0",
                  }}
                >
                  <Loading />
                </BtnArtSubmit>
              ) : (
                <BtnArtSubmit
                  type="submit"
                  name="submit"
                  disabled={isDisableSubmit}
                  textAlign="center"
                  style={{ backgroundColor: "#0B7C80", cursor: "pointer" }}
                >
                  Submit
                </BtnArtSubmit>
              )}
          
        </form>) : (<h3 style={{textAlign: "center", margin: "50px", color: "green"}}>Help request sent successfuly.</h3>)}

      </TabArea>
    </SubmissionContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(Help);
