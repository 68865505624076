import React, { CSSProperties } from "react";
import { useState, useEffect } from "react";
import { Point, Dimension } from '../AdminArtSubmissions/admin-art-submission-types';
import { SITE_BACKEND_FULL_URL } from '../../server'
import TransparentImage from "../../assets/transparent.jpg";
import { ArtLargePrview } from "../Dialog/dialog.component"

interface ArtCanvasInterface {
    imgSrc: string;
    backgroundColor: string;
    artDimention: Dimension;
}

export const ArtCanvas = ({ imgSrc, backgroundColor, artDimention }: ArtCanvasInterface) => {
    
    const [isOpenPreviewDialog, setIsOpenPreviewDialog ] = useState(false);

    // Fixed canvas dimention to 12x16
    const canvasDimention = { width: 300, height: 400  } as Dimension;

    const canvasStyle = {
        border: "3px dashed #AAA", 
        margin: "auto", 
        display: "block", 
        maxWidth: "100%",
        width: `${canvasDimention.width}px`, 
        height: `${canvasDimention.height}px`,
        cursor: 'pointer',
    } as CSSProperties;

    if(backgroundColor) {
        canvasStyle.backgroundColor = backgroundColor;
    } else {
        canvasStyle.backgroundImage = 'url("'+TransparentImage+'")';
    }
    

    const getImageSrcURL = () => {
        const serverURL =  SITE_BACKEND_FULL_URL.replace('/backend', '');
        const imgPath = imgSrc.replace('api/', '');
        return serverURL+imgPath;
    }
    
    return <div>
        <div style={canvasStyle} onClick={() => setIsOpenPreviewDialog(true)}>
            <img style={{ maxWidth: "100%", maxHeight: "100%"}} src={getImageSrcURL()} />
        </div>

        <ArtLargePrview 
            imageDataUrl={getImageSrcURL()} 
            isOpen={isOpenPreviewDialog} 
            handleClose={() => setIsOpenPreviewDialog(false)}
            backgroundColor={backgroundColor}
            />
    </div>
}
