import React, { useState, useEffect } from "react";
import axios from "axios";
import { SITE_BACKEND_API_URL } from "../../server";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { selectStatusList } from "../../redux/settings/settings.selector";
import { setConfigStart } from "../../redux/settings/settings.action";

import PayoutTable from "../AdminPayout/payoutTable.component";
import { Loader } from "../Common";
import Swal from "sweetalert2";

const ArtStatusSettings = ({ token, statusList, refreshSettings }) => {
  
  const fixedStatusNames = ["NEW", "APPROVED", "DECLINED", "PENDING", "PUBLISHED", "REVIEWED", "DELETED"];
  
    const tableColumn = [
    { 
        title: "Status Name", 
        field: "status_name", 
        validate: rowData => rowData.status_name !== '' 
    }
  ];

  const handleRowAdd = async (rowData) => {
      
      if(!rowData.hasOwnProperty("status_name")) {
          Swal.fire({icon: "error", text: "Status name can not be empty!"});
          return false;
      }
      
    const reqBody = {
      url: "api/admin/settings/art-statuses/add",
      method: "POST",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    const {
      data: { tableRowData },
    } = await axios(reqBody);


    refreshSettings();

    return tableRowData;
  };

  const handleRowUpdate = async (rowData) => {
      
      if(rowData.status_name === "") {
          Swal.fire({icon: "error", text: "Status name can not be empty!"});
          return false;
      }
      
    const reqBody = {
      url: "api/admin/settings/art-statuses/update",
      method: "PUT",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    const {
      data: { tableRowData },
    } = await axios(reqBody);

    refreshSettings();

    return tableRowData;
  };

  const handleRowDelete = async (rowData) => {
    const reqBody = {
      url: "api/admin/settings/art-statuses/delete",
      method: "DELETE",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    await axios(reqBody);

    refreshSettings();

    return true;
  };
  
  const handleIsEditable = (rowData) => {
      return !fixedStatusNames.includes(rowData.status_name);
  }
  
  const handleIsDeletable = (rowData) => {
      return !fixedStatusNames.includes(rowData.status_name);
  }

  return (
    <div style={{marginTop: "20px"}}>
        {statusList.length ? (
            <PayoutTable
              tableRowsData={statusList}
              tableColumn={tableColumn}
              handleRowUpdate={handleRowUpdate}
              handleRowDelete={handleRowDelete}
              handleRowAdd={handleRowAdd}
              handleIsEditable={handleIsEditable}
              handleIsDeletable={handleIsDeletable}
              title="Art Status"
            />
          ) : null
        }
    </div>
    
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  statusList: selectStatusList,
});

const mapDispatchToProps = (dispatch) => ({
  refreshSettings: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtStatusSettings);
