import React, { useState } from "react";
import { Select, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';

interface ColorCheckboxInterface {
    colorInfo: {
        colorCode: string;
        colorName: string;
        colorId: number;
        isLightColor: 'YES'|'NO'
    },
    isChecked: boolean;
    handleColorChange: Function;
    handleMouseOver: Function;
}

const ColorCheckbox = ({colorInfo, isChecked, handleColorChange, handleMouseOver}: ColorCheckboxInterface) => {

    const colorHash = "#"+colorInfo.colorCode;
    let mouseOverTimer : ReturnType<typeof setTimeout> | null = null;

    const handleChange = () => {
        handleColorChange(colorInfo.colorId);
    }

    const StyledSelectCheckbox = withStyles({
        root: {
            padding: "0px 5px",
            margin: "3px",
        },
        colorSecondary: {
           
        }    
    })(Checkbox);

    const StyledCircleCheckedFilled = withStyles({
        root: {
            width: "35px",
            height: "35px",
            border: "1px solid #888",
            borderRadius: "30px",
            backgroundColor: (colorInfo.isLightColor == 'YES') ? "#555" : '#FFF',
        },
        colorSecondary: {
           
        }    
    })(CircleCheckedFilled);

    const _handleMouseOver = (colorHash: string, type: 'IN'|'OUT') => {

        if(mouseOverTimer !== null) {
            clearTimeout(mouseOverTimer);
        }

        mouseOverTimer = setTimeout(() => {
            handleMouseOver(colorHash, type);
        }, 750); // Delayed by 1 second to avoid normal mouse move
    }

    const circle = <div style={{backgroundColor: colorHash, width: "35px", height: "35px", borderRadius: "25px", border: "1px solid #CCC"}}></div>;

    return ( <StyledSelectCheckbox style ={{ color: colorHash, }}
        icon={circle} 
        checkedIcon={<StyledCircleCheckedFilled />} 
        checked={isChecked} 
        onChange={handleChange} 
        title={colorInfo.colorName}
        onMouseEnter={() => _handleMouseOver(colorHash, 'IN')}
        onMouseOut={() => _handleMouseOver(colorHash, 'OUT')}
        />
    );

}

export default ColorCheckbox;