import React, { useState, useEffect } from "react";
import AutocompleteSearch from "../AutocompleteSearch";
import { DropdownElementInterface, SubmissionSearchFieldInterface } from "./admin-art-submission-types";
import { dateToLocaleDateString } from "../../utils";

interface SubmissionsSearchInterface {
    search: string;
    handleAutocompleteChange: Function;
    handleAutocompleteSearch: Function;
    submissions: SubmissionSearchFieldInterface[];
}

export const AdminArtSubmissionsSearch = ({
    handleAutocompleteChange,
    handleAutocompleteSearch,
    submissions,
    search
}: SubmissionsSearchInterface) => {

    const [dropdownArr, setDropdownArr] = useState<DropdownElementInterface[]>([]);

    useEffect(() => {
        _formatDropdownArr(submissions)
    }, [submissions]);

    const _formatDropdownArr = (submissionsDetailsArr: SubmissionSearchFieldInterface[]) => {
        const dropdownArr: DropdownElementInterface[] = [
          {
            title: "Product Title",
            elements: [],
          },
          {
            title: "Artist Name",
            elements: [],
          },
          {
            title: "Date Submitted",
            elements: [],
          },
          {
            title: "Email Status",
            elements: [],
          },
          {
            title: "Tags",
            elements: [],
          },
        ];
    
        submissionsDetailsArr.forEach((submission) => {
          if (submission.title) {
            addUniqueSeachElement(dropdownArr, 0, submission.title);
          }
    
          if (submission.internalTitle) {
            addUniqueSeachElement(dropdownArr, 0, submission.internalTitle);
          }
    
          if (submission.artistName) {
            addUniqueSeachElement(dropdownArr, 1, submission.artistName)
          }
          if (submission.createdAt) {
            addUniqueSeachElement(dropdownArr, 2, dateToLocaleDateString(new Date(submission.createdAt)))
          }
          if (submission.emailStatus) {
            addUniqueSeachElement(dropdownArr, 3, submission.emailStatus)
          }
    
          if (submission.internalPrimaryTag) {
            addUniqueSeachElement(dropdownArr, 4, submission.internalPrimaryTag)
          }
    
          if (submission.internalSuggestedTags) {
            const tags = submission.internalSuggestedTags.split(',');
    
            tags.forEach((tag) => {
              addUniqueSeachElement(dropdownArr, 4, tag);
            });
          }
        });
    
        console.log(dropdownArr);
    
        setDropdownArr(dropdownArr);
      };
    
      const addUniqueSeachElement = (dropdownArr: DropdownElementInterface[], index: number, value: string) => {
        const uniqueValues = new Set(dropdownArr[index].elements);
        uniqueValues.add(value)
    
        dropdownArr[index].elements = Array.from(uniqueValues);
      }

    return (
        <AutocompleteSearch
            search={search}
            handleAutocompleteChange={handleAutocompleteChange}
            handleAutocompleteSearch={handleAutocompleteSearch}
            dropdownArr={dropdownArr}
            submitButton="Search"
            placeholder="Type 'c'"
          />
    )
}