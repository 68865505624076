import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useParams, Link } from "react-router-dom";

import { SITE_BACKEND_API_URL } from "../../server";

import { selectUserJWTToken } from "../../redux/user/user.selector";
import { selectSubmissionsError } from "../../redux/submissions/submissions.selector";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";
import { Loader } from "../Common";

import { ArtistArtCard as ArtCard } from "../ArtCards";
import ArtistSubmissionsView from "../ArtistSubmissionsView";
import ArtistSubmissionsEdit from "../ArtistSubmissionsEdit";
import { ReactComponent as AdjustablesIcon } from "../../assets/adjustables.svg";
import { selectExternalStatusList } from "../../redux/settings/settings.selector";
import { MainButton } from "../Button"

import {
  SubmissionContainer,
  TabHeader,
  TabSubTitle,
  TabSubLink,
  TabTitle,
  TabArea,
  FilterHeader,
  StatusHeader,
  AdjustableIconWrapper,
  FilterContainer,
  FilterLink,
  ArtCardContainer,
  StatusButtonsContainer,
} from "../SharedStyle/art-submissions.styles";

import NoArtImage from "../../assets/no-arts-by-status.png";
import {
  NoRecordImgWrapper,
  NoRecordImg,
} from "../ArtistHome/artist-home.styles";

const ArtistArtSubmissions = ({ token, artStatusList }) => {
  const params = useParams();
  const [state, setState] = useState({
    search: "",
    isShowingFilter: false,
    status: "NEW",
    imageSrc: teefuryBirdLogo,
    submissionsArr: [],
    submissionsArrIndex: 0,
    submissionCard: null,
    id: 0,
    isSubmissionsView: false,
    isSubmissionsEdit: false,
    isFlipLeftDisabled: false,
    isFlipRightDisabled: false,
    loading: true,
  });
  const {
    isShowingFilter,
    status,
    submissionsArr,
    submissionsArrIndex,
    id,
    isSubmissionsView,
    isSubmissionsEdit,
    isFlipLeftDisabled,
    isFlipRightDisabled,
    loading,
  } = state;

  useEffect(
    () => { 
      setState({
        ...state,
        submissionsArr: [],
      });

      const status = _getCurrentPath();
      _getSubmissions(status);
    },
    // eslint-disable-next-line
    [params]
  );

  const _getCurrentPath = () => {
    const status = params.status.toUpperCase();

    return status;
  };

  const toggleFilterArea = () => {
    const { isShowingFilter } = state;
    setState({ ...state, isShowingFilter: !isShowingFilter });
  };

  const _getSubmissions = async (status) => {
      
    setState({...state, submissionsArr: [], loading: true, status});
  
    const {
      data: { submissionsDetailsArr },
//    } = await axios.get(`/api/artist/submissions/${status}`, {
    } = await axios.get(SITE_BACKEND_API_URL+`/artist/submissions/list/${status}`, {        
      headers: { Authorization: `JWT ${token}` },
    });

    console.log(submissionsDetailsArr);

    setState({
      ...state,
      status,
      isShowingFilter: false,
      submissionsArr: submissionsDetailsArr,
      isSubmissionsView: false,
      isSubmissionsEdit: false,
      loading: false,
    });
  };

  const openSubmissionsView = (event) => {
    const { id } = event.currentTarget;
    const index = parseInt(event.target.getAttribute("data-index"), 10);
    const isFlipLeftDisabled = index === 0 ? true : false;
    const isFlipRightDisabled =
      index === submissionsArr.length - 1 ? true : false;

    setState({
      ...state,
      id: id,
      submissionsArrIndex: index,
      isSubmissionsEdit: false,
      isSubmissionsView: true,
      isFlipLeftDisabled,
      isFlipRightDisabled,
    });
  };

  const openSubmissionsEdit = (id) => {
    setState({
      ...state,
      id: id,
      submissionsArrIndex: 0,
      isSubmissionsView: false,
      isSubmissionsEdit: true,
      isFlipLeftDisabled,
      isFlipRightDisabled,
    });
  };

  const closeSubmissionsView = () => {
    setState({
      ...state,
      isSubmissionsView: false,
      submissionsArr: [],
    });

    const status = _getCurrentPath();
    _getSubmissions(status);
  };

  const closeSubmissionsEdit = () => {
    setState({
      ...state,
      isSubmissionsEdit: false,
      submissionsArr: [],
    });

    const status = _getCurrentPath();
    _getSubmissions(status);
  };

  const flipLeft = () => {
    if (submissionsArrIndex > 0) {
      let previousIndex = submissionsArrIndex - 1;
      const newCardId = submissionsArr[previousIndex].id;
      let isFlipLeftDisabled = previousIndex === 0 ? true : false;

      setState({
        ...state,
        id: newCardId,
        submissionsArrIndex: previousIndex,
        isFlipLeftDisabled,
        isFlipRightDisabled: false,
      });
    }
  };

  const flipRight = () => {
    if (submissionsArrIndex < submissionsArr.length - 1) {
      let nextIndex = submissionsArrIndex + 1;
      const newCardId = submissionsArr[nextIndex].id;
      const isFlipRightDisabled =
        nextIndex === submissionsArr.length - 1 ? true : false;

      setState({
        ...state,
        id: newCardId,
        submissionsArrIndex: nextIndex,
        isFlipLeftDisabled: false,
        isFlipRightDisabled,
      });
    }
  };

  const handleEditExit = () => {
    console.log('handleEditExit last: ')
    setState({ 
      ...state, 
      isSubmissionsEdit: false, 
      isSubmissionsView: true 
    });
  }

  const displayView = () => {
    if (isSubmissionsView) {
      return (<ArtistSubmissionsView
        id={id}
        closeSubmissionsView={closeSubmissionsView}
        onSubmissionsEdit={openSubmissionsEdit}
        flipLeft={flipLeft}
        flipRight={flipRight}
        isFlipLeftDisabled={isFlipLeftDisabled}
        isFlipRightDisabled={isFlipRightDisabled}
      />)
    } else if(isSubmissionsEdit) {
      return (<ArtistSubmissionsEdit onExit={handleEditExit} id={id} />)
    } else {
      return (<ArtCardContainer>
          {submissionsArr.length > 0 ? (
            submissionsArr.map((submissionDetails, i) => {
              return (
                <ArtCard
                  key={i}
                  {...submissionDetails}
                  token={token}
                  index={i}
                  openSubmissionsEdit={openSubmissionsView}
                />
              );
            })
          ) : (
            <>
            {loading ? ( <Loader /> ) : (
            <NoRecordImgWrapper>
              <NoRecordImg src={NoArtImage}/>
            </NoRecordImgWrapper>
            )}
          </>
              )}
        </ArtCardContainer>)
    }
  } 

  return (
    <SubmissionContainer>
      <TabHeader>
        <TabSubLink to={`/artist/submissions/`}>
          <TabSubTitle>Submit Artwork</TabSubTitle>
        </TabSubLink>
        <TabTitle>Submissions</TabTitle>
      </TabHeader>
      <TabArea>
        <FilterHeader>
          <StatusHeader>
          <h2 style={{textAlign: "center"}}>{status}</h2>
            {status === "DECLINED" ? (
              <p style={{ textAlign: "center" }}>
                Note: Declined submissions more than 90 days old will have their
                preview file deleted.
              </p>
            ) : (
              ""
            )}
          </StatusHeader>
          {/* <AdjustableIconWrapper onClick={toggleFilterArea}>
            <AdjustablesIcon />
          </AdjustableIconWrapper>
          {isShowingFilter ? (
            <FilterContainer>

              { artStatusList.map(artStatus => (  
                <FilterLink
                  to={'/artist/submissions/'+artStatus}
                  data-filter={artStatus}
                  status={status === artStatus ? "selected" : ""}
                >
                  { artStatus }
                </FilterLink>
              ))}
            </FilterContainer>
          ) : null} */}
        </FilterHeader>
        
        <StatusButtonsContainer>
          { artStatusList.map(artStatus => ( 
            artStatus !== 'DELETED' ?
              (status.trim().toLowerCase() === artStatus.trim().replaceAll(' ', '-').toLowerCase() ? 
                <MainButton type="button" style={{width: "140px", cursor: "default", textTransform: 'capitalize' }}>{artStatus.toLowerCase()}</MainButton>
              : 
                <Link to={'/artist/submissions/'+artStatus.replaceAll(' ', '-')}>
                  <MainButton type="button" style={{width: "140px", backgroundColor: "lightgray", textTransform: 'capitalize' }}>{artStatus.toLowerCase()}</MainButton>
                </Link>
              )
            : null
          ))}
        </StatusButtonsContainer>

        { displayView() }
      </TabArea>
    </SubmissionContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  submissionsError: selectSubmissionsError,
  artStatusList: selectExternalStatusList,
});

export default connect(mapStateToProps)(ArtistArtSubmissions);
