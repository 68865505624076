import React from "react";

import Nav from "../../components/Nav";
import ArtistDashboard from "../../components/ArtistDashboard";
import ArtistProfile from "../../components/ArtistProfile";
import { ArtistContainer } from "../ArtistProfilePage/artist-profile-page.styles";

const DashboardPage = () => (
  <ArtistContainer>
    <Nav />
    <ArtistDashboard />
    <ArtistProfile />
  </ArtistContainer>
);

export default DashboardPage;
