
const size = {
    mobileS:  '320px',
    mobileM:  '375px',
    mobileL:  '425px',
    tablet:   '768px',
    desktopS: '1024px',
    desktopM: '1440px',
    desktopL: '2560px'
};

export const device = {
    mobileS:  `(max-width: ${size.mobileS})`,
    mobileM:  `(max-width: ${size.mobileM})`,
    mobileL:  `(max-width: ${size.mobileL})`,
    tablet:   `(max-width: ${size.tablet})`,
    desktopS: `(max-width: ${size.desktopS})`,
    desktopM: `(max-width: ${size.desktopM})`,
    desktopL: `(max-width: ${size.desktopL})`,
};