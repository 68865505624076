import { createSelector } from "reselect";

const selectSettings = (state) => state.settings;

export const selectColors = createSelector(
  [selectSettings],
  (settings) => settings.data.colors
);

export const selectStatusList = createSelector(
  [selectSettings],
  (settings) => settings.data.statusList
);

export const selectExternalStatusList = createSelector(
  [selectSettings],
  (settings) => { 
    const externalStatusNames = settings.data.statusList.map(s => s.external_status_name);

    // Return unique
    return [...new Set(externalStatusNames)];
  }
);

export const selectScheduleTags = createSelector(
  [selectSettings],
  (settings) => settings.data.scheduleTags
);

export const selectExcludeProducts = createSelector(
  [selectSettings],
  (settings) => settings.data.excludeProducts
);

export const selectTags = createSelector(
  [selectSettings],
  (settings) => settings.data.tags
);

export const selectProductTypes = createSelector(
  [selectSettings],
  (settings) => settings.data.productTypes
);

export const selectProductGroups = createSelector(
  [selectSettings],
  (settings) => settings.data.productGroups
);

export const selectSuggestedTags = createSelector(
  [selectSettings],
  (settings) => settings.data.suggestedTags
);

export const selectSuggestedTagNames = createSelector(
  [selectSettings],
  (settings) => settings.data.suggestedTags.map(s => s.tag_name)
);