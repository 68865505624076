import React from "react";
import { SelectColumnFilter, 
  SelectColumnFilterPaymentType, 
  SelectColumnFilterGroup, 
  SelectColumnFilterProductType,
  SelectColumnFilterStatus 
} from "../../libs/table";

export const TABLE_COLUMNS = [
  {
    Header: "Database Row Id",
    accessor: "dbRowId",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Created Date",
    accessor: "order_created_at",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Refund Date",
    accessor: "canceled_date",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Order #",
    accessor: "order",
    filter: "text",
    disableSortBy: true,
  },
  {
    Header: "Title",
    accessor: "product_title",
    filter: "fuzzyText",
    disableSortBy: true,
  },
  {
    Header: "Artist",
    accessor: "artist",
    filter: "fuzzyText",
    Cell: (event) => (
      <a href={`/admin/artists-profiles?artist=${event.value}`}>
        {event.value}
      </a>
    ),
    disableSortBy: true,
  },
  {
    Header: "Product Type",
    accessor: "product_type",
    Filter: SelectColumnFilterProductType,
    filter: "equals",
    disableSortBy: true,
  },
  {
    Header: "Status",
    accessor: "order_status",
    Filter: SelectColumnFilterStatus,
    filter: "equals",
    disableSortBy: true,
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Commissions Amount",
    accessor: "commissions_amount",
    disableFilters: true,
  },
  { 
    Header: "Negative Commissions",
    accessor: "negative_commissions",
    disableFilters: true,
    className: 'cell-center',
    disableSortBy: true,
  },
  {
    Header: "Donation Amount",
    accessor: "tip_amount",
    disableFilters: true,
  },
  {
    Header: "Group",
    accessor: "group",
    Filter: SelectColumnFilterGroup,
    filter: "equals",
    disableSortBy: true,
  },
  {
    Header: "Paid or Unpaid",
    accessor: "is_commissions_paid",
    Filter: SelectColumnFilterPaymentType,
    filter: "equals",
    disableSortBy: true,
  },
  {
    Header: "Tags",
    accessor: "tags",
    filter: "fuzzyText",
    disableSortBy: true,
  },
];