import React, { Component } from "react";

import {
  SignUpContainer,
  H1,
  H2,
  Img,
} from "../SharedStyle/signin-signout.styles";
import logo from "../../assets/logo.png";

import { SITE_BACKEND_API_URL } from "../../server";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { Loader } from "../Common";

import axios from 'axios';

class TermsAndCondition extends Component {

    constructor(props) {
        super(props);
        this.state = { terms: "", isLoading: true};
    }

    componentDidMount() {
        // this._getTerms();

        const { token } = this.props;
        axios({
            url: SITE_BACKEND_API_URL+"/common/api/gettermspage",
            method: "GET",
            data: {},
            headers: { Authorization: `JWT ${token}` },
        })
        .then(terms => {
            this.setState({ terms : terms.data, isLoading: false });
        })
    }

  render() { 

    const { terms, isLoading } = this.state;

    return (
      <SignUpContainer style={{ maxWidth: "890px", textAlign: "left" }}>
        <H2 style={{ textAlign: "center", marginTop: "25px" }}>
          <Img src={logo} alt="Teefury Logo" />
          Tee<b>Fury</b>
        </H2>
        <H1>Artist Agreement</H1>
        { isLoading ? <Loader /> : (
          <div dangerouslySetInnerHTML={{ __html: terms}}></div>
        )}
      </SignUpContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(TermsAndCondition);