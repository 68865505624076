import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";

import { SITE_BACKEND_API_URL } from "../../server";

import { ArtistTable } from "../Table";
import DashboardCalender from "../Common/dashboard-calender.component";

import { HomeSubmissionContainer, HomeTabArea } from "../SharedStyle/styled";
import { Loader } from "../Common";
import NoTopPerformingImage from "../../assets/no-top-performing.png";
import {
  NoRecordImgWrapper,
  NoRecordImg,
} from "./artist-home.styles";

const TABLE_COLUMNS = [
  {
    Header: "",
    accessor: "serial",
    disableFilters: true,
    className: 'cell-s-no cell-center',
    Cell: (row) => <div className="cell-s-no-value">{row.value}</div>
  },
  {
    Header: "DESIGN",
    accessor: "product_title",
    disableFilters: true,
  },
  {
    Header: "PAID COMMISSIONS",
    accessor: "total_paid_commissions_amount",
    disableFilters: true,
    className: 'cell-center',
  },
  {
    Header: "UNPAID COMMISSIONS",
    accessor: "total_unpaid_commissions_amount",
    disableFilters: true,
    className: 'cell-center',
  }
];

class ArtistCommissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      errorMsg: "",
      loading: true,
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  componentDidMount() {
    this.getTop5Commissions();

    const { startDate, endDate } = this.state;
    startDate.setMonth(endDate.getMonth() - 1);
    
    this.setState({ startDate });
  }

  setTableData = (data) => {
    this.setState({ tableData: data });
  };

  getTop5Commissions = async () => {
    try {
        const { token } = this.props;
        const { startDate, endDate } = this.state;

        this.setState({ loading: true });
        
        const {
            data: { commissionsDetailsArr },
        } = await axios.post(SITE_BACKEND_API_URL+"/artist/commissions/gettop5", 
        {
          startDate, endDate
        },
        {
            headers: { Authorization: `JWT ${token}` },
        });

        // Add serial number
        commissionsDetailsArr.forEach((commission, index) => { commission.serial = index + 1; });

        this.setState({ tableData: commissionsDetailsArr, loading: false });
    } catch (error) {
        this.setState({ errorMsg: "We could not find any records. Let us know if its a mistake."});
    }
  };

  render() {
    const { tableData, errorMsg, loading, startDate, endDate } = this.state;
    return (
      <HomeTabArea style={{ height: "90%" }}>
        <DashboardCalender
          handleDateFilter={this.getTop5Commissions}
          globalStartDate={startDate}
          globalEndDate={endDate}
          calenderLabel="Filter By Date"
        />

        {tableData.length > 1 ? (
          <ArtistTable className="home-table" columns={TABLE_COLUMNS} data={tableData} showPagination={false}/>
        ) : errorMsg ? (
          <h2>{errorMsg}</h2>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
            <NoRecordImgWrapper>
              <NoRecordImg src={NoTopPerformingImage}/>
            </NoRecordImgWrapper>
            )}
          </>
        )}
      </HomeTabArea>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(ArtistCommissions);
