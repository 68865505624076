const SubmissionActionTypes = {
  CLEAR_ALL_SUBMISSIONS_DETAILS: "CLEAR_ALL_SUBMISSIONS_DETAILS",
  SET_ALL_SUBMISSION_DETAILS: "SET_ALL_SUBMISSION_DETAILS",
  // Submit Artwork
  SUBMISSION_CREATE_START: "SUBMISSION_CREATE_START",
  SUBMISSIONS_EDIT_START: "SUBMISSIONS_EDIT_START",
  // Submission messages
  SUBMISSION_SUCCESS: "SUBMISSION_SUCCESS",
  SUBMISSION_ADD: "SUBMISSION_ADD",
  SUBMISSION_FAILURE: "SUBMISSION_FAILURE",
  // Alerts
  SUBMISSIONS_SUCCESS_ALERT: "SUBMISSIONS_SUCCESS_ALERT",
  SUBMISSIONS_SUCCESS_ALERT_CLEAR: "SUBMISSIONS_SUCCESS_ALERT_CLEAR",
  SUBMISSIONS_ERROR_ALERT: "SUBMISSIONS_ERROR_ALERT",
  SUBMISSIONS_ERROR_ALERT_CLEAR: "SUBMISSIONS_ERROR_ALERT_CLEAR",
  // Get All of the submissions by this particular artist
  SUBMISSIONS_GET_ALL_START: "SUBMISSIONS_GET_ALL_START",
  SUBMISSIONS_GET_ALL_SUCCESS: "SUBMISSIONS_GET_ALL_SUCCESS",
  SUBMISSIONS_GET_ALL_FAILURE: "SUBMISSIONS_GET_ALL_FAILURE",
  // Create blobs for the submissions for performance
  SUBMISSIONS_CREATE_BLOB_START: "SUBMISSIONS_CREATE_BLOB_START",
  SUBMISSIONS_CREATE_BLOB_SUCCESS: "SUBMISSIONS_CREATE_BLOB_SUCCESS",
};

export default SubmissionActionTypes;
