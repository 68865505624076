import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Nav from "../../components/Nav";
import ArtReviewReporting from "../../components/AdminReporting/art-review-report.component";
import ArtistsReporting from "../../components/AdminReporting/artists-report.component";

import { PageContainer, PageWrapper } from "../SharedStyle/style";

const AdminReportingPage = () => {
  const { path } = useRouteMatch();
  return (
    <PageContainer>
      <Nav />
      <Switch>
      <Route exact path={path}> 
          <PageWrapper>
            <ArtReviewReporting />
          </PageWrapper>
        </Route>

        <Route exact path={`${path}/art-review`}> 
          <PageWrapper>
            <ArtReviewReporting />
          </PageWrapper>
        </Route>

        <Route exact path={`${path}/artists`}> 
          <PageWrapper>
            <ArtistsReporting />
          </PageWrapper>
        </Route>

      </Switch>
    </PageContainer>
  );
};

export default AdminReportingPage;
