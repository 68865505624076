import React, { useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
// import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import ArtTypeAllOverImage from "../../assets/art-type-all-over.png";
import ArtTypeClassicImage from "../../assets/art-type-classic.png";
import { BtnArtSubmit } from "../Button";
import { Loader } from "../Common";

import { ArtworkType, ProductCreationTypes } from "../../constants";
import { SITE_BACKEND_FULL_URL } from '../../server';
import axios from "axios";
import Swal from "sweetalert2";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: "none",
  },
}))(MuiDialogContent);

// const DialogActions = withStyles((theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(1),
//   },
// }))(MuiDialogActions);

export function ArtworkTypeInfoDialog({artworkType, isOpen, handleClose }) {
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
        <DialogContent dividers>
            { artworkType === ArtworkType.classic ? ( 
                <div style={{display: "flex"}}>
                    <div><img alt="" src={ArtTypeClassicImage} style={{width: "300px"}}/></div>
                    <div style={{marginTop: "20px", marginLeft: "30px", fontWeight: "bold"}}>
                        Classic Artwork
                        <p style={{ color: "#888", fontSize: "14px" }}>Max print area: 12 x 16; Image will be adjusted based on product type</p>
                        <p style={{ color: "#888", fontSize: "14px" }}>File types: psd, ai, png (at 300dpi)</p>
                    </div>
                </div>
            ) : ( 
                <div style={{display: "flex"}}>
                    <div><img alt="" src={ArtTypeAllOverImage} style={{width: "300px"}}/></div>
                    <div style={{marginTop: "20px", marginLeft: "30px", fontWeight: "bold"}}>
                        All Over Print Specifications
                        <div style={{ color: "#888", fontSize: "14px" }}>
                          <p>Accepted File types: ai, jpeg, or psd</p>
                          <p>Artwork dimensions: 60”x100”</p>
                          <p>Pattern: Must be seamless design (no visible crop marks)</p>
                          <p>Max File Size: 10MB</p>
                          <p>Export Requirements:<br/>- Color Mode: RGB<br/>- Resolution: 72 ppi/dpi</p>
                        </div>
                    </div>
                </div>
            )}
        </DialogContent>
        
        { /*
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Save changes
          </Button>
        </DialogActions>
        */ }
      </Dialog>
    </div>
  );
}

const ImagePreviewDialog = withStyles((theme) => ({
  paperWidthSm: {
    maxWidth: "none"
  },
}))(Dialog);

export function ArtLargePrview({ imageDataUrl, isOpen, handleClose, backgroundColor = 'none' }) {
  return (
    <div>
      <ImagePreviewDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
        <DialogContent dividers>
          {imageDataUrl ? (
            <img src={imageDataUrl} style={{ width: "800px", backgroundColor }} />
          ) : null }
        </DialogContent>
      </ImagePreviewDialog>
    </div>
  );
}

export function AllOverPrintExDialog({ imageDataUrl, isOpen, handleClose }) {
  return (
    <div>
      <ImagePreviewDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
        <DialogContent dividers >
          {imageDataUrl ? (
            <img src={imageDataUrl} style={{width: "600px"}} />
          ) : null }
        </DialogContent>
      </ImagePreviewDialog>
    </div>
  );
}

export function ImportProductCreationDialog({ isOpen, handleClose, handleDone }) {
  const [loading, setLoading] = useState(false);
  const submissionForm = React.createRef();

  const importFile = async (event) => {
    event.preventDefault();

    try {

      const { elements } = submissionForm.current;

      const inputsDOM = Array.from(elements);

      const formData = new FormData();

      inputsDOM.forEach((el) => {
        console.log(el);

        const { files, name, value } = el ;
        if (files) {
          console.log("Files found: ");
          console.log(files);
          formData.append(name, files[0]);
        } else if (value) {
          console.log(name);
          formData.append(name, value);
        }
      });
    
      const importURL = SITE_BACKEND_FULL_URL.includes('localhost') ? 
      'http://localhost/teefury/odad-automation/generate/import' 
      : (SITE_BACKEND_FULL_URL.includes('staging') 
        ? 'https://phpstack-953066-3524313.cloudwaysapps.com/odad-automation-staging/generate/import' 
        : 'https://phpstack-953066-3320435.cloudwaysapps.com/ODAD/generate/import');

      setLoading(true);

      const {
        data: { status, text, data: { csvURL } },
      } = await axios.post(importURL, formData, {
        headers: {
        },
      });

      if(status != 'success') {
        handleDone();
        
        Swal.fire({
          icon: "error",
          title: text,
        });
      } else {

        window.open(csvURL, '_blank');

        handleDone();

        Swal.fire({
          icon: "success",
          title: "Product generated and downloaded successfully.",
        });
      }

      setLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

  }

  return (
    <div>
      <ImagePreviewDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <h2 style={{margin: 0}}>Import Product Creation</h2>
        </DialogTitle>
        <DialogContent dividers >
            <div style={{width: "500px"}}>
              {loading ? <>
                  <Loader/>
                  <p style={{fontWeight: 'bold', textAlign: 'center'}}>Please wait...</p>
                </> 
                : 
                <form
                  onSubmit={importFile}
                  ref={submissionForm}
                >
                  <div>
                    <select name="creationType" style={{fontSize: "18px", padding: "10px"}}>
                      <option value="">Select creation type</option>
                      { ProductCreationTypes.map(type => 
                        <option value={type}>{type}</option>
                      )}
                    </select>
                  </div>
                  <div>
                    <input name="csvInput" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{fontSize: "18px", margin: "25px 0"}} type="file"/>
                  </div>
                  <div>
                    <a href={`${SITE_BACKEND_FULL_URL}/files/sample-monster-file.csv`} target="_blank">Download sample</a>
                  </div>

                  <BtnArtSubmit
                      type="submit"
                      name="submit"
                      style={{ backgroundColor: "#0B7C80", cursor: "pointer", width: "200px"}}
                    >
                      Import
                  </BtnArtSubmit>
                </form>
              }
            </div>
        </DialogContent>
      </ImagePreviewDialog>
    </div>
  );
}