import React from "react";
import { useState } from "react";
import { ArtworkType } from "../../constants";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ArtworkTypeInfoDialog } from "../Dialog";

import {
  ArtworkTypeWrapper,
  ArtworkTypeOption,
} from "./artist-submit-art.styles";

export const ArtworkTypeSelector = ({ onChange }: { onChange: Function }) => {

  const [artworkType, setArtworkType] = useState(ArtworkType.classic);
  const [openedDialogArtworkType, setOpenedDialogArtworkType] = useState(ArtworkType.classic);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleArtTypeChange = (type: string) => {
    setArtworkType(type);
    onChange(type);
  }

  const openDiaog = (e: any, artworkType: string) => {
    setIsDialogOpen(true);
    setOpenedDialogArtworkType(artworkType)
    e.stopPropagation();
  }

  const closeDiaog = () => {
    setIsDialogOpen(false);
  }
  
  return <>
  <ArtworkTypeWrapper>
    <ArtworkTypeOption onClick={() => handleArtTypeChange(ArtworkType.classic)} className={['left', ( artworkType === ArtworkType.classic ? 'active' : '' )]}>
        <div style={{display: "flex", justifyContent: "center"}}>
            <div>Classic Artwork</div>
            <div><HelpOutlineIcon onClick={(e) => openDiaog(e, ArtworkType.classic)} style={{marginLeft: "10px"}} /></div>
        </div>
    </ArtworkTypeOption>
    <ArtworkTypeOption onClick={() => handleArtTypeChange(ArtworkType.allOver)} className={['right', ( artworkType === ArtworkType.allOver ? 'active' : '' )]}>
        <div style={{display: "flex", justifyContent: "center"}}>
            <div>All Over/Patterned Artwork</div>
            <div><HelpOutlineIcon onClick={(e) => openDiaog(e, ArtworkType.allOver)} style={{marginLeft: "10px"}} /></div>
        </div>
    </ArtworkTypeOption>
  </ArtworkTypeWrapper>
  <ArtworkTypeInfoDialog artworkType={openedDialogArtworkType} isOpen={isDialogOpen}  handleClose={closeDiaog} />
</>
}