import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Nav from "../../components/Nav";
import AdminSettings from "../../components/AdminSettings";
import ConfigurationSettings from "../../components/AdminSettings/configuration-settings.component";
import DashboardSettings from "../../components/AdminSettings/dashboard-settings.component";
import DesignerTipsSettings from "../../components/AdminSettings/designer-tips-settings.component";

import { PageContainer, PageWrapper } from "../SharedStyle/style";

const AdminSettingsPage = () => {
  const { path } = useRouteMatch();
  return (
    <PageContainer>
      <Nav />
      <Switch>
        <Route exact path={path}> 
          <PageWrapper>
            <AdminSettings />
          </PageWrapper>
        </Route>
        <Route exact path={`${path}/configuration`}> 
          <PageWrapper>
            <ConfigurationSettings />
          </PageWrapper>
        </Route>
        <Route exact path={`${path}/dashboard`}> 
          <PageWrapper>
            <DashboardSettings />
          </PageWrapper>
        </Route>
        <Route exact path={`${path}/designer-tips`}> 
          <PageWrapper>
            <DesignerTipsSettings />
          </PageWrapper>
        </Route>
      </Switch>
    </PageContainer>
  );
};

export default AdminSettingsPage;