import React, { CSSProperties } from "react";
import { useState, useEffect } from "react";
import { ArtworkType } from "../../constants";

import {
  InstructionPoint,
  BottomInstruction,
  BoldSpan,
} from "./artist-submit-art.styles";

interface FileRequirementsProps {
  artworkType: String,
}

export const FileRequirements = ({artworkType}: FileRequirementsProps) => {

  return artworkType == ArtworkType.classic ?
    (<div>
      <p style={{ maxWidth: "80%",margin: 'auto', fontWeight: 'bold', fontSize: "20px", color: '#555' }}>
        File Requirements
      </p>
      <div style={{maxWidth: "80%", margin: 'auto', display: 'flex', justifyContent: 'space-between' }}>
        <InstructionPoint>
          <div>Transparent</div>
          <div>.PNG file</div>
        </InstructionPoint>
        <InstructionPoint>
          <div>Minimum 150 dpi</div>
          <div>Maximum 300 dpi</div>
        </InstructionPoint>
        <InstructionPoint>
          <div>Canvas size:</div>
          <div>1800 x 2400 pixels</div>
        </InstructionPoint>
        <InstructionPoint>
          <div>Max file size is</div>
          <div>15MB</div>
        </InstructionPoint>
      </div>
      <div style={{ maxWidth: "50%", margin: 'auto', marginTop: "30px", textAlign: 'center' }}>
        If you have any questions or concerns about the submission process, please contact us at <a href="mailto:art@teefury.com">art@teefury.com</a> for assistance.
        <p>NEW! You can now upload multiple designs at once. Simply select multiple files from your computer during upload.</p>
      </div>
    </div>) : 
    (
      <BottomInstruction>
        <div>
          <p className="heading">File Requirements</p>
          <div>Please submit a <BoldSpan>high resolution, seamless pattern as a JPEG</BoldSpan> file at <BoldSpan>72 dpi</BoldSpan>.</div>
          <div>Required dimensions are 60”x 100”.</div>
          <div>Max file size is 15MB.</div>
          <div style={{ marginTop: "10px" }}>If you have any questions or concerns about the submission process, please contact us at <a href="mailto:art@teefury.com">art@teefury.com</a> for assistance.</div>
        </div>
        </BottomInstruction>
    )
}