import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectArtistProfile } from "../../redux/artist/artist.selector";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { artistErrorAlertClear } from "../../redux/artist/artist.action";
import axios, { AxiosRequestConfig } from "axios";
import { SITE_BACKEND_FULL_URL } from '../../server'
import Swal from "sweetalert2";
import { 
  DialogTitle,
  DialogContent,
} from '../Dialog/dialog.component';
import { MainButton, ButtonMd } from "../Button";

import Dialog from '@material-ui/core/Dialog';
import {
  TextAreaStyled,
} from "../ArtistSubmissionsEdit/artist-submissions-edit.styles";
import halloweenImage from "../../assets/halloween.png";

import { 
  Header,
  DashboardWrapper,
  ArtistNameTitle,
  Description,
  WhatsSectionWrapper,
  WhatsSection,
  WhatsSectionTitle,
  WhatsSectionTile,
  TrendingList,
  Trending,
  Art,
  ArtCategory,
  FavoriteSection,
  FavoriteTile,
  TipsWrapper,
  Tip,
  TipContent,
  DidWeMissWrapper,
  ImageCard,
} from "./artist-dashboard.styles"
import HeaderBanner from "../../assets/dashboard/banner.jpg"
import DesignerTipBanner from "../../assets/dashboard/banner2.jpg"

import type { IFanFavorite, IComingSoon, ITrend } from '../AdminSettings/DashboardSettings/types';

const darkBlurColor = '#0b7c80';

const HighLightedText = ({children}: {children: string}) => 
  <span style={{color: darkBlurColor, fontWeight: 'bold'}}>{children}</span>

const ArtistDashboard = (props: any) => {

  const [favorites, setFavorites] = useState<IFanFavorite[]>([]);
  const [trendings, setTrendings] = useState<ITrend[]>([]);
  const [comingSoon, setComingSoon] = useState<IComingSoon[]>([]);
  const [userMessage, setUserMessage] = useState('');
  const [isOpenMessageBox, setIsOpenMessageBox] = useState(false);
  const [haloweenSettings, setHaloweenSettings] = useState<any>({});

  useEffect(() => {
    console.log(props.token);

    axios.get("api/admin/settings/dashboard/list", {
      headers: { Authorization: `JWT ${props.token}` },
    }).then((res) => {
      setTrendings(res.data.trendings);
      setComingSoon(res.data.comingSoon);
      setFavorites(res.data.favorites);
      setHaloweenSettings(res.data.haloweenSettings[0]);
    });
  }, [])

  const getImageSrcURL = (imgFilename: string) => {
    const serverURL =  SITE_BACKEND_FULL_URL.replace('/backend', '');
    return serverURL+'/uploads/favorites/'+imgFilename;
  }

  const images = require.context('../../assets/dashboard/', true);

  const openMessageBox = () => {
    setIsOpenMessageBox(true);
  }

  const handleChangeMessage = (e: any) => {
    setUserMessage(e.target.value);
  }

  const sendMessage = async () => {
    if(!userMessage) {
      setIsOpenMessageBox(false);
      Swal.fire({icon: "error", text: "Please enter message!"});
      return false;
    }

    const reqBody = {
      url: "api/admin/settings/dashboard/send-user-message",
      method: "POST",
      headers: { Authorization: `JWT ${props.token}` },
      data: { userMessage },
    } as AxiosRequestConfig;

    const {
      data: { tableRowData },
    } = await axios(reqBody);

    setIsOpenMessageBox(false);
    Swal.fire({
      icon: "success",
      text: "Message sent successfully.",
      showConfirmButton: true,
    });
  }

  const handleCloseMessageBox = () => {
    setIsOpenMessageBox(false);
  }

  return <DashboardWrapper>
      <Header>
        <img src={HeaderBanner} style={{maxWidth: "100%", borderRadius: "30px"}}/>
        <ArtistNameTitle>Welcome, {props.artistProfile.artistName}!</ArtistNameTitle>
      </Header>

      <Description>
        Welcome to the <HighLightedText>TeeFury Artist Dashboard!</HighLightedText> This is your one-stop inspiration station
        with trending themes, upcoming releases, tips & tricks, and more! Check out the
        information below or use the navigation on the left-hand side to view your personal
        information, submissions and commission information. If you have any questions
        feel free to drop us a line at <HighLightedText>art@teefury</HighLightedText> and we'll be happy to help!
      </Description>

      <WhatsSectionWrapper>
        <WhatsSection>
          <WhatsSectionTitle>WHAT'S TRENDING NOW</WhatsSectionTitle>
          <WhatsSectionTile>
            <TrendingList>
            { trendings.map((trending) => ( 
                <Trending>{trending.trend_title}</Trending>
              ))}
            </TrendingList>
          </WhatsSectionTile>
        </WhatsSection>
        <WhatsSection>
          <WhatsSectionTitle>WHAT'S COMING SOON</WhatsSectionTitle>
          {/* Before Halloween
          <WhatsSectionTile>
            { comingSoon.map((art) => ( 
              <Art>{art.title} <ArtCategory>{art.category}</ArtCategory></Art>
            ))}

            <DidWeMissWrapper>
              <HighLightedText>Did we miss anything?</HighLightedText>
              <img style={{width: "50px", marginLeft: "10px", cursor: 'pointer'}} src={images('./message.png')} onClick={openMessageBox}/>
            </DidWeMissWrapper>
          </WhatsSectionTile> */}
          {/* Halloween Special */}
          <WhatsSectionTile>
            <div style={{padding: '0 20px'}}>
              <img src={getImageSrcURL(haloweenSettings.banner_image)} alt="halloween" style={{ width: "100%" }} />
              { haloweenSettings.description_text && <div className="content" dangerouslySetInnerHTML={{__html: haloweenSettings.description_text.replace(/(?:\r\n|\r|\n)/g, '<br/>')}}></div> }

              <ButtonMd onClick={() => window.location.href = "/#/artist/submissions/"} style={{cursor: 'pointer'}}>
                <span style={{color: "#000"}}>{ haloweenSettings.cta_button_text }</span>
              </ButtonMd>
            </div>
          </WhatsSectionTile>
        </WhatsSection>
      </WhatsSectionWrapper>

      <WhatsSectionTitle style={{marginTop: "50px"}}>RECENT FAN FAVORITES</WhatsSectionTitle>
      <FavoriteSection>
          { favorites.map((favorite) => ( 
              <FavoriteTile>
                <div>
                  <ImageCard src={getImageSrcURL(favorite.image_path)}/>
                </div>
                <div style={{padding: "10px 10px 0px 10px", fontWeight: "bold", color: "#555", wordBreak: "break-word"}}>{favorite.title}</div>
                <div style={{padding: "0 10px", color: "#6CB6BB", fontWeight: "bold", wordBreak: "break-word"}}>{favorite.artist_name}</div>
              </FavoriteTile>
          ))}
      </FavoriteSection>

      <div>
        <img src={DesignerTipBanner} style={{maxWidth: "100%", borderRadius: "30px", marginTop: "50px"}}/>
      </div>

      <TipsWrapper>
        <Tip>
            <img style={{width: "100%"}} src={images('./tip1.png')}/>
            <TipContent>
              <h2 style={{color: darkBlurColor}}>Setting up your file.</h2>
              Make sure to properly set up your files, to ensure a high quality design for print. 
              <b> Anything higher than 150 PPI will work.</b> Your design will automatically be scaled to fit within a 12x16in artboard, 
              so setting the following dimensions will allow you to visualize the size of your design.
            </TipContent>
        </Tip>
        <Tip>
          <img style={{width: "100%"}} src={images('./tip2.png')}/>
          <TipContent>
            <h2 style={{color: darkBlurColor}}>Transparency and opacity.</h2>
            Transparency in designs can sometimes get lost in the final print. 
            For areas where you want the design to “fade” or be less opaque, 
            <b> make sure your opacity is set to any number roughly above 20%</b> to ensure the color is visible in the final print.
          </TipContent>
        </Tip>
        <Tip>
          <img style={{width: "100%"}} src={images('./tip3.png')}/>
          <TipContent>
            <h2 style={{color: darkBlurColor}}>Preparing your art for product creation.</h2>
            Don't forget to <b> test your final design against our offered tee colors. </b> 
            Choosing the appropriate color will allow your design to stand out and visually pop!
          </TipContent>
        </Tip>
      </TipsWrapper>

      <Dialog onClose={handleCloseMessageBox} open={isOpenMessageBox}>
        <DialogTitle id="customized-dialog-title" onClose={handleCloseMessageBox}></DialogTitle>
        <DialogContent dividers style={{textAlign: 'center'}}>

          <div style={{textAlign: "left", fontWeight: "bold", color: "#555", margin: "0 18px"}}>
            <p style={{marginTop: "-10px"}}>Did we miss something?</p>
            <p>Let us know which upcoming movie, tv show, anime or video game you're looking forward to, below!</p>
          </div>
          
          <TextAreaStyled
            type="text"
            name="message"
            label="Message"
            data-lpignore="true"
            autoComplete="off"
            handleChange={handleChangeMessage}
            value={userMessage}
            maxlength="1000"
            required
            style={{width: "88%", height: "200px", resize: 'none', borderRadius: "15px", fontSize: "18px" }}
          />

          <MainButton 
            type="button" 
            style={{width: "200px", cursor: "default", fontSize: "18px", cussor: 'pointer', marginBottom: "20px" }} 
            onClick={sendMessage}>
              Send Message
          </MainButton>
        </DialogContent>
      </Dialog>
  </DashboardWrapper>;
};

const mapStateToProps = createStructuredSelector({
  artistProfile: selectArtistProfile,
  token: selectUserJWTToken,
});

const mapDispatchToProps = (dispatch: any) => ({
  clearMsg: () => dispatch(artistErrorAlertClear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistDashboard);
