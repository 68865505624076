import React, { MouseEventHandler } from "react";
import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import { IconButton } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { ReactComponent as ApprovedIcon } from "../../assets/approved.svg";
import { ReactComponent as NewIcon } from "../../assets/approved.svg";

export const Loader = () => (
    <div style={{ display: "flex", justifyContent: "center", padding: "50px",}}>
        <img className="rotating" src={teefuryBirdLogo} alt="tee bird" style={{ height: "100px", width: "100px" }} />
    </div>
);

const images = require.context('../../assets/', true);

interface DownloadButtonInterface {
    title: string;
    handleDownload: MouseEventHandler;
}

export const DownloadButton = ({title, handleDownload}: DownloadButtonInterface) => (
    <IconButton size="small" onClick={handleDownload}>
        <ListAltIcon fontSize={"large"} />
        <div>{title}</div>
    </IconButton>
);

export interface DateRangeInterface {
    startDate: Date;
    endDate: Date;
}

export var COMMON_DATE_RANGE: DateRangeInterface = {
    startDate: new Date(),
    endDate: new Date(),
    
}

export var COMMON_DATE_PROP = {
    isDateOpened: false,
}

export interface TableColumnProp {
    Header: string;
    accessor: string;
    disableFilters?: boolean;
    disableSortBy?: boolean;
    isHidden?: boolean;
    className?: string;
    filter?: string;
}

export interface CSVHeaderInterface {
    key: string;
    label: string;
}

export const DEFAULT_PAGE_SIZE = 20;

export const ApprovedFlag = () => <ApprovedIcon width={20} height={20} fill="#00e991" style={{padding: '0px 5px'}} />
export const NewArtistFlag = () => <div style={{display: 'flex'}}>
        <img style={{width: "20px", marginRight: '5px'}} src={images('./new-icon.png')}/>
        <span style={{fontSize: '16px', color: '#6CB6BB'}}>New Artist</span>
    </div>