import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";

import { selectUserJWTToken, selectUserAccount } from "../../redux/user/user.selector";
import { COMMON_DATE_RANGE, DateRangeInterface, COMMON_DATE_PROP, TableColumnProp } from "../Common";

import { AdminTable } from "../Table";
import TableQueries from "../Table/table-queries.component";
import { stausReportTable } from "../../utils/fetchCommForTable";
import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import CustomCSVLink from "../Common/custom-csv-link-component";

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabArea,
  TabSubLink,
  TabSubTitle,
  ExportToolbar,
} from "../SharedStyle/styled";
import { dateToLocaleDateString } from "../../utils";

export const TABLE_COLUMNS: TableColumnProp[] = [
  {
    Header: "Artist Name",
    accessor: "artist_name",
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: "Account Creation Date",
    accessor: "account_creation_date",
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: "Artist Email",
    accessor: "artist_email",
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: "Sent Artwork",
    accessor: "sent_artwork_count",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Published",
    accessor: "published_count",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Approved",
    accessor: "approved_count",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Denied",
    accessor: "denied_count",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "ODAD",
    accessor: "odad_count",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Weekly or Gallery",
    accessor: "weekly_or_gallery_count",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Sold Qty",
    accessor: "sold_qty",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Paid Commission",
    accessor: "paid_commission",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Unpaid Commission",
    accessor: "unpaid_commission",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Donations",
    accessor: "donations",
    disableFilters: true,
    disableSortBy: false,
  },
  {
    Header: "Total payment",
    accessor: "total_payment",
    disableFilters: true,
    disableSortBy: false,
  }
];

const newCsvHeaders = TABLE_COLUMNS.map(col => ({label: col.Header, key: col.accessor}))

interface ArtistsReportProp { 
  token: String 
};

const ArtistsReport = (props: ArtistsReportProp) => {
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState(COMMON_DATE_RANGE);
  const [isCommLoading, setIsCommLoading] = useState(true);
  const { startDate, endDate } = dateRange;
  const { token } = props;

  useEffect(
    () => {
      _getStatusReport(startDate, endDate);
    },
    //eslint-disable-next-line
    []
  );

  const _getStatusReport = async (
    startDate: Date,
    endDate: Date,
  ) => {

    const start = dateToLocaleDateString(new Date(startDate)); 
    // .toLocaleDateString("en-CA");
    const end = dateToLocaleDateString(new Date(endDate));
    // .toLocaleDateString("en-CA");

    const reqBody = {
      method: "POST",
      data: {
        startDate: start,
        endDate: end,
      },
      url: "/api/admin/reportings/artists-report"
    };

    try {
      const tableData = await stausReportTable(
        reqBody,
        token
      );

      setIsCommLoading(false);
      setTableData(tableData);

      return tableData;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
      return;
    }
  };

  const handleDateFilter = async ({ startDate, endDate }: DateRangeInterface) => {
    try {
        
        COMMON_DATE_RANGE.startDate = new Date(startDate + " 00:00:00");
        COMMON_DATE_RANGE.endDate = new Date(endDate + " 00:00:00");
        
      const tableData = await _getStatusReport(
        startDate,
        endDate
      );

      setTableData(tableData);
      setDateRange({
        startDate,
        endDate,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

    return;
  };
  
  return (
    <SubmissionContainer>
      <TabHeader>
        <TabSubLink to={`/admin/reporting/art-review`}>
          <TabSubTitle>Art Review reports</TabSubTitle>
        </TabSubLink>
        <TabTitle>Artists</TabTitle>
      </TabHeader>
      <TabArea>
        <TableQueries
          handleDateFilter={handleDateFilter}
          globalStartDate={startDate}
          globalEndDate={endDate}
          isDateOpened={COMMON_DATE_PROP.isDateOpened}
          note="Note: Default is today."
        />
        {isCommLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "50px",
            }}
          >
            <img
              className="rotating"
              src={teefuryBirdLogo}
              alt="tee bird"
              style={{ height: "100px", width: "100px" }}
            />
          </div>
        ) : tableData.length > 1 ? (
          <>
              <ExportToolbar>
                <div style={{ marginRight: "50px" }}>
                  <CustomCSVLink  
                    title="Export CSV"
                    filename={"artist-report.csv"} 
                    csvBodyData={tableData} 
                    csvHeaders={newCsvHeaders} 
                    />
                </div>
              </ExportToolbar>

          <div style={{ overflowX: "scroll" }}>
            <div style={{ width: "140%" }}>
              <AdminTable
                columns={TABLE_COLUMNS}
                setTableData={setTableData}
                startDate={startDate}
                endDate={endDate}
                data={tableData}
                token={token}
              />
            </div>
          </div>
          </>
        ) : (
          <h2> No Records Found </h2>
        )}
      </TabArea>
    </SubmissionContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(ArtistsReport);
