import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useParams, useHistory } from "react-router-dom";
import { SITE_BACKEND_API_URL, SITE_BACKEND_FULL_URL } from "../../server";
import {
  selectUserAccount,
  selectUserJWTToken,
} from "../../redux/user/user.selector";

import { selectScheduleTags, selectStatusList, selectColors } from "../../redux/settings/settings.selector";

import axios from "axios";
import Swal from "sweetalert2";

import { UserRole, WindowsSavedData, ARTS_PER_PAGE, AutomationAllowedInStatus, MAX_ART_SELECTION_ALLOWED } from "../../constants";

import Calendar from "./calendar.component";
import { AdminArtCard as ArtCard } from "../ArtCards";
import BulkEmail from "./bulk-email.component";
import AdminArtApproval from "../AdminArtApproval";
import { BtnArtSubmit } from "../Button";

import { Loader } from "../Common";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";
import { ReactComponent as AdjustablesIcon } from "../../assets/adjustables.svg";

import { Checkbox, FormControlLabel, MenuItem, Select } from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroller';
import { AdminArtAutomationForm } from "./admin-art-automation-form.component";
import { AdminArtSubmissionsSearch } from "./admin-art-submissions-search-component";
import { 
  SubmissionInterface, 
  SubmissionSearchFieldInterface,
  GetSubmissionsResponseInterface,
  LogInterface,
  ArtStatusInterface,
  ScheduleTagInterface,
  EmailTemplateInterface,
  ColorsListInterface,
} from "./admin-art-submission-types";

import { ImportProductCreationDialog } from "../Dialog";

import {
  SubmissionContainer,
  TabArea,
  FilterHeader,
  TabHeader,
  TabTitle,
  TabSubLink,
  TabSubTitle,
  ArtCardContainer,
} from "../SharedStyle/art-submissions.styles";
import { dateToLocaleDateString } from "../../utils";
import { generateProducts, exportToDesktop, isSameCreationTypeSelected } from "../../utils/generateProducts";
import { buttonAndTextFontStyle, filterDropdownStyle } from "./common-styles";
import AdminArtHeaderForm from './admin-art-header-form.component';

const TopFilterTitle = (props: any) => {
  const { heading, title } = props;
  return <div style={{fontWeight: "bold", fontSize: "18px", color: "#AAA", padding: "10px 0"}}>{heading} <span style={{color: "#000"}}>{title}</span></div>
}

interface SubmissionUpdateInterface {
  status: string;
  scheduleType: string;
  emailTemplateId: number;
}

interface AdminArtSubmissionsGridInteface {
  userAccount: any;
  token: string;
  colorsList: ColorsListInterface[];
  scheduleTags: ScheduleTagInterface[];
  artStatusList: ArtStatusInterface[];
}

interface GetEmailTemplateInterface {
  emailTemplatesArr: EmailTemplateInterface[], 
};

type ArtistType = 'NEW' | 'APPROVED';

const AdminArtSubmissionsGrid = (props: AdminArtSubmissionsGridInteface) => {
  const {
    userAccount: { contactEmail, userRole },
    token,
    scheduleTags,
    artStatusList,
    colorsList,
  } = props;

  type Params = { status: string, id: string };

  const params = useParams<Params>();
  const [state, setState] = useState({
    search: "",
    isShowingFilter: false,
    status: "NEW",
    imageSrc: teefuryBirdLogo,
    submissionsArrIndex: 0,
    id: 0,
    scheduleType: '',
    isAnArtCardSelected: false,
    isAdminArtApproval: false,
    isAdminArtDecline: false,
    isFlipLeftDisabled: false,
    isFlipRightDisabled: false,
    loading: true,
    loadingExport: false,
  });
  let [selectionCount, setSelectionCount] = useState(0);
  const [showOnlyArtistTypes, setShowOnlyArtistTypes] = useState<ArtistType[]>([]);
  const [submissionsArr, setSubmissionsArr] = useState<SubmissionInterface[]>([]);
  const [submissionsSearchData, setSubmissionsSearchData] = useState<SubmissionSearchFieldInterface[]>([]);
  const [submissionUpdate, setSubmissionUpdate] = useState<SubmissionUpdateInterface>({
    status: 'NEW',
    scheduleType: "",
    emailTemplateId: 0
  });

  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplateInterface|null>(null);
  const [selectedSubmissionIds, setSelectedSubmissionIds] = useState<number[]>([]);
  const [allSubmissionIds, setAllSubmissionIds] = useState<number[]>([]);

  // To Do: This can be use to make searchParams cosistancy all places
  const [searchParams, setSearchParams] = useState<Record<string, any>>({});

  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 4)),
    endDate: new Date(),
  });

  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [hasMorePage, setHasMorePage] = useState(false);

  const [openImportCreationDialog, setOpenImportCreationDialog ] = useState(false);

  const { startDate, endDate } = dateRange;
  const [isSelectedAllSubmission, setIsSelectedAllSubmission] = useState(false);

  const {
    search,
    status,
    submissionsArrIndex,
    id,
    scheduleType,
    isAdminArtApproval,
    isAdminArtDecline,
    isFlipLeftDisabled,
    isFlipRightDisabled,
    loading,
  } = state;

  const history = useHistory();

  useEffect(() => {

    const status = _getCurrentPath();

    // Check if id parameter is passed
    const { id } = params;
    if (id) {
      // setTimeout(function(){
      //   console.log('Opening....');
      //   setState({
      //     ...state,
      //     id: parseInt(id),
      //     isAdminArtApproval: true,
      //   });
      // }, 5000)
    }

  }, []);

  useEffect(
    () => {
      setSubmissionsArr([]);
      
      const status = _getCurrentPath();
      if (status === "DECLINED" || status === "DELETED") {
        _getSubmissionsByDate(startDate, endDate);
      } else {
        _getSubmissions(status);
      }

      // setSubmissionUpdate({ ...submissionUpdate, status });
    },
    // eslint-disable-next-line
    [params]
  );

   const applyFilter = (event: any) => {
       
       const scheduleType = event.target.value;
       
        const status = _getCurrentPath();
        if (status === "DECLINED" || status === "DELETED") {
          _getSubmissionsByDate(startDate, endDate, scheduleType);
        } else {
          _getSubmissions(status, scheduleType);
        }
   };

  const applyFlaggedArtist = (artistType: ArtistType) => {
    
    // setShowOnlyApprovedArtist(isFlagged);
    
    let newTypes = showOnlyArtistTypes;

    if (newTypes.includes(artistType)) {
      var index = newTypes.indexOf(artistType);
      if (index !== -1) {
        newTypes.splice(index, 1);
      }
    } else {
      newTypes.push(artistType);
    }

    setShowOnlyArtistTypes(newTypes);

    const status = _getCurrentPath();
    if (status === "DECLINED" || status === "DELETED") {
      _getSubmissionsByDate(startDate, endDate, scheduleType, newTypes);
    } else {
      _getSubmissions(status, scheduleType, newTypes);
    }
  };

  const changeStatusFilter = (event: any) => {
    const selectedStatus = event.target.value;
    history.push(selectedStatus);
  }

  const createImages = async () => {
    const creationType = isSameCreationTypeSelected(submissionsArr, selectedSubmissionIds)

    if(creationType === false) {
      return false;
    }

    setState({...state, loading: true });

    const {
        data: filePaths,
    } = await axios({
        url: SITE_BACKEND_API_URL+"/admin/odad/exportAllArts",
        method: "POST",
        data: { showOnlyArtistTypes, scheduleType, selectedSubmissionIds },
        headers: { Authorization: `JWT ${token}` },
    });

    // Push to print server (Don't use await to push to server, this creates unneccessarily waiting)
    axios({
      url: SITE_BACKEND_API_URL+"/admin/odad/pushArtsToPrintServer",
      method: "POST",
      data: { ...filePaths, sendToImageProgram: 'YES', creationType },
      headers: { Authorization: `JWT ${token}` },
    });

    setState({...state, loading: false });
    
    Swal.fire({
      title: "The art files sent to Image Creation successfully!",
      icon: "success",
    });

    setSelectedSubmissionIds([]);
    setSelectionCount(0);
    setIsSelectedAllSubmission(false);
  }

  // const exportSubmission = async () => {
  //   const {
  //       data: { csvPath },
  //   } = await axios({
  //       url: SITE_BACKEND_API_URL+"/admin/commissions/exportCSVSubmissions",
  //       method: "POST",
  //       data: { selectedSubmissionIds: selectedSubmissionIds },
  //       headers: { Authorization: `JWT ${token}` },
  //   });

  //   console.log(csvPath);

  //   window.open(SITE_BACKEND_FULL_URL+"/"+csvPath, '_blank');
  // }

  // const updateSubmission = async () => {

  //   // Open email popup here
  //   // alert("Open email popop");
  //   if(submissionUpdate.emailTemplateId) {
  //     setState({
  //       ...state,
  //       isAdminArtDecline: true,
  //     });
  //   } else {

  //     try {
  //       await axios.put(`/api/admin/submissions/update-status`, { ...submissionUpdate, ids: selectedSubmissionIds }, {
  //         headers: { Authorization: `JWT ${token}` },
  //         }
  //       );

  //       Swal.fire({
  //         icon: "success",
  //         title: "Arts updated successfully.",
  //       });

  //       setSelectedSubmissionIds([]);

  //       // Refresh page to update changes
  //       setTimeout(function(){
  //           window.location.reload();
  //         }, 2000);

  //     } catch (error) {
  //       console.log(error);
  //       Swal.fire({
  //         icon: "error",
  //         title: "Something went wrong. Please try again.",
  //       });
  //     }
  //   }
  // }

  const onSentBulkEmail = async () => {
    await axios.put(`/api/admin/submissions/update-status`, { ...submissionUpdate, ids: selectedSubmissionIds }, {
      headers: { Authorization: `JWT ${token}` },
      }
    );

    setState({ ...state, isAdminArtDecline: false });

    _getSubmissions('NEW');
  }
  
  const _getCurrentPath = () => {
    return params.status.toUpperCase();
  };

  const _getSubmissions = async (status: any, scheduleType = "", artistTypes: ArtistType[] = []) => {
    
    if(!scheduleType) {
        scheduleType = "";
    }
    
    console.log(scheduleType);
    
    setState({...state, loading: true, status, scheduleType});

    // const fetchOnlyApprovedArtist = onlyApprovedArtist ? 'YES' : 'NO';
  
    setTotalCount(0);
    setSelectionCount(0);

    const searchParamsJson = { scheduleType, artistTypes: artistTypes.join(','), search };
    const searchParamsString = new URLSearchParams(searchParamsJson).toString();

    setSearchParams(searchParamsJson)

    const {
      data: { submissionsDetailsArr, submissionsSearchArr, pageCount, totalCount: totalRecordsCount },
    } = await axios.get<GetSubmissionsResponseInterface>(`/api/admin/submissions/${status}?${searchParamsString}`, {
      headers: { Authorization: `JWT ${token}` },
    });

    console.log(submissionsDetailsArr);

    // submissionsDetailsArr.forEach((submission) => {
    //   submission.isSelected = false;
    // });

    setSelectedSubmissionIds([]);
    setSelectionCount(0);
    setIsSelectedAllSubmission(false);

    if(submissionsSearchArr) {
      setSubmissionsSearchData(submissionsSearchArr);
      setAllSubmissionIds(submissionsSearchArr.map((s) => s.id))
    }

    setState({
      ...state,
      status,
      scheduleType,
      isShowingFilter: false,
      isAdminArtApproval: false,
      isAdminArtDecline: false,
      loading: false,
    });
    setSubmissionsArr(submissionsDetailsArr);

    if(pageCount > 1) {
      setPageNo(1);
      setHasMorePage(true);
    } else {
      setHasMorePage(false);
    }

    setTotalCount(totalRecordsCount);

    return submissionsDetailsArr;
  };
  
  const _getSubmissionsByDate = async (startDate: any, endDate: any, scheduleType = "", artistTypes: ArtistType[] = []) => {
      
    const start = dateToLocaleDateString(new Date(startDate))
    // .toLocaleDateString("en-CA", {
    //   timeZone: "GMT",
    // });
    const end = dateToLocaleDateString(new Date(endDate))
    // .toLocaleDateString("en-CA", {
    //   timeZone: "GMT",
    // });

    // const fetchOnlyApprovedArtist = onlyApprovedArtist ? 'YES' : 'NO';

    const status = _getCurrentPath();

    const searchParamsJson = { status, start, end, scheduleType, search, artistTypes };
    setSearchParams(searchParamsJson)

    try {
        setState({...state, loading: true, status, scheduleType});
        setTotalCount(0);
      const {
        data: { submissionsDetailsArr, submissionsSearchArr, pageCount, totalCount: totalRecordsCount },
      } = await axios.post<GetSubmissionsResponseInterface>(
        `/api/admin/submissions/by-date`,
        searchParamsJson,
        {
          headers: { Authorization: `JWT ${token}` },
        }
      );

      // submissionsDetailsArr.forEach((submission) => {
      //   submission.isSelected = false;
      // });

      setSelectedSubmissionIds([]);

      if(submissionsSearchArr) {
        // _formatDropdownArr(submissionsSearchArr);
        setSubmissionsSearchData(submissionsSearchArr);
      }

      setState({
        ...state,
        scheduleType,
        status,
        loading: false,
        isShowingFilter: false,
        isAdminArtApproval: false,
      });
      setSubmissionsArr(submissionsDetailsArr);
      setPageNo(1);

      if(pageCount > 1) {
        setHasMorePage(true);
      } else {
        setHasMorePage(false);
      }

      setTotalCount(totalRecordsCount);

      return submissionsDetailsArr;
    } catch (error) {
        setState({...state, loading: false});
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }
  };

  const onEmailTemplateSelector = (selectedEmailTemplate: EmailTemplateInterface, submissionUpdate: SubmissionUpdateInterface) => {
    setState({
      ...state,
      isAdminArtDecline: true,
    });

    setSelectedEmailTemplate(selectedEmailTemplate)
    setSubmissionUpdate(submissionUpdate);
  }

  const hideEmailTemplateSelector = () => {
    setState({
      ...state,
      isAdminArtDecline: false,
    });
  }

  const handleAutocompleteChange = (event: any, { newValue, method }: any) => {
    setState({
      ...state,
      search: newValue,
    });
  };

  const handleAutocompleteSearch = async (event: any) => {
    if (event) {
      event.preventDefault();
    }

    try {
      if (status === "DECLINED" || status === "DELETED") {
        _getSubmissionsByDate(
          startDate,
          endDate
        );
      } else {
        _getSubmissions(status);
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Something went wrong. Please try again. 2222");
    }
  };

  const toggleFilterArea = () => {
    const { isShowingFilter } = state;
    setState({ ...state, isShowingFilter: !isShowingFilter });
  };

  const handleSelectArtCard = (event: any) => {

    /* Ignoring action for "view only" user */
    if(userRole === UserRole.ADMIN_VIEW_ONLY) {
      return true;
    }

    // if (status === "NEW" || status === "PENDING" || status === "DELETED") {
      const {
        id,
        dataset: { selected },
      } = event.currentTarget;

      const newSelectedSubmissionIds = [...selectedSubmissionIds];

      submissionsArr.forEach((submission) => {
        if (submission.id == id) {
          if (selected === "false") {
            newSelectedSubmissionIds.push(submission.id);
            selectionCount++;
          } else {
            const idIndex = newSelectedSubmissionIds.indexOf(submission.id);
            if (idIndex > -1) {
              newSelectedSubmissionIds.splice(idIndex, 1); // 2nd parameter means remove one item only
            }
            selectionCount--;
          }
        }
      });

      if (newSelectedSubmissionIds.length <= MAX_ART_SELECTION_ALLOWED) {
        setSelectedSubmissionIds(newSelectedSubmissionIds);
        setSelectionCount(selectionCount);
      } else {
        Swal.fire({
          icon: "error",
          title: `You can select maximum ${MAX_ART_SELECTION_ALLOWED} arts to process at once!`,
        });
      }


      // check if removed all
      if (selectionCount == 0) {
        // Reset creation fields
        // setCreationType(null);
        // setScheduleDate(null);
        // setODADPosition(null);
      }
    // }
  };

  const openAdminArtApproval = (event: any) => {
    event.stopPropagation();
    
    // Save current scroll position
    WindowsSavedData.scrollTop = document.documentElement.scrollTop;
    
    const { id } = event.target;
    const index = parseInt(event.target.getAttribute("data-index"), 10);
    const isFlipLeftDisabled = index === 0 ? true : false;
    const isFlipRightDisabled =
      index === submissionsArr.length - 1 ? true : false;

    // submissionsArr.forEach((submission) => {
    //   if (submission.isSelected === true) {
    //     submission.isSelected = false;
    //   }
    // });

    setSelectedSubmissionIds([]);

    setSubmissionsArr(submissionsArr);
    setSelectionCount(0);
    setState({
      ...state,
      id: id,
      submissionsArrIndex: index,
      isAdminArtApproval: true,
      isFlipLeftDisabled,
      isFlipRightDisabled,
    });
    
    // Set window scroll position to top 
      window.scrollTo(0, 100);
  };

  const closeAdminArtApproval = () => {
    setState({
      ...state,
      isAdminArtApproval: false,
    });
    setSubmissionsArr([]);
    
    const { scheduleType } = state;

    // Reset array for admin
    const status = _getCurrentPath();
    if (status === "DECLINED" || status === "DELETED")  {
      _getSubmissionsByDate(startDate, endDate, scheduleType);
    } else {
      _getSubmissions(status, scheduleType);
      
      // Set window scroll position to previous 
      setTimeout(function(){
        window.scrollTo(0, WindowsSavedData.scrollTop + 100);
      }, 3000);
    }
  };

  const flipLeft = () => {
    if (submissionsArrIndex > 0) {
      let previousIndex = submissionsArrIndex - 1;
      const newCardId = submissionsArr[previousIndex].id;
      let isFlipLeftDisabled = previousIndex === 0 ? true : false;

      setState({
        ...state,
        id: newCardId,
        submissionsArrIndex: previousIndex,
        isFlipLeftDisabled,
        isFlipRightDisabled: false,
      });
    }
  };

  const flipRight = () => {
    if (submissionsArrIndex < submissionsArr.length - 1) {
      let nextIndex = submissionsArrIndex + 1;
      const newCardId = submissionsArr[nextIndex].id;
      const isFlipRightDisabled =
        nextIndex === submissionsArr.length - 1 ? true : false;

      setState({
        ...state,
        id: newCardId,
        submissionsArrIndex: nextIndex,
        isFlipLeftDisabled: false,
        isFlipRightDisabled,
      });
    }
  };

  const handleDateFilter = async ({ startDate, endDate }: any) => {
    try {
      await _getSubmissionsByDate(startDate, endDate);
      setDateRange({ // commented to resolve "String" type date issue
        startDate,
        endDate,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

    return;
  };

  /*
  const deleteAllSelected = (event: any) => {
    Swal.fire({
      title: "Are you sure?",
      text:
        "The art files will be deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (response) => {
      if (response.value) {
        const timeOptions = {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        } as Intl.DateTimeFormatOptions;

        const selectedSubmissionsArr = submissionsArr.filter(
          (submission) =>  selectedSubmissionIds.includes(submission.id)
        );

        selectedSubmissionsArr.forEach(async (submission) => {
          // const { artistName, artistEmail, id, title } = submission;
          let { logs } = submission;

          let logData: LogInterface[] = [];
          if (logs !== null) {
            logData = JSON.parse(logs);
          } else {
            logData = [];
          }

          logData.push(
            {
              logInfo: `${contactEmail} changed status from ${status} to DELETED`,
              logTimestamp: new Date().toLocaleTimeString("en-US", timeOptions),
            }
          );

          logs = JSON.stringify(logData);
          submission.logs = logs;
        });

        await axios.put(
          "/api/admin/submissions/status-to",
          { selectedSubmissionsArr, status: "DELETED" },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );

        // Not deleting all art files immediatly
        // setTimeout(() => {
        //   axios.delete("/api/admin/submissions/declined-all-art-files", {
        //     headers: {
        //       Authorization: `JWT ${token}`,
        //     },
        //   });
        // }, 1000);

        _getSubmissions(status);

        Swal.fire({
          title: "The art files have been deleted!",
          icon: "success",
        });
      } else {
        Swal.fire("The submissions were not deleted.");
      }
    });
  };

  const revertBackToNewAllSelected = (event: any) => {
    Swal.fire({
      title: "Are you sure?",
      text:
        "The art will be revert back to NEW.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (response) => {
      if (response.value) {
        const timeOptions = {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        } as Intl.DateTimeFormatOptions;

        const selectedSubmissionsArr = submissionsArr.filter(
          (submission) => selectedSubmissionIds.includes(submission.id)
        );

        selectedSubmissionsArr.forEach(async (submission) => {
          // const { artistName, artistEmail, id, title } = submission;
          let { logs } = submission;

          let logData: LogInterface[] = [];
          if (logs !== null) {
            logData = JSON.parse(logs);
          } else {
            logData = [];
          }

          logData.push(
            {
              logInfo: `${contactEmail} changed status from DELETED to NEW`,
              logTimestamp: new Date().toLocaleTimeString("en-US", timeOptions),
            }
          );

          logs = JSON.stringify(logData);
          submission.logs = logs;
        });

        try {
          await axios.put(
            "/api/admin/submissions/status-to",
            { selectedSubmissionsArr, status: "NEW" },
            {
              headers: { Authorization: `JWT ${token}` },
            }
          );

          Swal.fire({
            title: "The art files have been reverted back to NEW!",
            icon: "success",
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Something went wrong. Please try again.",
          });
        }

        setSelectionCount(0);
        _getSubmissionsByDate(startDate, endDate);
  
      } else {
        Swal.fire("The submissions were not reverted.");
      }
    });
  };
  */

  const hasAnyFilterApplied = () => {
    return showOnlyArtistTypes.length > 0 || scheduleType || search;
  };

  const loadNextPage = async () => {

    if(pageNo == 0 || loading) {
      return true;
    }

    console.log(startDate);
    console.log(endDate)

    // const fetchOnlyApprovedArtist = showOnlyApprovedArtist ? 'YES' : 'NO';

    if (status === "DECLINED" || status === "DELETED")  {
      const start = dateToLocaleDateString(new Date(startDate))
      // .toLocaleDateString("en-CA", { timeZone: "GMT", });
      const end = dateToLocaleDateString(new Date(endDate))
      //.toLocaleDateString("en-CA", { timeZone: "GMT", });
      const status = _getCurrentPath();
  
      try {
        const {
          data: { submissionsDetailsArr, pageCount },
        } = await axios.post<GetSubmissionsResponseInterface>(
          `/api/admin/submissions/by-date`,
          {
            status,
            start,
            end,
            scheduleType,
            pageIndex: pageNo,
            search,
            showOnlyArtistTypes,
          },
          {
            headers: { Authorization: `JWT ${token}` },
          }
        );

        // setSelectedSubmissionIds([]);

        // Merge next page records
        setSubmissionsArr(submissionsArr.concat(submissionsDetailsArr));
        console.log("pageCount: "+pageCount);

        if(submissionsDetailsArr.length < ARTS_PER_PAGE) {
          setHasMorePage(false);
        }

      } catch (error) {
        console.log(error);
      }

    } else {

      const {
        data: { submissionsDetailsArr, pageCount },
      } = await axios.get<GetSubmissionsResponseInterface>(`/api/admin/submissions/${status}?scheduleType=${scheduleType}&search=${search}&pageIndex=${pageNo}&artistTypes=${showOnlyArtistTypes.join(',')}`, {
        headers: { Authorization: `JWT ${token}` },
      });

      // setSelectedSubmissionIds([]);

      // Merge next page records
      setSubmissionsArr(submissionsArr.concat(submissionsDetailsArr));

      console.log("pageCount: "+pageCount);

      if(submissionsDetailsArr.length < ARTS_PER_PAGE) {
        setHasMorePage(false);
      }
    }

    const nextPageNo = pageNo + 1;
    setPageNo(nextPageNo);

  }

  // const getSelectedEmailTemplate = () => {
  //   return []; //emailTemplates.find(e => e.id == submissionUpdate.emailTemplateId) as EmailTemplateInterface;
  // }

  const getBackgroundColorCode = (colorId: number) : string => {
    const colorDetails = colorsList.find(c => (c.id == colorId));
    if(colorDetails) {
      return colorDetails.color_code;
    }

    return '';
  }

  const _handleSelectAllArt = () => {

    if (isSelectedAllSubmission) {
      setSelectedSubmissionIds([]);
      setSelectionCount(0);
    } else {

      if (!hasAnyFilterApplied() && allSubmissionIds.length > MAX_ART_SELECTION_ALLOWED) {
        
        Swal.fire({
          icon: "error",
          title: `You can select maximum ${MAX_ART_SELECTION_ALLOWED} arts to process at once!`,
        });

        return false;
      }

      // console.log(submissionsArr.map((s) => s.id));

      // setSelectedSubmissionIds(submissionsArr.map((s) => s.id))
      // setSelectionCount(submissionsArr.length);

      setSelectedSubmissionIds(allSubmissionIds);
      setSelectionCount(allSubmissionIds.length);
    }

    setIsSelectedAllSubmission(!isSelectedAllSubmission);
  }

  return (
    <SubmissionContainer>
      <TabHeader>
        <TabTitle style={{ marginRight: "33px" }}>Grid View</TabTitle>
        <TabSubLink to={`/admin/art-submissions/thumb/${status}`}>
          <TabSubTitle>Thumbnail View</TabSubTitle>
        </TabSubLink>
      </TabHeader>
      <TabArea style={{position: "relative"}}>

        <div style={{position: "absolute", right: "60px"}}>
          <AdminArtSubmissionsSearch
            search={search}
            handleAutocompleteChange={handleAutocompleteChange}
            handleAutocompleteSearch={handleAutocompleteSearch}
            submissions={submissionsSearchData}
          />
        </div>

        <div style={{position: "absolute", left: "60px", top: "20px"}}>
          <FormControlLabel
            control={
              <Checkbox color="default"
                checked={showOnlyArtistTypes.includes('APPROVED')}
                onChange={() => { applyFlaggedArtist('APPROVED') }}
              />
            }
            label="Show all submissions by an approved artist"
            style={{ }}
          />
        </div>
        {/* This is still in staging yet */}
        {/* <div style={{position: "absolute", left: "60px", top: "50px"}} >
          <FormControlLabel
            control={
              <Checkbox color="default"
                checked={showOnlyArtistTypes.includes('NEW')}
                onChange={() => { applyFlaggedArtist('NEW') }}
              />
            }
            label="Show all submissions by an new artist"
            style={{ }}
          />
        </div> */}

        <h2 style={{ textAlign: "center", margin: 0 }}>{status.replaceAll('-', ' ')} ({totalCount})</h2>

        {selectionCount == 0 ? (
          <FilterHeader style={{ justifyContent: 'space-between' }}>
            <div style={{display: "flex", margin: "30px 0"}}>
              <div>
                <TopFilterTitle heading="Sort By" title="Status"/>
                <Select
                    value={status}
                    onChange={changeStatusFilter}
                    disableUnderline
                    displayEmpty
                    style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
                    // IconComponent={() => (
                    //   <Person />
                    // )}
                  >
                    {artStatusList.map((artStatus) => (
                      <MenuItem key={artStatus.id} value={artStatus.status_name.replaceAll(' ', '-')} style={buttonAndTextFontStyle}>{artStatus.status_name}</MenuItem>
                    ))}
                  
                </Select>
              </div>
              <div style={{marginLeft: "40px"}}>
                <TopFilterTitle heading="Sort By" title="Schedule Type"/>
                <Select
                  id="scheduleTypeSelect"
                  name="scheduleType"
                    value={scheduleType}
                    onChange={applyFilter}
                    disableUnderline
                    displayEmpty
                    style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
                  >
                    <MenuItem key="all" value="" style={buttonAndTextFontStyle}>All Type</MenuItem>

                    {scheduleTags.map((scheduleTag) => (
                        <MenuItem key={scheduleTag.id} value={scheduleTag.tag_name} style={buttonAndTextFontStyle}>{scheduleTag.tag_name}</MenuItem>
                    ))}
                  
                </Select>
              </div>
            </div>
            <div style={{display: "flex", margin: "30px 0"}}>
              <BtnArtSubmit
                type="button"
                textAlign="right"
                style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
                onClick={() => setOpenImportCreationDialog(true)}
                >
                Import Product Creation
            </BtnArtSubmit>
            {(selectionCount == 0 && submissionsArr && submissionsArr.length > 0 && status == 'APPROVED') && 
              <BtnArtSubmit
                  type="button"
                  textAlign="right"
                  style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
                  onClick={async () => {
                    if (!state.loadingExport) {
                      setState({...state, loadingExport: true });
                      await exportToDesktop(showOnlyArtistTypes, scheduleType, token);
                      setState({...state, loadingExport: false });
                    } else {
                      alert('Export is in progress..')
                    }
                  }}
                  >
                  { state.loadingExport ? 'Please wait...' : 'Export to Desktop' }
              </BtnArtSubmit>
            }
            <ImportProductCreationDialog 
              isOpen={openImportCreationDialog} 
              handleClose={() => setOpenImportCreationDialog(false)} 
              handleDone={() => setOpenImportCreationDialog(false)}
              />
            </div>
            </FilterHeader>
          ) : (
            <AdminArtHeaderForm 
              selectedSubmissionIds={selectedSubmissionIds}
              isSelectedAllSubmission={isSelectedAllSubmission}
              submissionsArr={submissionsArr}
              onEmailTemplateSelector={onEmailTemplateSelector}
              onImageGeneration={createImages}
            />
          )}

        {(selectionCount > 0) && AutomationAllowedInStatus.includes(status) && 
          <AdminArtAutomationForm 
            selectedSubmissionIds={selectedSubmissionIds}
            isSelectedAllSubmission={isSelectedAllSubmission}
            token={token}
            onUpdate={() => _getSubmissions(status)}
          />
        }
        
        {(status === "DECLINED" || status === "DELETED") &&
          <Calendar
            handleDateFilter={handleDateFilter}
            globalStartDate={startDate}
            globalEndDate={endDate}
          />
        }
        {isAdminArtApproval ? (
          <AdminArtApproval
            id={id}
            closeAdminArtApproval={closeAdminArtApproval}
            flipLeft={flipLeft}
            flipRight={flipRight}
            isFlipLeftDisabled={isFlipLeftDisabled}
            isFlipRightDisabled={isFlipRightDisabled}
          />
        ) : (
         isAdminArtDecline && selectedEmailTemplate ? ( 
          <BulkEmail 
            emailTemplate={selectedEmailTemplate}
            closeAdminArtDecline={hideEmailTemplateSelector} 
            submissionsArr={submissionsArr}
            selectedSubmissionIds={selectedSubmissionIds}
            contactEmail={contactEmail}
            onSentBulkEmail={onSentBulkEmail}
            newStatus={status}
            /> 
          ) : (
          <>
            {submissionsArr.length > 1 &&
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={isSelectedAllSubmission}
                      onChange={_handleSelectAllArt}
                    />
                  }
                  label="Select All"
                  style={{ }}
                />  
                { selectionCount > 0 && <div>Total Selected: { selectionCount }</div> }
              </div>
            }
            <InfiniteScroll
                pageStart={0}
                loadMore={loadNextPage}
                hasMore={submissionsArr.length > 0 && hasMorePage}
                loader={ loading ? <></> : <Loader />}
            >
                <ArtCardContainer items={submissionsArr.length}>
                {loading ? ( <Loader /> ) : (
                  submissionsArr.length > 0 ? (
                      <>
                      {submissionsArr.map((submissionDetails, i) => (
                        <ArtCard
                          key={i}
                          {...submissionDetails}
                          backgroundColorCode={ getBackgroundColorCode(submissionDetails.mainColor) }
                          token={token}
                          index={i}
                          handleSelectArtCard={handleSelectArtCard}
                          openAdminArtApproval={openAdminArtApproval}
                          isSelected={selectedSubmissionIds.includes(submissionDetails.id)}
                        />
                      ))}
                    </>
                  ) : (
                    <h2>No Artwork to be Viewed</h2>
                ))}
                </ArtCardContainer>
            </InfiniteScroll>
          </>
        )
        )}
      </TabArea>
    </SubmissionContainer>
  );
};

const mapStateToProps = createStructuredSelector<any, { 
  userAccount: any, 
  token: string, 
  scheduleTags: ScheduleTagInterface[], 
  artStatusList: ArtStatusInterface[],
  colorsList: ColorsListInterface[],
}>({
  userAccount: selectUserAccount,
  token: selectUserJWTToken,
  scheduleTags: selectScheduleTags,
  artStatusList: selectStatusList,
  colorsList: selectColors,
});

export default connect(mapStateToProps)(AdminArtSubmissionsGrid);
