import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Nav from "../../components/Nav";
import Help from "../../components/Help";

import { PageContainer, PageWrapper } from "../SharedStyle/style";

const AdminReportingPage = () => {
  return (
    <PageContainer>
      <Nav />
      <PageWrapper>
        <Help />
      </PageWrapper>
    </PageContainer>
  );
};

export default AdminReportingPage;
