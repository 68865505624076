import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import axios, { AxiosRequestConfig } from "axios";
import Swal from "sweetalert2";
import { SITE_BACKEND_FULL_URL } from '../../server'
import { ImageCard, InstructionCard } from "./artist-dashboard.styles"
import { MainButton, ButtonSm, InputArtPreview } from "../Button";
import { ReactComponent as Upload } from "../../assets/upload.svg";
import { TextAreaStyledEditable } from "../AdminArtApproval/admin-art-approval.styles";
import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabArea,
  TabSubLink,
  TabSubTitle,
} from "../SharedStyle/styled";

import { 
  DialogTitle,
  DialogContent,
} from '../Dialog/dialog.component';

import Dialog from '@material-ui/core/Dialog';

import { DashboardWrapper } from "./artist-dashboard.styles";
import { Loader } from "../Common";

import {
  ArtPreview,
  IconContainer,
  IconTopSubtitle,
  PreviewImageWrapperWider,
  PreviewImageWider,
  FormArtistSubmit,
} from "../ArtistSubmitArt/artist-submit-art.styles";

interface IDesignerTip {
  id: number;
  description: string;
  image_path: string;
}

const DesignerTipsSettings = (props: any) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [selectedDesignerTip, setSelectedDesignerTip] = useState<IDesignerTip|null>(null);
  const [artPreviewImg, setArtPreviewImg] = useState('');
  const [designerTips, setDesignerTips] = useState<IDesignerTip[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadSettings();
  }, [])

  const loadSettings = () => {
    
    setLoading(true);

    axios.get("api/admin/settings/designer-tips/list", {
      headers: { Authorization: `JWT ${props.token}` },
    }).then((res) => {
      setDesignerTips(res.data.designerTips);
      setLoading(false);
    });
  }

  const refresh = () => {
    loadSettings();
  }
  
  const handleClose = function() {
    setIsOpen(false);
  }

  const artworkSubmissionForm: React.RefObject<HTMLFormElement> = React.createRef();

  const { token } = props;

  const getImageSrcURL = (imgFilename: string) => {
    const serverURL =  SITE_BACKEND_FULL_URL.replace('/backend', '');
    return serverURL+'/uploads/designer-tips/'+imgFilename;
  }

  const editDesignerTip = (id: number) => {

    setIsNew(false);
    if (designerTips) {
      const designerTip = designerTips.find(t => t.id == id);
      if(designerTip) {
        setSelectedDesignerTip(designerTip)
        setArtPreviewImg(getImageSrcURL(designerTip.image_path));
        setIsFileUploaded(false);
        
        setIsOpen(true);
      }
    }
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target as { name: keyof IDesignerTip; value: string };

    if(selectedDesignerTip) {

      const newSelectedDesignerTip = { ...selectedDesignerTip };

      switch(name) {
        // case 'artist_name' :
        //   newSelectedDesignerTip.artist_name = value;
        //   break;
        case 'description' :
          newSelectedDesignerTip.description = value;
          break;
      }

      setSelectedDesignerTip(newSelectedDesignerTip);
    } 
  };

  const updateDesignerTip = async () => {
    if(!selectedDesignerTip || selectedDesignerTip.description === "") {
        Swal.fire({icon: "error", text: "Description can not be empty!"});
        return false;
    }
      
    const reqBody = {
      url: "api/admin/settings/designer-tips/update",
      method: "PUT",
      headers: { Authorization: `JWT ${token}` },
      data: selectedDesignerTip,
    } as AxiosRequestConfig;

    const {
      data: { tableRowData },
    } = await axios(reqBody);

    setIsOpen(false);

    Swal.fire({
      icon: "success",
      text: "Designer Tip saved successfully.",
      showConfirmButton: true,
    });

    refresh();
  }
    
  const onChangeArtFile = async (event: any) => {

    console.log('onChangeArtFile --> 1');

    let [file] = event.target.files;
    if (!file) return;
    
    console.log(file.name);
    
    const fileExtention = file.name.split('.').pop();
    const expectedFileExtentions = ['png','jpg','jpeg'];

    if(!expectedFileExtentions.includes(fileExtention.toLowerCase())) {
        Swal.fire({
            icon: "error",
            text: "Does not meet requirements. PNG and JPG file types accepted.",
            showConfirmButton: true,
        });
        
        event.target.value = null;

      return;
    }
    
    // Make sure `file.size` does not 0 size
    if (file.size < 5) {
      Swal.fire({
        icon: "error",
        text: "Sorry your file is too small! Please make sure your image size is greater than 0MB.",
        showConfirmButton: true,
      });
      
      event.target.value = null;

      return;
    }
    
//    console.log(file.size);
    
    // Make sure `file.size` does not exceed 15MB
    if (file.size > 15000000) {
      Swal.fire({
        icon: "error",
        text: "Sorry your file is too large! Please limit your image size to less than 15MB.",
        showConfirmButton: true,
      });
      
      // event.target.value = null;

      return;
    }

    const artPreviewDetails = await _generatePreviewImg(file) as { data: string };
    setArtPreviewImg(artPreviewDetails.data);
    setIsFileUploaded(true);
  };

  const _generatePreviewImg = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const loadImg = () => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);

        const image = new Image();
        image.src = reader.result as string;

        image.onload = function() {
            // access image size here 
            // const dimension = { width: image.width, height: image.height } as Dimension;

            resolve({ data: reader.result });
        };
      };

      const loadError = (event: any) => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        reject(event);
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", loadImg);
      reader.addEventListener("error", loadError);
    });
  };

  const clearImage = () => {
    setArtPreviewImg('');
    setIsFileUploaded(false);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if(!artworkSubmissionForm.current) {
      return;
    }

    const { elements } = artworkSubmissionForm.current;
    const inputsDOM = Array.from(elements) as HTMLInputElement[];

    const formData = new FormData();

    inputsDOM.forEach((el) => {
      console.log(el);

      const { files, name, value } = el ;
      if (files) {
        formData.append(name, files[0]);
      } else if (value) {
        formData.append(name, value);
      }
    });

    // onLoading();

    if(!isFileUploaded) {
      updateDesignerTip();
    } else {

      if(selectedDesignerTip) {
        formData.append('id', selectedDesignerTip.id.toString());
      }

      try {
        const {
          data: { res },
        } = await axios.post(`api/admin/settings/designer-tips/update-file`, formData, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });

        showSuccessAndRefresh();
      } catch(e: any) {
        showErrorMassage(e.response.data.message);
      }

      refresh();
    }
  }

  const showErrorMassage = (msg: string) => {
    Swal.fire({
      icon: "error",
      text: msg,
      showConfirmButton: true,
    });

    // onLoading(false);
  }

  const showSuccessAndRefresh = () => {
    setIsOpen(false);
    // onRefresh();

    Swal.fire({
      icon: "success",
      text: "Designer Tip saved successfully.",
      showConfirmButton: true,
    });
  }

  return (
    <SubmissionContainer>
      <TabHeader>
        <TabSubLink to={`/admin/settings`}>
          <TabSubTitle>Admin Settings</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/settings/configuration`}>
          <TabSubTitle>Configuration Settings</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/settings/dashboard`}>
          <TabSubTitle>Dashboard Settings</TabSubTitle>
        </TabSubLink>
        <TabTitle>Designer Tips</TabTitle>
      </TabHeader>
      <TabArea>
        <DashboardWrapper>
          { loading ? <Loader /> : <>
          <div style={{ width: '80%', margin: 'auto' }}>
            { designerTips.map((designerTip) => ( 
                <div style={{marginTop: "20px"}}>
                  <div>
                    <ImageCard src={getImageSrcURL(designerTip.image_path)}/>
                  </div>
                  <InstructionCard dangerouslySetInnerHTML={{__html: designerTip.description.replace(/(?:\r\n|\r|\n)/g, '<br/>')}}></InstructionCard>
                  <div style={{ textAlign: 'center' }}>
                    <MainButton 
                      type="button" 
                      style={{width: "200px", cursor: "pointer", fontSize: "18px", margin: 'auto' }} 
                      onClick={() => editDesignerTip(designerTip.id)}>
                        Edit
                    </MainButton>
                  </div>
                </div>
            ))}
          </div>
          </>
        }
        </DashboardWrapper>
        <Dialog onClose={handleClose} open={isOpen} fullWidth maxWidth="md">
          <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
          <DialogContent dividers style={{textAlign: 'center'}}>
            <h2 style={{marginTop: "-20px"}}>Edit Designer Tips</h2>

            <FormArtistSubmit
              onSubmit={handleSubmit}
              ref={artworkSubmissionForm}
            >
              <PreviewImageWrapperWider style={{display: artPreviewImg ? 'block' : 'none', textAlign: "center", width: '100%'}}>
                <PreviewImageWider style={{
                  maxHeight: "400px", 
                  maxWidth: 'auto', 
                  boxShadow: '0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)',
                  borderRadius: '15px'
                }}
                  src={ artPreviewImg }
                  alt="Art Preview"
                />
              </PreviewImageWrapperWider>
              <label htmlFor="preview-art" style={{display: artPreviewImg ? 'none' : 'block'}}>
                <InputArtPreview
                  id="preview-art"
                  type="file"
                  name="artFile"
                  onChange={onChangeArtFile}
                  textAlign="center"
                  accept="image/png,image/jpeg"
                >
                <ArtPreview>
                  <IconContainer>
                    <Upload />
                  </IconContainer>
                  <IconTopSubtitle>Click to upload images</IconTopSubtitle>
                </ArtPreview>
            </InputArtPreview>
            </label>

            <ButtonSm type="button" style={{ marginTop: "20px", display: artPreviewImg ? 'initial' : 'none', marginBottom: '20px' }} onClick={clearImage}>Clear Image</ButtonSm>

            <TextAreaStyledEditable
              type="text"
              name="description"
              label="Description"
              placeholder=""
              data-lpignore="true"
              autoComplete="off"
              handleChange={handleChange}
              value={selectedDesignerTip?.description}
              required
              style={{ height: '200px', fontSize: "18px" }}
            />

            <MainButton 
              type="submit" 
              style={{width: "200px", cursor: "default", fontSize: "24px", cussor: 'pointer', marginBottom: "20px" }}>
                Save
            </MainButton>
          </FormArtistSubmit>
        </DialogContent>
      </Dialog>
      </TabArea>
    </SubmissionContainer>
  );
};



const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(DesignerTipsSettings);