import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";
import axios from "axios";

import { selectUserJWTToken, selectUserAccount } from "../../redux/user/user.selector";
import { SITE_BACKEND_API_URL, SITE_BACKEND_FULL_URL } from "../../server";
import { COMMON_DATE_RANGE, DateRangeInterface, COMMON_DATE_PROP, Loader, DEFAULT_PAGE_SIZE } from "../Common";

import { AdminTable } from "../Table";
import TableQueries from "../Table/table-queries.component";
import { adminFetchCommForTable } from "../../utils/fetchCommForTable";
import { StringKeyValuePair, InputChangeEvent } from "../Common/common-types"
import getCsvHeaders from "../../utils/csvHeaders";

import { TABLE_COLUMNS } from "./admin-table-headers";
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

import {
  SubmissionContainer,
  TabArea,
  TabHeader,
  TabSubLink,
  TabSubTitle,
  TabTitle,
} from "../SharedStyle/styled";
import { UserRole } from "../../constants";

enum FilteredDateType { 
  CREATED = "created", 
  CANCELED_RETURNED = "canceled_returned" 
};

interface SortByInterface {
  id: string; 
  desc: boolean;
}

interface SearchFilterInterface {
  startDate: Date|null;
  endDate: Date|null;
  order: string;
  product_title: string;
  artist: string;
  product_type: string;
  group: string;
  is_commissions_paid: string;
  order_status: string,
  tags: string;
  checkedExcludeTags: boolean;
  filtered_date_type: FilteredDateType;
  pageSize: number;
  pageIndex: number;
  sortBy: SortByInterface;
}

const AdminCommissions = (props: any) => {
  const [tableColumns, setTableColumns] = useState(TABLE_COLUMNS);
  const [tableData, setTableData] = useState(null);
  const [isCommLoading, setIsCommLoading] = useState(false);
  const [showTableLoader, setTableLoader] = useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalRecordCount, setTotalRecordCount] = React.useState(0);
  const [isFilterInitialized, setIsFilterInitialized] = React.useState(false);
  const {token, userAccount } = props;
  
  // console.log("COMMON_DATE_RANGE: ");
  // console.log(COMMON_DATE_RANGE);

  const [ filters, setFilters ] = useState<SearchFilterInterface>({
    startDate: COMMON_DATE_RANGE.startDate,
    endDate: COMMON_DATE_RANGE.endDate,
    order: "",
    product_title: "",
    artist: "",
    product_type: "",
    group: "",
    is_commissions_paid: "",
    order_status: "",
    tags: "",
    checkedExcludeTags: false,
    filtered_date_type: FilteredDateType.CREATED,
    pageSize: DEFAULT_PAGE_SIZE,
    pageIndex: 0,
    sortBy: { id: 'order_created_at', desc: true },
  });

  useEffect(() => {
    if(!isFilterInitialized) { // Don't load report by default to avoid performace issue
      console.log("useEffect filter initialized (Only first time when filter load).");
      setIsFilterInitialized(true);

      // default order date is selected
      const excludeColumns = getExcludeColumns();
      setTableColumns(TABLE_COLUMNS.filter(c => !excludeColumns.includes(c.accessor)));
    } else {
      console.log("Filters: ");
      console.log(filters);

      _getAllCommissions(filters);
    }

  }, [filters]);

  const _getAllCommissions = async (filters: SearchFilterInterface) => {

    console.log("_getAllCommissions called");
    setTableLoader(true);

    const { startDate, endDate } = getDateParams();
    const requetData = {...filters, startDate, endDate };

    const reqBody = {
      method: "POST",
      data: requetData,
      url: `/api/admin/commissions/list-by-order`
    };
    try {
      const { tableData, totalRecordCount } = await adminFetchCommForTable(
        reqBody,
        token,
        filters.checkedExcludeTags
      );

      setIsCommLoading(false);
      setTableData(tableData);
      setTableLoader(false);

      setPageCount(Math.ceil(totalRecordCount / filters.pageSize));
      setTotalRecordCount(totalRecordCount);

    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
      return;
    }
  };

  const handleDateFilter = async (dateRange: DateRangeInterface | null) => {
    try {
        
      const newFilters = filters;

      if(dateRange !== null) {
        const { startDate, endDate } = dateRange;

        COMMON_DATE_RANGE.startDate = new Date(startDate + " 00:00:00");
        COMMON_DATE_RANGE.endDate = new Date(endDate + " 00:00:00");

        newFilters.startDate = startDate;
        newFilters.endDate = endDate;

      } else {
        newFilters.startDate = null;
        newFilters.endDate = null;
      }
      
      setFilters({...newFilters});

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

    return;
  };  

  const handleDateFilterTypeChange = (event: any) => {
    setFilters({ ...filters, filtered_date_type: event.target.value });

    // Adjust columns 
    const excludeColumns = getExcludeColumns(event.target.value);
    setTableColumns(TABLE_COLUMNS.filter(c => !excludeColumns.includes(c.accessor)));
  };

  const handleCheckboxChange = (event: InputChangeEvent) => {
    setFilters({ ...filters, checkedExcludeTags: event.target.checked });
  };

  const globleSearchHandler = (globleFilters: any, pageIndex: number, pageSize: number, sortBy: SortByInterface[]) => {
    console.log("globleSearchHandler: ");

    const newFiltersList: StringKeyValuePair = resetFilter();

    globleFilters.forEach((filter: any) => {
      newFiltersList[filter.id] = filter.value.trim();   
    });

    setFilters({ ...filters, ...newFiltersList, pageIndex, pageSize, sortBy: sortBy[0] });
  }

  const resetFilter = () => {
    return {
      order: "",
      product_title: "",
      artist: "",
      product_type: "",
      group: "",
      is_commissions_paid: "",
      tags: "",
      order_status: "",
    }
  }

  const markedAllPaymentStatus = (isPaid: boolean) => {
    Swal.fire({
      title: "Are you sure?",
      text: `${totalRecordCount} orders will be marked as ${isPaid ? "Paid" : "Unpaid"}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (response) => {
      if (response.value) { // Confirmed

        setTableLoader(true);

        const { startDate, endDate } = getDateParams();
        const filtersData = {...filters, startDate, endDate };
        
        try {
        // update payment status
         await axios.put(SITE_BACKEND_API_URL+`/admin/commissions/updatePaymentStatusByOrder`, { filtersData, isPaid }, {
            headers: {
              Authorization: `JWT ${token}`,
            },
          }
        );

        setTableLoader(false);
        Swal.fire({
          icon: "success",
          title: `Commissions marked as ${isPaid ? "Paid" : "Unpaid"}`,
        });

        // Reload table
        reloadData();

      } catch (error) {
        
        setTableLoader(false);
        
        Swal.fire({
          icon: "error",
          title: "Sorry, Something Went Wrong",
        });
      }

      }
    });
  };

  const onTableLoading = (isLoading: boolean) => {
    setTableLoader(isLoading);
  }

  const reloadData = () => {
    _getAllCommissions(filters);
  }

  const getExcludeColumns = (dateType = undefined) : string[] => {
    const selectedDateType = dateType ?? filters.filtered_date_type;
    return selectedDateType == FilteredDateType.CANCELED_RETURNED ? 
        ['order_created_at'] : ['canceled_date', 'negative_commissions'];
  }

  const exportCSV = async () => {

    const { startDate, endDate } = getDateParams();

    const CSVHeaders = getCsvHeaders();
    const excludeColumns = getExcludeColumns()
    const exportHeaders = CSVHeaders.filter(c => !excludeColumns.includes(c.key));
    
    const requetData = {...filters, startDate, endDate, exportType: "ByMain", csvHeaders: exportHeaders };
    
    setIsCommLoading(true);

    const {
        data: { csvPath },
    } = await axios({
        url: SITE_BACKEND_API_URL+"/admin/commissions/exportCSV",
        method: "POST",
        data: requetData,
        headers: { Authorization: `JWT ${token}` },
    });

    window.open(SITE_BACKEND_FULL_URL+"/"+csvPath, '_blank');

    setIsCommLoading(false);
}

  const getDateParams = () => {
        const startDate = filters.startDate !== null ? getFormatedDate(filters.startDate) : '';
        const endDate = filters.endDate !== null ? getFormatedDate(filters.endDate) : '';

        return { startDate, endDate };
  }

  const getFormatedDate = (date: Date) : string => {

    if(!(date instanceof Date)) {
      return date;
    }

    const monthNumber = (date.getMonth() + 1);
    const dateNumber = date.getDate();

    const monthSeperater = (monthNumber < 10) ? '-0' : '-';
    const dateSeperater = (dateNumber < 10) ? '-0' : '-';
    return date.getFullYear() + monthSeperater + monthNumber + dateSeperater + dateNumber;
  }

  return (
    <SubmissionContainer style={{ maxWidth: "86vw", width: "98%" }}>
      <TabHeader>
        <TabTitle>Commissions by Order</TabTitle>
        <TabSubLink to={`/admin/commissions/by-artist`}>
          <TabSubTitle>Commission by Artist</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/commissions/payouts`}>
          <TabSubTitle>Payouts</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/commissions/errors`}>
            <TabSubTitle>Commissions Errors</TabSubTitle>
        </TabSubLink>
      </TabHeader>
      <TabArea style={{ padding: "55px 20px" }}>
          <RadioGroup style={{display: "block", textAlign: "center"}} onChange={handleDateFilterTypeChange}>
            <FormControlLabel 
              value={FilteredDateType.CREATED}
              checked={filters.filtered_date_type == FilteredDateType.CREATED} 
              control={<Radio />} 
              label="Order Date" 
            />
            <FormControlLabel 
              value={FilteredDateType.CANCELED_RETURNED}
              checked={filters.filtered_date_type == FilteredDateType.CANCELED_RETURNED} 
              control={<Radio />} 
              label="Cancel/Return Date" 
              style={{marginLeft: "20px"}} />
          </RadioGroup>

        <TableQueries
          handleDateFilter={handleDateFilter}
          globalStartDate={COMMON_DATE_RANGE.startDate}
          globalEndDate={COMMON_DATE_RANGE.endDate}
          isDateOpened={COMMON_DATE_PROP.isDateOpened}
          note="Note: Default is today."
          isDateClearable={true}
        />
        {/* <ToggleButtonGroup
          size="large"
          value={alignment}
          exclusive
          onChange={_handleToggle}
          style={{ marginBottom: "15px" }}
        > */}
        {/* <ToggleButton value="summary">Summary</ToggleButton> */}
        {/* <ToggleButton value="artist">Artist</ToggleButton>
          <ToggleButton value="product">Product Title</ToggleButton>
          <ToggleButton value="product-type">Product Type</ToggleButton> */}
        {/* </ToggleButtonGroup> */}
        {isCommLoading || (tableData === null && showTableLoader) ? (<Loader/>) : (
          tableData === null ? ( 
            <div>
              <p style={{ textAlign: 'center', fontSize: "20px", padding: "20px" }}>
                Please select date range to load report.
              </p> 
            </div>
          ) :
          <AdminTable
            columns={tableColumns}
            reloadData={reloadData}
            data={tableData}
            token={token}
            checkedExcludeTags={filters.checkedExcludeTags}
            handleCheckboxChange={handleCheckboxChange}
            showToolbar={true}
            isSelectableRows={ userAccount.userRole !== UserRole.ADMIN_VIEW_ONLY }
            isManualSearch={true}
            globleSearchHandler={globleSearchHandler}
            onTableLoading={onTableLoading}
            showTableLoader={showTableLoader}
            pageCount={pageCount}
            pageIndex={filters.pageIndex}
            totalRecordCount={totalRecordCount}
            markedAllPaymentStatus={markedAllPaymentStatus}
            exportCSV={exportCSV}
          />
        )}
      </TabArea>
    </SubmissionContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(AdminCommissions);
