const getCsvHeaders = (type) => {
  const csvHeaders = [
      { label: "id", key: "dbRowId" },
      { label: "Order Date", key: "order_created_at" },
      { label: "Refund Date", key: "canceled_date" },
      { label: "Order #", key: "order" },
      { label: "Title", key: "product_title" },
      { label: "Variant SKU", key: "variant_sku" },
      { label: "Artist", key: "artist" },
      { label: "Product Type", key: "product_type" },
      { label: "Status", key: "order_status" },
      { label: "Quantity", key: "quantity" },
      { label: "Commissions Amount", key: "commissions_amount" },
      { label: "Negative Commissions", key: "negative_commissions" },
      { label: "Donation Amount", key: "tip_amount" },
      { label: "Group", key: "group" },
      { label: "Paid or Unpaid", key: "is_commissions_paid" },
      { label: "Paypal Email", key: "paypal_email" },
      { label: "Is International", key: "is_international" },
    ];
  return csvHeaders;
};

export default getCsvHeaders;