import styled from "styled-components";

export const DashboardWrapper = styled.section`
    max-width: 80%;
    margin: auto;
`;

export const Header = styled.div`
    position: relative;
`;

export const ArtistNameTitle = styled.div`
    position: absolute;
    font-size: 50px;
    font-weight: bold;
    top: 55px;
    color: #fff;
    text-align: center;
    left: 0;
    right: 0;
    font-style: italic;
`;

export const Description = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 620;
    width: 95%;
    margin: 35px auto;
    line-height: 30px;
    color: #555;
`;

export const WhatsSectionWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
`;

export const WhatsSection = styled.div`
    width: 48%;
`;

export const WhatsSectionTitle = styled.div`
    font-size: 21px;
    font-weight: bold;
    color: #555;
`;

export const WhatsSectionTile = styled.div`
    box-shadow: 0 5px 15px rgb(0 0 0 / 30%);
    padding: 20px 10px; 
    border-radius: 15px;
    min-height: 200px;
    margin-top: 20px;
`;

export const TrendingList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const Trending = styled.div`
    background-color: #cce6e8;
    padding: 16px 20px;
    font-size: 18px;
    margin: 5px;
    border-radius: 8px;
    font-weight: 500;
    color: #555;
`;

export const Art = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: #555;
    padding: 10px;
`;

export const ArtCategory = styled.span`
    color: #6CB6BB;
    border: 3px solid #6CB6BB;
    padding: 0 10px 3px 10px;
    border-radius: 10px;
    font-weight: 600;
    margin-left: 10px;
    font-size: 18px;
`;

export const FavoriteSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
`;

export const FavoriteTile = styled.div`
    margin-top: 30px;
`;

export const TipsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
`;

export const Tip = styled.div`
    width: 32%;
    position: relative;
`;

export const TipContent = styled.div`
    position: absolute;
    top: 260px;
    padding: 20px;
    padding-top: 0;
`;

export const ImageCard = styled.img`
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
`;

export const InstructionCard = styled.div`
  background-color: #dff1f3;
  color: #444;
  text-align: left;
  border-radius: 20px;
  padding: 30px 40px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
`;