import React, { useState, useEffect } from "react";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import MailOutlineIcon from "@material-ui/icons/MailOutline";

import { SubmissionInterface } from '../AdminArtSubmissions/admin-art-submission-types';

import {
  CardContainer,
  CardWrapperThumb,
  ImgCard,
  RoleHeading,
  FieldTitle,
} from "./art-submissions-card.styles";

import { withStyles } from "@material-ui/core/styles";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import SuggestedTags from "../Common/suggested-tags.component";
import { Tag } from 'react-tag-input';
import { ApprovedFlag, NewArtistFlag } from "../Common";

import {
  FormInputTitleStyledSmall,
  TextAreaStyledSmall,
  FormInputTitleStyledEditable,
  TextAreaStyledEditable,
} from "../AdminSubmitArt/admin-submit-art.styles";

interface AdminArtCardThumbInterface {
  token: string;
  submission: SubmissionInterface;
  index: number;
  onInternalChange: Function;
  isSelected: boolean;
  handleSelectArtCard: Function;
}

const StyledCircleCheckedFilled = withStyles({
  root: {
      width: "25px",
      height: "25px",
      borderRadius: "30px",
      color: "#007FA3",
      position: "absolute",
      right: "10px",
      top: "10px",
  }  
})(CircleCheckedFilled);

const AdminArtCardThumb = (props: AdminArtCardThumbInterface) => {
  const { token, index, submission, onInternalChange, isSelected, handleSelectArtCard } = props;

  const [imageSrc, setImageSrc] = useState("");
  const [emailStatusColor, setEmailStatusColor] = useState({
    color: "#6a6a6a",
  });

  useEffect(
    () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const fetchImage = () => {
        const thumbImg = `/api/art-submissions-thumb/?src=${submission.previewArt.substring(
          20
        )}`;
        fetch(thumbImg, { signal, headers: { Authorization: `JWT ${token}` } })
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            setImageSrc(URL.createObjectURL(blob));
          })
          .catch((error) => {
            // console.error(error);
          });
      };
      
      if (!submission.previewArt) {
        setImageSrc(teefuryBirdLogo);
      } else {
        fetchImage();
      }

      return () => {
        controller.abort();
      };
    },
    //eslint-disable-next-line
    [token, submission.previewArt]
  );

  const _handleSelectArtCard = (e: any) => {
    // console.log(e.target.tagName.toLowerCase());

    const targetTag = e.target.tagName.toLowerCase();
    if(!['input', 'textarea'].includes(targetTag)) {
      handleSelectArtCard(submission.id);
    }
  }

  const handleSuggestedTagsChange = (tags: Tag[]) => {
    const tagNames = tags.map(t => t.text);
    onInternalChange(submission.id, 'internalTags', tagNames.join(','));
  }

  return (
    <CardContainer 
      style={{maxWidth: "100%", cursor: "pointer", position: "relative", backgroundColor: (isSelected ? '#dedddd' : 'initial') }}
      onClick={_handleSelectArtCard}>
      <CardWrapperThumb id={submission.id}>
        <div style={{textAlign: "center", paddingRight: "10px"}}>
          <ImgCard style={{width: "100px", height: "auto"}}
            src={imageSrc ? imageSrc : teefuryBirdLogo}
            alt={submission.title}
            loaded={imageSrc ? "true" : ""}
          />
          <div style={{marginTop: "10px"}}>
            <div>Artist:</div>
            <div style={{fontWeight: "bold", display: "flex"}}>
              {submission.artistName}
              {submission.isApprovedArtist == 'YES' && <ApprovedFlag />}
            </div>
            <div>
              {submission.isNewArtist == 'YES' && <NewArtistFlag />}
            </div>

            {submission.creationType && <>
                <div>Creation Type:</div>
                <div style={{fontWeight: "bold"}}>{submission.creationType}</div>
              </>
            }
          </div>
        </div>
        <div style={{width: "35%"}}>
            <RoleHeading>ARTIST</RoleHeading>
            <div style={{display: "flex"}}>
              <div>
                <FieldTitle>Title</FieldTitle>
                <FormInputTitleStyledSmall
                  type="text"
                  label="title"
                  placeholder="TITLE"
                  data-lpignore="true"
                  value={submission.title}
                  disabled="disabled"
                />

                <FieldTitle>Main Inspiration</FieldTitle>
                <FormInputTitleStyledSmall
                  type="text"
                  label="Main Inspiration:"
                  placeholder="Main Inspiration"
                  data-lpignore="true"
                  value={submission.mainInspiration}
                  disabled="disabled"
                />
              </div>
              <div style={{marginLeft: "10px"}}>
                <FieldTitle>Tags</FieldTitle>
                <TextAreaStyledSmall
                  type="text"
                  label="Tags"
                  placeholder="Tags"
                  data-lpignore="true"
                  autoComplete="off"
                  value={submission.tags}
                  disabled="disabled"
                />
              </div>
            </div>
        </div>
        <div style={{width: "45%"}}>
            <RoleHeading>ADMIN</RoleHeading>
            <div style={{display: "flex"}}>
              <div>
                <FieldTitle>Title</FieldTitle>
                {isSelected ? 
                  <FormInputTitleStyledSmall
                    type="text"
                    value={submission.internalTitle}
                    disabled="disabled"
                  /> :
                  <FormInputTitleStyledEditable
                    type="text"
                    label="title"
                    placeholder="TITLE"
                    data-lpignore="true"
                    autoComplete="off"
                    value={submission.internalTitle}
                    onChange={(e: any) => {
                      onInternalChange(submission.id, 'internalTitle', e.target.value);
                    }}
                  />
                }

                <FieldTitle>Main Inspiration</FieldTitle>
                {isSelected ? 
                    <FormInputTitleStyledSmall
                      type="text"
                      value={submission.internalMainInspiration}
                      disabled="disabled"
                    /> :
                    <FormInputTitleStyledEditable
                      type="text"
                      label="Main Inspiration:"
                      placeholder="Main Inspiration"
                      data-lpignore="true"
                      autoComplete="off"
                      value={submission.internalMainInspiration}
                      onChange={(e: any) => {
                        onInternalChange(submission.id, 'internalMainInspiration', e.target.value);
                      }}
                    />
                  }
              </div>
              <div style={{marginLeft: "10px"}}>
                <FieldTitle>Tags</FieldTitle>
                  {isSelected ? 
                    <TextAreaStyledSmall
                    type="text"
                    value={submission.internalTags}
                    disabled="disabled"
                    />:
                    // <TextAreaStyledEditable
                    //   type="text"
                    //   label="Tags"
                    //   placeholder="Tags"
                    //   data-lpignore="true"
                    //   autoComplete="off"
                    //   value={submission.internalTags}
                    //   onChange={(e: any) => {
                    //     onInternalChange(submission.id, 'internalTags', e.target.value);
                    //   }}
                    // />
                    <div style={{ maxHeight: '150px', overflowX: 'hidden', overflowY: 'scroll' }}>
                      <SuggestedTags 
                        onChange={handleSuggestedTagsChange} 
                        selectedTags={submission.internalTags ? submission.internalTags.split(",") : []}
                        />
                    </div>
                  }
              </div>
            </div>
        </div>  

        {isSelected ? ( <StyledCircleCheckedFilled/> ) : null }
      </CardWrapperThumb>
    </CardContainer>
  );
};

export default AdminArtCardThumb;
