import React, { useEffect, useState } from "react"
import { Calendar } from "react-date-range"
import { dateToLocaleDateString } from "../../utils";
import { FormInputTitleEditable } from "../AdminArtApproval/admin-art-approval.styles";
import './calendar-styles.css';

interface DatePickerInterface {
    value: string | Date | null;
    onChange: Function;
    fullForm: boolean
}

export const DatePicker = ({value, onChange, fullForm = true}: DatePickerInterface) => {
    
    const [selectedDate, setSelectedDate] = useState<Date|null>(null);
    const [isOpenCalender, setIsOpenCalender] = useState(false);

    useEffect(() => {
        if (value) {
            const selectedSDate = new Date(value);
            // To resolve timezone issue
            var userTimezoneOffset = selectedSDate.getTimezoneOffset() * 60000;
            setSelectedDate(new Date(selectedSDate.getTime() + userTimezoneOffset));
        }
    }, []);

    const handleSelect = (date: Date) => {
        setSelectedDate(date);
        setIsOpenCalender(false);
        onChange(date);
    }

    const formatedDate = () => {
        if (!selectedDate) {
            return '';
        }
        let month = (selectedDate.getMonth() + 1) as number | string;
        let date = selectedDate.getDate() as number | string;

        month = month < 10 ? '0' + month : month;
        date = date < 10 ? '0' + date : date;

        return month + '/' + date + '/' + selectedDate.getFullYear();
    }

    return <div style={{position: 'relative'}}>
        { fullForm ? 
            <FormInputTitleEditable
                type="text"
                label="Schedule Date"
                placeholder="Schedule Date"
                autoComplete="off"
                value={formatedDate()}
                readonly
                onClick={() => setIsOpenCalender(!isOpenCalender)}
            />
        :
            <input
                style={{padding: "8px", borderRadius: "10px", border: "2px solid rgb(80, 184, 189)", fontSize: "18px"}}
                type="text"
                placeholder="Choose Schedule Date"
                autoComplete="off"
                value={formatedDate()}
                readOnly={true}
                onClick={() => setIsOpenCalender(!isOpenCalender)}
            />
        }
        { isOpenCalender &&
            <div style={{position: "absolute", zIndex: 999 }}>
                <Calendar date={ selectedDate ? selectedDate : undefined } onChange={handleSelect} />
            </div>
        }
    </div>
}