
import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({component: Component, authed, ...rest}) => {

    console.log("authed");
    console.log(authed);

    return (
      <Route
        {...rest}
        render={(props) => authed === true
          ? <Component {...props} />
          : <Redirect to={{pathname: '/signin', state: {from: props.location}}} />}
      />
    )
  }

  export default PrivateRoute;