import React, { useMemo, useState, useEffect } from "react";
import { CSVDownload } from "react-csv";
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from "react-table";

import axios from "axios";
import Swal from "sweetalert2";

import TableToolbar from "./table-tool-bar.component";
import { adminFetchCommForTable } from "../../utils/fetchCommForTable";
import getCsvHeaders from "../../utils/csvHeaders";
import { Loader, DEFAULT_PAGE_SIZE } from "../Common";

import {
  DefaultColumnFilter,
  IndeterminateCheckbox,
  fuzzyTextFilterFn,
  startWithFn,
} from "../../libs/table";

import { TableContainer } from "./table.styles";
import { Pages } from "@material-ui/icons";

// Remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const AdminTable = (props) => {
  const {
    columns,
    reloadData,
    data,
    token,
    checkedExcludeTags,
    handleCheckboxChange,
    showToolbar,
    isSelectableRows,
    isMainCommissionTable=true,
    isManualSearch=false,
    globleSearchHandler,
    showTableLoader,
    onTableLoading,
    pageCount: controlledPageCount = 0,
    pageIndex: controlledPageIndex = 0,
    totalRecordCount = 0,
    markedAllPaymentStatus,
    exportCSV,
  } = props;

  const [showUpdateLoader, setUpdateLoader] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: startWithFn,
    }),
    []
  );

  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,

    // Paginate
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    // Selection
    selectedFlatRows,

    state: { pageIndex, pageSize, filters, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      manualSortBy: true,
      manualFilters: true,
      manualPagination: true,
      autoResetPage: false,
      initialState: {
        hiddenColumns: ["dbRowId", "order_ids"],
        filters: [],
        pageIndex: controlledPageIndex,
        pageSize: DEFAULT_PAGE_SIZE,
        sortBy: []
      },
      pageCount: controlledPageCount
    },
    // Filter
    useFilters,
    
    useSortBy,

    // Paginated
    usePagination,

    // Selection
    useRowSelect,
    (hooks) => {
        
        if(!isSelectableRows) {
            return;
        }
        
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    console.log("Table load first time.");
    setInitialized(true);
  }, []); // Run only first time load

  useEffect(() => {
    if(isInitialized && isManualSearch) {

      console.log("useEffect: table filters changed ");

      onTableLoading(true);

      const timeoutID = setTimeout(() => {
        globleSearchHandler(filters, 0, pageSize, sortBy);
      }, 500);

      return () => clearTimeout(timeoutID);
    }

  }, [filters]);

  useEffect(() => {
    if(isInitialized && isManualSearch) {
      console.log("useEffect: table pageIndex, pageSize changed ");

      onTableLoading(true);
      globleSearchHandler(filters, pageIndex, pageSize, sortBy);
    }

  }, [pageIndex, pageSize, sortBy]);

  const updateCommissions = async (isPaid) => {
    const dbRowIds = selectedFlatRows.map((row) => {
      return row.values.dbRowId;
    });

    try {
        
//        showToolbar = false;
        setUpdateLoader(true);
        
      // eslint-disable-next-line
      const data = await axios.put(
        "/api/admin/commissions/update",
        {
          dbRowIds,
          isPaid,
        },
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      );
      
      reloadData();

      setUpdateLoader(false);
      Swal.fire({
        icon: "success",
        title: `Commissions marked as ${isPaid ? "Paid" : "Unpaid"}`,
      });
      
    } catch (error) {
        
        setUpdateLoader(false);
        
      Swal.fire({
        icon: "error",
        title: "Sorry, Something Went Wrong",
      });
    }
  };

  const updateCommissionsByArtist = async (isPaid) => {

    // console.log(selectedFlatRows);

    const orderIds = selectedFlatRows.map((row) => row.values.order_ids.split(','));
    const dbRowIds = [].concat(...orderIds);

    Swal.fire({
      title: "Are you sure?",
      text: `${dbRowIds.length} orders will be marked as ${isPaid ? "Paid" : "Unpaid"}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (response) => {
      if (response.value) { // Confirmed

        setUpdateLoader(true);
        
        try {
        // update payment status
         await axios.put("/api/admin/commissions/update",
          {
            dbRowIds,
            isPaid,
          },
          {
            headers: {
              Authorization: `JWT ${token}`,
            },
          }
        );

        setUpdateLoader(false);
        Swal.fire({
          icon: "success",
          title: `Commissions marked as ${isPaid ? "Paid" : "Unpaid"}`,
        });

        // Reload table
        reloadData();

      } catch (error) {
        
        setUpdateLoader(false);
        
        Swal.fire({
          icon: "error",
          title: "Sorry, Something Went Wrong",
        });
      }

      }
    });
  }

  const markedAsPaid = () => {
    updateCommissions(true);
  };

  const markedAsUnpaid = () => {
    updateCommissions(false);
  };

  const markedAllAsPaid = () => {
    markedAllPaymentStatus(true);
  };

  const markedAllAsUnpaid = () => {
    markedAllPaymentStatus(false);
  };

  const markedAsPaidByArtist = () => {
    updateCommissionsByArtist(true);
  };

  const markedAsUnpaidByArtist = () => {
    updateCommissionsByArtist(false);
  };

  const [csvData, setCsvData] = useState({ csvBodyData: [], csvHeaders: [] });
  const { csvBodyData, csvHeaders } = csvData;

  // const exportCSV = async () => {
  //   // const csvHeaders = getCsvHeaders();
  //   // const csvBodyData = rows.map((row) => row.original);

  //   // // TODO: Watch MYSTERY CODE!!
  //   // await setCsvData({ csvBodyData, csvHeaders });
  //   // setCsvData({ csvBodyData: [], csvHeaders: [] });

  //   console.log("Export csv");
  // };

  return (
    <TableContainer>
      {/* Download CSV file */}
      {csvBodyData.length > 0 ? (
        <CSVDownload data={csvBodyData} headers={csvHeaders} />
      ) : null}
      {/* Toolbar */}
      
      {showToolbar && !showUpdateLoader ? (
      <TableToolbar
        numSelected={Object.keys(selectedRowIds).length}
        markedAsPaid={isMainCommissionTable? markedAsPaid : markedAsPaidByArtist}
        markedAsUnpaid={isMainCommissionTable ? markedAsUnpaid : markedAsUnpaidByArtist}
        markedAllAsPaid={markedAllAsPaid}
        markedAllAsUnpaid={markedAllAsUnpaid}
        exportCSV={exportCSV}
        totalRecords={totalRecordCount}
        checkedExcludeTags={checkedExcludeTags}
        handleCheckboxChange={handleCheckboxChange}
        isMainCommissionTable={isMainCommissionTable}
      />
      ) : null}
      
      {showUpdateLoader ? ( <Loader/> ) : null}
      
      {/* Table */}
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, j) => (
                <th key={j} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                  <span>{ column.canSort ? (column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : "") : "" }</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>

        { showTableLoader ? (<tr><td colSpan="100">Loading...</td></tr>) : [
          (page.length > 0 ? (
            page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, j) => {
                    return (
                      <td key={j} {...cell.getCellProps({
                          className: cell.column.className,
                          style: cell.column.style,
                        })}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })) 
          : (<tr key="no-data-row"><td key="no-data-col" colSpan="100" style={{color: "red"}}>No Records Found</td></tr>))
        ]}
          
        </tbody>
      </table>
      <br />

      {/* Pagination */}
      <div className="pagination">
        <span>Total: {rows.length} </span>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
        <span>
          Page
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span>
          | Go to page:
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

      {process.env.NODE_ENV === "development" ? (
        <div>
          <pre>
            <code>
              {JSON.stringify(
                {
                  filters,
                  pageIndex,
                  pageSize,
                  pageCount,
                  canNextPage,
                  canPreviousPage,
                  selectedRowIds: selectedRowIds,
                  "selectedFlatRows[].original": selectedFlatRows.map(
                    (d) => d.original
                  ),
                },
                null,
                2
              )}
            </code>
          </pre>
        </div>
      ) : null}
    </TableContainer>
  );
};

export default AdminTable;