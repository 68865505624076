export const buttonAndTextFontStyle = {
    fontFamily:
      "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: "15px",
    fontWeight: "bold",
  } as React.CSSProperties;
  
export const filterDropdownStyle = {
    width: "200px",
    border: "2px solid #50b8bd", 
    borderRadius: "10px", 
    padding: "0px 15px",
} as React.CSSProperties;