import React, { Component } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";
import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import { ArtworkType } from "../../constants";
import { SITE_BACKEND_API_URL } from "../../server";

import { selectArtistProfile } from "../../redux/artist/artist.selector";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import {
  selectSubmissionsErrorAlert,
  selectSubmissionsSuccessAlert,
} from "../../redux/submissions/submissions.selector";
import {
  submissionsEditStart,
  submissionErrorAlertClear,
  submissionSuccessAlertClear,
} from "../../redux/submissions/submissions.action";

import { ReactComponent as Loading } from "../../assets/loading.svg";
import { BtnArtSubmit } from "../Button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// eslint-disable-next-line
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
// eslint-disable-next-line
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// eslint-disable-next-line
import { IconButton } from "@material-ui/core";

import { SectionHeading } from "../FormInput";
import { ArtworkTypeInfoDialog } from "../Dialog";

import {
  TabArea,
  FilterHeader,
  AdjustableIconWrapper,
  SubTitle,
  SubmitCard,
  FieldSection,
  ArtPreview,
  IconBottomSubtitle,
  PreviewImageWrapper,
  PreviewImage,
  FormInputArtistStyled,
  FormInputTitleStyled,
  TextAreaStyled,
  ArtworkTypeWrapper,
  ArtworkTypeOption,
} from "./artist-submissions-view.styles";

import { DownloadLink as AdminDownloadLink } from "../AdminArtApproval/admin-art-approval.styles";

import { MainButton } from "../Button";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

class ArtistSubmissionsView extends Component {
  constructor(props) {
    super(props);

    this.artworkSubmissionForm = React.createRef();

    this.state = {
      id: "",
      artistName: "",
      title: "",
      description: "",
      suggestedTags: "",
      artFileName: "UPLOAD ART FILE",
      artPreviewImg: "",
      artworkType: ArtworkType.classic,
      mainColor: "",
      additionalColors: "",
      artHasSubmitted: false,
      isDisableSubmit: false,
      colorsList: [],
      additionalColorsList: [],
      isDialogOpen: false,
      openedDialogArtworkType: ArtworkType.classic,
      status: '',
      isEnlargeImg: false,
      artFile:"",
      previewArt:""
    };
  }

  static getDerivedStateFromProps(props) {
    const {
      artistProfile: { artistName },
    } = props;

    return {
      artistName,
    };
  };

  componentDidMount() {
    this._loadArtwork();
  }

  componentDidUpdate(prevProps) {
    this._submissionMessages();
    if (this.props.id !== prevProps.id) {
      this._loadArtwork();
    }
  }

    openDiaog = (e, artworkType) => {
        this.setState({isDialogOpen: true, openedDialogArtworkType: artworkType});
        e.stopPropagation();
    }
    
    closeDiaog = () => {
        this.setState({isDialogOpen: false});
    }
  

  _generatePreviewImg = (file) => {
    return new Promise((resolve, reject) => {
      const loadImg = () => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        resolve(reader.result);
      };

      const loadError = (event) => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        reject(event);
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", loadImg);
      reader.addEventListener("error", loadError);
    });
  };
  
   clickEnlargeImg = () => {
        this.setState({ isEnlargeImg: !this.isEnlargeImg });
    };
    
  _loadPreviewArt = async (previewArt) => {
    if (!previewArt) {
//      setArtPreviewImg(teefuryBirdLogo);
      this.setState({ artPreviewImg: teefuryBirdLogo });
    } else {
      try {
        const largeThumb = `/api/art-submissions-thumb/?src=${previewArt.substring(
          20
        )}&w=500`;
        const artPreviewImg = await this._createBlob(largeThumb);
//        setArtPreviewImg(artPreviewImg);
        this.setState({ artPreviewImg: artPreviewImg });
      } catch (error) {
        Swal.fire({
          icon: "error",
          text: "Sorry could not load art preview file",
          showConfirmButton: false,
        });
      }
    }
  };
  
  _createBlob = async (previewArt) => {
      
      const { token } = this.props;
    return await fetch(previewArt, {
      headers: { Authorization: `JWT ${token}` },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  _resetForm = () => {
    const {
      artistProfile: { artistName },
    } = this.props;

    this.artworkSubmissionForm.current.reset();

    this.setState({
      artistName,
      title: "",
      description: "",
      suggestedTags: "",
      artFileName: "UPLOAD ART FILE",
      artPreviewImg: "",
      artworkType: ArtworkType.classic,
      mainColor: "",
      status: '',
      additionalColors: [],
      artHasSubmitted: false,
      isDisableSubmit: false,
    });
  };

  _submissionMessages() {
    // Check Redux for messages

    const {
      submissionSuccessMsg,
      submissionSuccessAlertClear,
      submissionErrorMsg,
      submissionErrorAlertClear,
    } = this.props;

    if (!!submissionSuccessMsg) {
      Swal.fire({
        icon: "success",
        text: submissionSuccessMsg,
        showConfirmButton: false,
      });

      setTimeout(() => {
        submissionSuccessAlertClear();
        this._resetForm();
      }, 2000);
    } else if (!!submissionErrorMsg) {
      Swal.fire({
        icon: "error",
        text: submissionErrorMsg,
        showConfirmButton: false,
      });

      setTimeout(() => {
        submissionErrorAlertClear();
      }, 2000);
    }
  }

  _loadArtwork = async () => {
    try {
      const { title, description, suggestedTags, id, artworkType, previewArt, artFile, mainColor, additionalColors, status } = await this._getArtistSubmissions();

      const selectedMainColor = mainColor ? mainColor : "None";
//      const selectedAdditionalColors = (additionalColors != "") ? additionalColors.split(',') : [];
      const selectedAdditionalColors = additionalColors ? additionalColors : "None";

      console.log(selectedMainColor);
      console.log(selectedAdditionalColors);
      
      this._loadPreviewArt(previewArt);

      this.setState({ title, description, suggestedTags, id, artworkType, artFile, previewArt, mainColor : selectedMainColor, additionalColors: selectedAdditionalColors, status });
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: "Sorry Something went wrong, Please check back later.",
        showConfirmButton: false,
      });
    }
  };

  _getArtistSubmissions = async () => {
    const { token, id } = this.props;
    const {
      data: { submissionDetails },
//    } = await axios.get(`/api/artist/submissions/view/${id}`, {
    } = await axios.get(SITE_BACKEND_API_URL+`/artist/submissions/view/${id}`, {
      headers: {
        Authorization: `JWT ${token}`,
      },
    });

    return submissionDetails;
  };

  render() {
    const {
      id,
      artistName,
      title,
      description,
      suggestedTags,
      // isDisableSubmit,
      artPreviewImg,
      // artHasSubmitted,
      // artFileName,
      artworkType,
      mainColor,
      additionalColors,
      // colorsList,
      // additionalColorsList,
      isDialogOpen,
      openedDialogArtworkType,
      
      isEnlargeImg,
      artFile,
      previewArt,
      status,
    } = this.state;

    const {
      closeSubmissionsView,
      onSubmissionsEdit,
      // eslint-disable-next-line
      flipLeft,
      // eslint-disable-next-line
      flipRight,
      // eslint-disable-next-line
      isFlipLeftDisabled,
      // eslint-disable-next-line
      isFlipRightDisabled,
    } = this.props;

    return (
      <>
        <TabArea>
          <FilterHeader>
            <AdjustableIconWrapper onClick={closeSubmissionsView}>
              <HighlightOffIcon />
            </AdjustableIconWrapper>
          </FilterHeader>
          <SubTitle>Your Submission</SubTitle>
          
          <input type="hidden" name="artworkType" value={artworkType}/>
            
            <ArtworkTypeWrapper>
                <ArtworkTypeOption className={['left', ( artworkType === ArtworkType.classic ? 'active' : '' )]}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{ paddingTop: "2px" }}>Classic Artwork</div>
                        <div><HelpOutlineIcon onClick={(e) => this.openDiaog(e, ArtworkType.classic)} style={{marginLeft: "10px"}} /></div>
                    </div>
                </ArtworkTypeOption>
                <ArtworkTypeOption className={['right', ( artworkType === ArtworkType.allOver ? 'active' : '' )]}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{ paddingTop: "2px" }}>All Over/Patterned Artwork</div>
                        <div><HelpOutlineIcon onClick={(e) => this.openDiaog(e, ArtworkType.allOver)} style={{marginLeft: "10px"}} /></div>
                    </div>
                </ArtworkTypeOption>
            </ArtworkTypeWrapper>
            
            <ArtworkTypeInfoDialog artworkType={openedDialogArtworkType} isOpen={isDialogOpen}  handleClose={this.closeDiaog} />
            
            <SubmitCard>
              <FieldSection>
               <SectionHeading serialNumber = "1" label="Preview Image" />
                {artPreviewImg ? (
                        <PreviewImageWrapper>
                    <PreviewImage
                      src={artPreviewImg}
                      alt="Art Preview"
                      isEnlargeImg={isEnlargeImg}
                      onClick={this.clickEnlargeImg}
                    />
                    </PreviewImageWrapper>
                
              ) : (
                <ArtPreview>
                    <IconBottomSubtitle style={{ position: "absolute" }}>
                      No Art Was Submitted
                    </IconBottomSubtitle>
                </ArtPreview>
              )}
              <MainButton
                type="button"
                style={{ width: "250px" }}
                // loaded={artPreviewImg}
                textAlign="center"
              >
                <AdminDownloadLink href={previewArt} download>
                  Download Print File
                </AdminDownloadLink>
              </MainButton>
              </FieldSection>
              <FieldSection>
                <SectionHeading serialNumber = "2" label="Art File" />
                    <ArtPreview>
                   
                        <MainButton
                          type="button"
                          style={{ width: "200px"}}
                          textAlign="center"
                        >
                          <AdminDownloadLink
                            href={`${artFile}`}
                            download
                          >
                            {artFile ? "Download Submitted File" : <Loading />}
                          </AdminDownloadLink>
                        </MainButton>
                    </ArtPreview>
            </FieldSection>
            </SubmitCard>
            <SubmitCard>
              <FieldSection>
                <SectionHeading serialNumber = "3" label="Artwork Info" />
              <div>
                <FormInputTitleStyled
                  type="text"
                  name="artistName"
                  label="artist_name"
                  data-lpignore="true"
                  value={artistName}
                  readOnly
                />
                <FormInputArtistStyled
                  type="text"
                  name="id"
                  label="id"
                  data-lpignore="true"
                  value={id}
                  style={{ display: "none" }}
                  readOnly
                />
                <FormInputTitleStyled
                  type="text"
                  name="title"
                  label="title"
                  placeholder="TITLE"
                  data-lpignore="true"
                  autoComplete="off"
                  handleChange={this.handleChange}
                  value={title}
                  maxlength="180"
                  required
                  readOnly
                />
                <TextAreaStyled
                  type="text"
                  name="description"
                  label="Description"
                  placeholder="DESCRIPTION"
                  data-lpignore="true"
                  autoComplete="off"
                  handleChange={this.handleChange}
                  value={description}
                  maxlength="255"
                  required
                  readOnly
                />
                
                <TextAreaStyled
                  type="text"
                  name="suggestedTags"
                  label="Suggested Tags"
                  placeholder="Suggested Tags"
                  data-lpignore="true"
                  autoComplete="off"
                  handleChange={this.handleChange}
                  value={suggestedTags}
                  maxlength="255"
                  required
                  readOnly
                />
              </div>
              </FieldSection>
              <FieldSection>
              
              <div style={{ marginTop: "60px", marginBottom: "5px" }}>Main color</div>
              <FormInputTitleStyled
                  type="text"
                  name="artistName"
                  label="artist_name"
                  data-lpignore="true"
                  value={mainColor}
                  readOnly
                />
                
                <div style={{ marginBottom: "5px" }}>Additional colors</div>
                <TextAreaStyled
                  type="text"
                  name="artistName"
                  label="artist_name"
                  data-lpignore="true"
                  value={additionalColors}
                  readOnly
                />
                
                <p style={{ color: "#AAA", fontSize: "14px" }}>Note: Colors may be changed or adjusted at our discretion.</p>
                
              </FieldSection>
            </SubmitCard>

            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
              {  onSubmissionsEdit && status == 'NEW' ? 
                (
                  <BtnArtSubmit
                    type="button"
                    textAlign="right"
                    style={{ backgroundColor: "#0B7C80", cursor: "pointer", width: '200px' }}
                    onClick={() => onSubmissionsEdit(id)}
                  >
                    EDIT
                  </BtnArtSubmit>
                ) : null
              }

              <BtnArtSubmit
                type="button"
                textAlign="right"
                style={{ backgroundColor: "#0B7C80", cursor: "pointer", width: '200px'  }}
                onClick={closeSubmissionsView}
              >
                OK
              </BtnArtSubmit>
            </div>
        </TabArea>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  artistProfile: selectArtistProfile,
  submissionErrorMsg: selectSubmissionsErrorAlert,
  submissionSuccessMsg: selectSubmissionsSuccessAlert,
  token: selectUserJWTToken,
});

const mapDispatchToProps = (dispatch) => ({
  submissionsEditStart: (formData) =>
    dispatch(submissionsEditStart({ formData })),
  submissionErrorAlertClear: () => dispatch(submissionErrorAlertClear()),
  submissionSuccessAlertClear: () => dispatch(submissionSuccessAlertClear()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistSubmissionsView)
);
