import styled from "styled-components";
import { device } from '../../utils/device';

export const TableContainer = styled.div`
  table {
//    border-spacing: 2px;
//    border: 1px solid black;
    width: 100%;
    border-collapse: collapse;
    border-radius: 15px;
    overflow: hidden;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    thead {
        tr {
            background-color: rgb(108, 182, 187);
            color: #fff;
        }
    }

    tbody {
        tr {
            background-color: rgb(238, 238, 238);
        }
    }

    th,
    td {
        margin: 0;
        padding: 0.5rem;
//      border-bottom: 2px solid #fff;
//      border-right: 2px solid #fff;
        border: 2px solid #fff; 

//        :last-child {
//            border-right: 0;
//        }
    }

    td.cell-center {
        text-align: center;
    }

    td.cell-s-no {
        font-weight: bold;
        div.cell-s-no-value {
            background-color: #6CB6BB;
            text-align: center;
            color: #fff;
            padding: 5px 10px;
            border-radius: 5px;
        }
    }

    &.home-table {
        @media ${device.desktopM} {
            font-size: 13px;
        }
    }

  }

  .pagination {
    padding: 0.5rem;
    text-align: center;
    margin-top: 25px;
  }
    
`;

export const FilterDateBtnsWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`;