import React, { CSSProperties } from "react";
import { useState, useEffect } from "react";
import { ArtworkType } from "../../constants";
import SuggestedTags from "../Common/suggested-tags.component";
import DisplayTags from "../Common/display-tags.component";
import CustomAutocomplete from "../Common/custom-autocomplete.component";
import CustomPopover from "../Common/custom-popover.component";
import { PRIMARY_TAG_INFO, OTHER_TAG_INFO } from "../../constants/text";
import { ODAD_AUTOMATION_API_URL } from "../../server";
import { Tag } from 'react-tag-input';
import { ColorsListInterface, Dimension, ArtFormData } from '../AdminArtSubmissions/admin-art-submission-types';
import ColorCheckbox from "../FormInput/color-checkbox.component";
import Swal from "sweetalert2";
import { StyledMultiSelectCheckbox } from "../FormInput";
import { AllOverPrintExDialog } from "../Dialog";
import AllOverPrintExImage from "../../assets/all-over-print-example.jpeg";
import { ArtistCanvas } from "./artist-canvas";
import axios from "axios";

import {
  FormInputTitleStyled,
  TextAreaStyled,
  CaptionTitle,
} from "./artist-submit-art.styles";

import { MainButton } from "../Button"

import { FormControlLabel } from "@material-ui/core";

const ArtistCertifyText = 'I hereby certify that the submitted artwork has been created by and belongs to me and has not been created using any form of AI generation. I understand that any submission that is determined to have been AI generated is subject to removal and that my account may be restricted or deactivated in these instances.';

interface ArtworkFormProps {
  artistName?: string, 
  artworkType: string
  colorsList: ColorsListInterface[];
  stepNumber: number;
  onResetForm: Function;
  artTrimmedImgURL: string;
  hasNextArt: boolean;
  fileIndex: number,
  selectPreviousArt: Function;
  selectNextArt: Function;
  resetForm: Function;
  saveArtFormData: Function;
  // artFormData: ArtFormData | null;
  token: String,
}

export const ArtworkForm = ({
  artistName, 
  artworkType, 
  colorsList, 
  stepNumber, 
  onResetForm, 
  artTrimmedImgURL,
  hasNextArt,
  fileIndex,
  selectPreviousArt,
  selectNextArt,
  resetForm,
  saveArtFormData,
  token,
  // artFormData,
}: ArtworkFormProps) => {

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [mainInspiration, setMainInspiration] = useState('');
  const [primaryTag, setPrimaryTag] = useState('');
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [mainColor, setMainColor] = useState(0);
  const [additionalColors, setAdditionalColors] = useState<number[]>([])
  const [isClarifiedDeclaration, setIsClarifiedDeclaration] = useState(false)
  const [isAllOverExDialogOpen, setIsAllOverExDialogOpen] = useState(false)
  const [artDimension, setArtDimension] = useState<Dimension>();
  const [canvasBackColorCode, setCanvasBackColorCode] = useState('');
  const [mainColorCode, setMainColorCode] = useState('');
  const [duplicateDetectionTimer, setDuplicateDetectionTimer] = useState<NodeJS.Timeout | null>(null);
  const [isTitleExist, setIsTitleExist] = useState(false);
  const [checkingForDuplicate, setCheckingForDuplicate] = useState(false);

  useEffect(() => {
    console.log('useEffect');

    // if (artFormData) {
    //   const { title, description, mainInspiration, primaryTag, suggestedTags, mainColor, additionalColors, isClarifiedDeclaration } = artFormData;

    //   setTitle(title);
    //   setDescription(description);
    //   setMainInspiration(mainInspiration);
    //   setPrimaryTag(primaryTag);
    //   setSuggestedTags(suggestedTags);
    //   setMainColor(mainColor);
    //   setAdditionalColors(additionalColors);
    //   setIsClarifiedDeclaration(isClarifiedDeclaration);
    // } else {
      setTitle('');
      setDescription('');
      setMainInspiration('');
      setPrimaryTag('');
      setSuggestedTags([]);
      setMainColor(0);
      setAdditionalColors([]);
      setIsClarifiedDeclaration(false);
    // }
  }, [fileIndex]);

  const handleChangeTitle = (e: any) => {
    const { value } = e.target as { value: string };
    setTitle(value);
    // save();

    setCheckingForDuplicate(true);

    if (duplicateDetectionTimer) {
      clearTimeout(duplicateDetectionTimer);
    }

    const timer = setTimeout(async () => {
      // Check if title is already taken
      const {
        data
      } = await axios.get("/api/artist/is-art-title-exist?title="+encodeURIComponent(value.trim()), {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      if (data.status === 'TITLE_EXIST') {
        setIsTitleExist(true);
      } else {
        setIsTitleExist(false);
      }

      setCheckingForDuplicate(false);

    }, 2000);

    setDuplicateDetectionTimer(timer);
  }

  const handleChangeDescription = (e: any) => {
    const { value } = e.target as { value: string };
    setDescription(value);
    // save();
  }

  const handleChangeMainInspiration = (e: any) => {
    const { value } = e.target as { value: string };
    setMainInspiration(value);
    // save();
  }

  const handleSuggestedTagsChange = (tags: Tag[]) => {
    const tagNames = tags.map(t => t.text);
    setSuggestedTags(tagNames)
    // save();
  }

  const handleOnDeleteTag = (i: number) => {
      const newTags = suggestedTags.filter((tag, index) => index !== i);
      setSuggestedTags(newTags)
      // save();
  }

  const onPrimaryTagSelected = (event: any, data: any) => {

    // Fetch associated tags
    fetch(ODAD_AUTOMATION_API_URL+'/getAssociatedTags?primary_tag='+data.suggestionValue)
    .then(res => res.json())
    .then(res => {
      setPrimaryTag(data.suggestionValue);
      setSuggestedTags(suggestedTags);
      // save();
    })
  }

  const handlePrimaryTagChange = (tag: string) => {
    setPrimaryTag(tag);
    // save();
  }

  const handleOnDeletePrimaryTag = () => {
    setPrimaryTag('');
    // save();
  }

  const handleMainColorChange = (colorId: number) => {

    // Check if deselect
    if(mainColor == colorId) {
        // this.setState({ 
        //   mainColor : 0, 
        //   mainColorCode: '',
        //   canvasBackColorCode: '',
        // });
        setMainColor(0);
        setMainColorCode('');
        setCanvasBackColorCode('');
    } else {
    
      // Remove main color from additionalColors list
      const updatedAdditionalColorsList = colorsList.filter(color => color['id'] !== colorId);
      const updatedAdditionalColors = additionalColors.filter(color => color !== colorId);

      const mainColorData = colorsList.filter(color => color['id'] == colorId);
      setMainColor(colorId);
      setMainColorCode("#"+mainColorData[0].color_code);
      setAdditionalColors(updatedAdditionalColors);
      setCanvasBackColorCode("#"+mainColorData[0].color_code,);

      // this.setState({ 
      //     mainColor : colorId, 
      //     mainColorCode: "#"+mainColorData[0].color_code,
      //     canvasBackColorCode: "#"+mainColorData[0].color_code,
      //     additionalColorsList: updatedAdditionalColorsList,
      //     additionalColors: updatedAdditionalColors
      // });
    }

    // save();
  };
  
  const handleAdditionalColorChange = (colorId: number) => {
  
    if(additionalColors.includes(colorId)) {
      // Remove
      const newColors = additionalColors.filter(c => c != colorId);
      setAdditionalColors(newColors);
    } else {
      // Allowed to select only 4 colors
      if(additionalColors.length == 4) {
        Swal.fire({
          icon: "error",
          text:
            "Sorry you can select maximum 4 colors only.",
          showConfirmButton: true,
        });
      } else if(colorId == mainColor) { // check if main color is already selected
        Swal.fire({
          icon: "error",
          text:
            "Main color should not be part of additional color.",
          showConfirmButton: true,
        });
      } else {
        // include
        additionalColors.push(colorId);
        setAdditionalColors(additionalColors)
      }
    }

    // save();
  };

  const handleMainColorMouseOver = (colorHash: string, type: 'IN'|'OUT') => {
    if(type == 'IN') {
      setCanvasBackColorCode(colorHash);
    } else {
      setCanvasBackColorCode(mainColorCode);
    }
  }

  const getColorInfo = (color: ColorsListInterface) => {
    return {
      colorId: color.id, 
      colorCode: color.color_code, 
      colorName: color.color_name,
      isLightColor: color.is_light_color
    }
  }

  const clarifyDeclaration = (e: any, checked: boolean) => {
    setIsClarifiedDeclaration(checked);
    // save();
  };

  const handleClarifyDeclaration = () => {
    setIsClarifiedDeclaration(!isClarifiedDeclaration);
    // save();
  };

  const openAllOverEx = (e: any) => {
    console.log('_openAllOverEx');
    setIsAllOverExDialogOpen(true);
  }

  const handleArtDimentionChange = (artDimension: Dimension) => {
    setArtDimension(artDimension)
  }

  const handleExit = () => {
    onResetForm();
  }

  const handleRevertToOriginal = () => {
    setMainColorCode('');
    setMainColor(0);
    setCanvasBackColorCode('');
    setAdditionalColors([]);
  }

  const save = () => {
      const formFields = {
        title,
        description,
        mainInspiration,
        primaryTag,
        suggestedTags,
        mainColor,
        additionalColors,
        isClarifiedDeclaration,
    }
    saveArtFormData(fileIndex, formFields);
  }

  const saveAndGotoNextArt = (event: any) => {
    event.preventDefault();
    
    if (isTitleExist) {
      Swal.fire({
        icon: "error",
        text:
          "Title already taken.",
        showConfirmButton: true,
      });
      return;
    }

    save();
    // selectNextArt();
  }

  return (
  <div style={{display: (stepNumber >= 2) ? 'flex': 'none', justifyContent: "space-between", marginTop: "30px"}}>
    <ArtistCanvas 
      artworkType={artworkType} 
      imgSrc={artTrimmedImgURL} 
      backgroundColor={canvasBackColorCode} 
      handleArtDimentionChange={handleArtDimentionChange}
      handleExit={handleExit}
      handleRevertToOriginal={handleRevertToOriginal}
      isEdit={false}
    /> 
    <div style={{width: "45%"}} className="artist-side">
      <FormInputTitleStyled
        type="text"
        name="artistName"
        label="artist_name"
        data-lpignore="true"
        value={artistName ?? artistName}
        readOnly
      />

      <FormInputTitleStyled
        type="text"
        name="title"
        label="title"
        placeholder="Title *"
        data-lpignore="true"
        autoComplete="off"
        handleChange={handleChangeTitle}
        value={title}
        maxlength="180"
        required
      />
      {checkingForDuplicate && <p style={{
        color: '#888', 
        width: '75%', 
        margin: 'auto',
        marginTop: 0, 
        marginBottom: '25px'
        }}>Checking for availability...</p>}

      { !checkingForDuplicate && isTitleExist && <p style={{ 
        color: 'red', 
        width: '75%', 
        margin: 'auto', 
        marginTop: 0, 
        marginBottom: '25px'}}>
          Error: Title already taken
        </p>}

      <TextAreaStyled
        type="text"
        name="description"
        label="Description"
        placeholder="Description *"
        data-lpignore="true"
        autoComplete="off"
        handleChange={handleChangeDescription}
        value={description}
        maxlength="255"
        required
      />

      <FormInputTitleStyled
        type="text"
        name="mainInspiration"
        label="Main Inspiration"
        placeholder="Main Inspiration *"
        data-lpignore="true"
        autoComplete="off"
        handleChange={handleChangeMainInspiration}
        value={mainInspiration}
        maxlength="180"
        required
      />

      <CaptionTitle> Primary Tag:
        <CustomPopover text={PRIMARY_TAG_INFO} />
      </CaptionTitle>
      {!primaryTag && <CustomAutocomplete 
        placeholder="Please enter primary tag"
        onChange={() => {}}
        value={primaryTag}
        onSuggestionSelected={onPrimaryTagSelected}
        isAdmin={false}
        onPrimaryTagChange={handlePrimaryTagChange}
        />
      }
      <DisplayTags 
        tags={primaryTag} 
        isDeletable={true}
        handleOnDelete={handleOnDeletePrimaryTag}/>

      <CaptionTitle>Other Tags:
        <CustomPopover text={OTHER_TAG_INFO} />
      </CaptionTitle>
      <SuggestedTags 
        onChange={handleSuggestedTagsChange} 
        selectedTags={suggestedTags}
      />
      <DisplayTags 
        tags={suggestedTags.join(',')} 
        isDeletable={true}
        handleOnDelete={handleOnDeleteTag}
      />

      { artworkType == ArtworkType.classic ? (
        <div style={{width: "80%", margin: "auto"}}>
          <div style={{padding: "10px 0", fontWeight: "bold", marginTop: "10px"}}>Choose Main Color</div>
          <div style={{display: "flex", flexFlow: "wrap"}}>
            {colorsList.map((color) => ( 
              <ColorCheckbox key={color.id}
                colorInfo={getColorInfo(color)} 
                isChecked={mainColor == color.id} 
                handleColorChange={handleMainColorChange}    
                handleMouseOver={handleMainColorMouseOver} />
            ))}
          </div>
        
          <div style={{padding: "10px 0", fontWeight: "bold", marginTop: "20px"}}>Choose Additional Colors (4 Max)</div>
          <div style={{display: "flex", flexFlow: "wrap"}}>
            {colorsList.map((color) => ( 
              <ColorCheckbox key={color.id}
                colorInfo={getColorInfo(color)}
                isChecked={additionalColors.indexOf(color.id) > -1}   
                handleColorChange={handleAdditionalColorChange} 
                handleMouseOver={handleMainColorMouseOver}/>     
            ))}
          </div>
        </div>
      ) : null}

      <div style={{margin: "30px auto", width: "80%"}}>
        <FormControlLabel 
          control={
            <StyledMultiSelectCheckbox 
              checked={ isClarifiedDeclaration } 
              required={true}
              onInvalid={e => (e.target as HTMLInputElement).setCustomValidity('Please read and verify the submission terms.')} 
              onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
            />
          } 
          onChange={ clarifyDeclaration } 
          label=""/>
        <label style={{cursor: 'pointer', lineHeight: "26px"}} onClick={ handleClarifyDeclaration }>
          * { ArtistCertifyText }
        </label>
      </div>

      <div style={{margin: "10px auto 50px auto", width: "80%"}}> * Required Fields</div>

      { artworkType == ArtworkType.allOver && 
        <>
          <div style={{margin: "10px auto 50px auto", width: "80%"}}>
            <a href="javascript:void(0)" onClick={openAllOverEx}>Click here</a> to see an example of all over print.
          </div>

          <AllOverPrintExDialog 
            isOpen={isAllOverExDialogOpen} 
            handleClose={() => setIsAllOverExDialogOpen(false)} 
            imageDataUrl={AllOverPrintExImage}
            />
        </>
      } 

      <div style={{position: "absolute", right: "60px", bottom: "20px", display: "flex" }}>

      {/* fileIndex > 0 &&
        <MainButton type="button" textAlign="right" style={{width: "140px", marginRight: "10px" }} onClick={selectPreviousArt}>
          Previous
        </MainButton>
     */}
      
      <MainButton type="button" style={{width: "200px", marginRight: "10px"}} onClick={resetForm}>
        Exit without Saving
      </MainButton>
      
      { (!isTitleExist && !checkingForDuplicate) && 
        <MainButton type="button" textAlign="right" style={{width: "140px"}} onClick={saveAndGotoNextArt}>
          { hasNextArt ? 'Save and Next' : 'Save' }
        </MainButton>
      }
      
      </div>
    </div>
  </div>)
}