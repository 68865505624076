const ArtistActionTypes = {
  // Artist Profile
  SET_ARTIST_PROFILE: "SET_ARTIST_PROFILE",
  CLEAR_ALL_ARTIST_DETAILS: "CLEAR_ALL_ARTIST_DETAILS",
  // Get artist profile from database
  GET_ARTIST_PROFILE_START: "GET_ARTIST_PROFILE_START",
  GET_ARTIST_PROFILE_SUCCESS: "GET_ARTIST_PROFILE_SUCCESS",
  // Create Artist Profile
  CREATE_ARTIST_PROFILE_START: "CREATE_ARTIST_PROFILE_START",
  CREATE_ARTIST_PROFILE_SUCCESS: "CREATE_ARTIST_PROFILE_SUCCESS",
  // Update Artist Profile
  UPDATE_ARTIST_PROFILE_START: "UPDATE_ARTIST_PROFILE_START",
  UPDATE_ARTIST_PROFILE_SUCCESS: "UPDATE_ARTIST_PROFILE_SUCCESS",
  // Error Handling
  ARTIST_PROFILE_FAILURE: "ARTIST_PROFILE_FAILURE",
  CLEAR_ARTIST_ERROR: "CLEAR_ARTIST_ERROR",
  // HTTP Response messages
  ARTIST_SUCCESS_ALERT: "ARTIST_SUCCESS_ALERT",
  ARTIST_SUCCESS_ALERT_CLEAR: "ARTIST_SUCCESS_ALERT_CLEAR",
  ARTIST_ERROR_ALERT: "ARTIST_ERROR_ALERT",
  ARTIST_ERROR_ALERT_CLEAR: "ARTIST_ERROR_ALERT_CLEAR",
};

export default ArtistActionTypes;
