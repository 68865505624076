import React, { useState, useEffect } from 'react';
import Autosuggest, { RenderInputComponentProps, OnSuggestionSelected } from 'react-autosuggest';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectSuggestedTagNames } from "../../redux/settings/settings.selector";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { FormInputTitleEditable } from '../AdminArtApproval/admin-art-approval.styles';
import { FormInputTitleStyled } from '../ArtistSubmitArt/artist-submit-art.styles';
import { capitalizeSentence } from '../../utils';

interface CustomAutocompleteProps {
  placeholder: string;
  dropdownItems: string[];
  onChange: Function;
  onPrimaryTagChange: Function;
  onSuggestionSelected: OnSuggestionSelected<string>;
  value: string;
  isAdmin: boolean;
}

const CustomAutocomplete = (props: CustomAutocompleteProps) => {

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [value, setValue] = useState<string>(props.value);

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : props.dropdownItems.filter(item =>
      item.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion: string) => suggestion;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion: string) => (
    <div>
      {suggestion}
    </div>
  );

  const onChange = (event: any, { newValue }: any) => {
    setValue(newValue)
    props.onChange(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }: {value: string}) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = (inputProps: RenderInputComponentProps) => {
    return props.isAdmin ? <FormInputTitleEditable {...inputProps} /> : <FormInputTitleStyled {...inputProps} />
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: props.placeholder,
    value,
    onChange,
    onKeyUp: function(e: any) {
      if (e.keyCode == 13 || e.keyCode == 188) { // KeyCode For enter is 13 and comma is 188

        const newValue = capitalizeSentence(e.target.value);

        setValue(newValue)
        props.onPrimaryTagChange(newValue);
        return false;
      }
    },
    onBlur: function(e: any) {
      if (e.target && e.target.value) {
        const newValue = capitalizeSentence(e.target.value);

        setValue(newValue)
        props.onPrimaryTagChange(newValue);
      }
    }
  };

  // Finally, render it!
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={props.onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
      focusInputOnSuggestionClick={false}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  dropdownItems: selectSuggestedTagNames,
});

export default connect(mapStateToProps)(CustomAutocomplete);