import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class QuillEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = { content: "" } // You can also pass a Quill Delta here
  }
 
    handleChange = (value) => {
      this.setState({ content: value })
    }
  
    save = () => {
      const { content } = this.state;
      console.log(content);
    }
 
  render() {
    return (
            <>
      <ReactQuill theme="snow" value={this.state.content} onChange={this.handleChange} />
        <input type="button" onClick={this.save} value="Save" />
        </>
    )
  }
}

export default QuillEditor;