import React, { useMemo } from "react"
import { useTable, useFilters, useSortBy, usePagination } from "react-table";

import { DefaultColumnFilter, fuzzyTextFilterFn } from "../../libs/table";
import { TableContainer } from "./table.styles";


// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
const ArtistTable = ({ columns, data, showPagination = true, className, isSortingEnabled = false }) => {
    
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // Paginate
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, 
      filterTypes,
    },
    useFilters, 
    isSortingEnabled ? useSortBy : "",
    usePagination
  );

  return (
    <TableContainer>
      <table {...getTableProps({className: className})}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()} style={{backgroundColor:"#6CB6BB", color: "white"}}>
              {headerGroup.headers.map((column, j) => (

                isSortingEnabled ? (
                  <th key={j} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Render the columns filter UI */}
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                    <span>{ column.canSort ? (column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : "") : "" }</span>
                  </th>
                ) : (
                  <th key={j} {...column.getHeaderProps()}>
                    {column.render("Header")}
                    {/* Render the columns filter UI */}
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                  </th>
                )
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()} style={{backgroundColor:"#EEEEEE"}}>
                {row.cells.map((cell, j) => {
                  return (
                    <td key={j} {...cell.getCellProps({
                        className: cell.column.className,
                        style: cell.column.style,
                      })}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />

      {/* Pagination */}
      { showPagination ? (
      <div className="pagination">
        <button onClick={() => previousPage()} disabled={!canPreviousPage} style={{borderRadius: "2rem", color:"white", fontSize: "1.25rem", backgroundColor:"lightgrey", height: "2rem", width: "2rem", marginRight: "2rem", border:"none"}}>
          {"<"}
        </button>
        <span>
          Page
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage} style={{borderRadius: "2rem", color:"white", fontSize: "1.25rem", backgroundColor:"lightgrey", height: "2rem", width: "2rem", marginLeft: "2rem", border:"none"}}>
          {">"}
        </button>
      </div>
      ): null }

      {false && process.env.NODE_ENV === "development" ? (
        <div>
          <pre>
            <code>
              {JSON.stringify(
                {
                  filters,
                  pageIndex,
                  pageSize,
                  pageCount,
                  canNextPage,
                  canPreviousPage,
                },
                null,
                2
              )}
            </code>
          </pre>
        </div>
      ) : null}
    </TableContainer>
  );
}

export default ArtistTable;
