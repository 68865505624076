
// ArtworkType based on submissions table, artwork_type enum
export const ArtworkType = {
    classic : 'CLASSIC',
    allOver : 'ALL_OVER_PATTERNED'
}

// ScheduleType based on submissions table, schedule_type enum
export const ScheduleType = {
    ODAD : 'ODAD',
    WEEKLY : 'WEEKLY',
    MYSTERY_BOX: 'Mystery Box'
}

export const ProductCreationTypes = ['ODAD', 'WEEKLY', 'GALLERY', 'ALL OVER PRINT'];

// User type based on artist-dashboard table, is_admin
export const UserRole = {
    ARTIST : 0,
    ADMIN : 1,
    ADMIN_VIEW_ONLY: 2
}

export const WindowsSavedData = {
    scrollTop: 0
}

export const Status = {
    ACTIVE: "L",
    INACTIVE: "D"
}

export const ArtStatus = {
    NEW: 'NEW', 
    APPROVED: 'APPROVED', 
    PUBLISHED: 'PUBLISHED', 
    EXPORTED: 'EXPORTED',
    DECLINED: 'DECLINED',
    DELETED: 'DELETED',
}

export const AutomationAllowedInStatus = [ArtStatus.APPROVED, ArtStatus.PUBLISHED, ArtStatus.EXPORTED];

export const ARTS_PER_PAGE = 12;
export const MAX_ART_SELECTION_ALLOWED = 150;