import styled from "styled-components";


export const ReportsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ReportContainer = styled.div`
 
`;

export const ReportHeading = styled.h3`
    font-size: 16px;
    text-align: center;
    background-color: #6CB6BB;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 8px;
`;