import React, { useState, useEffect } from "react";
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectSuggestedTags } from "../../redux/settings/settings.selector";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { capitalizeSentence } from "../../utils";
import './suggested-tags.css';
  
const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

interface ISuggestedTagsProps {
    suggestedTags: string[]; 
    selectedTags: string[];
    onChange: Function;
}

const SuggestedTags = (props: ISuggestedTagsProps) => {

    const { suggestedTags, selectedTags, onChange } = props;    

    const [tags, setTags] = useState<Tag[]>([]);
    const [suggestedTagsList, setSuggestedTagsList] = useState<Tag[]>([]);

    useEffect(() => {
        const suggestedTagsData: Tag[] = suggestedTags ? suggestedTags.map((tag: any) => {
            return { id: tag.id.toString(), text: tag.tag_name }
        }) : [];
    
        setSuggestedTagsList(suggestedTagsData);
    }, [suggestedTags]);

    useEffect(() => {
        
        const selectedTagsLowerCase = selectedTags.map(st => st.toLocaleLowerCase());
        // setTags(suggestedTagsList.filter(t => !!t.text && selectedTagsLowerCase.includes(t.text.toLocaleLowerCase())));

        // Fixed order: It should be same order as entered instead of alphabetical
        const finalList = selectedTagsLowerCase.map(t => suggestedTagsList.find(tList => tList.text.toLocaleLowerCase() == t)).filter(t => t) as Tag[];
        setTags(finalList);
    }, [selectedTags, suggestedTagsList]);

    const handleDelete = (i: any) => {
        const newTags = tags.filter((tag, index) => index !== i);
        setTags(newTags);
        onChange(newTags);
    }

    const handleAddition = (tag: Tag) => {

        // All tags should have the convention of first letter of each work being capitalized. E.g. Cat Woman or Harry Potter.
        tag.text = capitalizeSentence(tag.text);

        const newTags = [...tags, tag];
        setTags(newTags);
        
        const suggestionFound = suggestedTagsList.filter(s => s.text.toLowerCase() == tag.text.toLocaleLowerCase());

        // add to suggested tags list if not exist
        if(suggestionFound.length == 0) {
            // console.log('Adding new suggestion');
            setSuggestedTagsList([...suggestedTagsList, tag]);
        }
        onChange(newTags);
    }
    
    const handleFilterSuggestions = (textInputValue: string, possibleSuggestions: Tag[]): Tag[] => {
    
        const lowerCaseQuery = textInputValue.toLowerCase();
        
        return possibleSuggestions.filter(function(suggestion)  {
            return suggestion.text.toLowerCase().includes(lowerCaseQuery)
        })
    }
    
    const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
        if(tags) {
            const tags1 = [...tags];
            const newTags = tags1.slice();

            newTags.splice(currPos, 1);
            newTags.splice(newPos, 0, tag);

            // re-render
            setTags(newTags);
            onChange(newTags);
        }
    }

    return (
        <ReactTags tags={tags}
            autofocus={false}
            inputFieldPosition="top"
            suggestions={suggestedTagsList}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleFilterSuggestions={handleFilterSuggestions}
            handleDrag={handleDrag}
            allowDeleteFromEmptyInput={false}
            delimiters={delimiters} 
        />
    )
}

const mapStateToProps = createStructuredSelector({
    token: selectUserJWTToken,
    suggestedTags: selectSuggestedTags,
  });
  
  export default connect(mapStateToProps)(SuggestedTags);