import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken, selectUserAccount } from "../../redux/user/user.selector";
import { logoutStart } from "../../redux/user/user.action";
import TableQueries from "../Table/table-queries.component";

import { COMMON_DATE_RANGE, COMMON_DATE_PROP, DateRangeInterface, Loader } from "../Common";
import { Report } from "./report.component";
import { setConfigStart } from "../../redux/settings/settings.action";

import { SubmissionContainer } from "../SharedStyle/styled";
import { ReportsContainer } from "./admin-dashboard.styles"
import axios, { AxiosRequestConfig } from "axios";
import Swal from "sweetalert2";
import { Popover } from "@material-ui/core";
import { dateToLocaleDateString } from "../../utils";

interface UserAccountInterface {
  isAdmin: Boolean;
}

interface AdminDashboardInterface {
  userAccount: UserAccountInterface;
  logOut: Function;
  token: String
  setConfigStartAction: any
}

type KeyValuePairList = { [key: string]: string; }[];

const AdminDashboard = ({ userAccount, logOut, token, setConfigStartAction }: AdminDashboardInterface) => {

  const [artistsReport, setArtistsReport] = useState<KeyValuePairList>([]);
  const [submissionsReport, setSubmissionsReport] = useState<KeyValuePairList>([]);
  const [commissionsReport, setCommissionsReport] = useState<KeyValuePairList>([]);
  const [topSellersReport, setTopSellersReport] = useState<KeyValuePairList>([]);
  const [topArtistsReport, setTopArtistsReport] = useState<KeyValuePairList>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  // Set first date of the month
  COMMON_DATE_RANGE.startDate = new Date(COMMON_DATE_RANGE.startDate.getFullYear(), COMMON_DATE_RANGE.startDate.getMonth(), 1);

  // TODO: admin summary of different components
  let history = useHistory();

  useEffect(
    () => {
      const redirectUser = () => {
        const { isAdmin } = userAccount;
        if (!isAdmin) {
          logOut();
          history.push("/");
        }
        return;
      };

      redirectUser();
    },
    // eslint-disable-next-line
    [userAccount]
  );

  const artistsReportHeadings = ["Total", "Joined", "Active", "Productive"];
  const submmissionsReportHeadings = ["NEW", "Declined", "Reviewed", "Approved", "Published"];
  const commissionsReportHeadings = ["Sold Units", "Commissions", "Paid", "Unpaid", "Donations"];
  const topSelllerssReportHeadings = ["Title", "Artist Name", "Sold Units"];
  const topArtistsReportHeadings = ["Artist Name", "Sold Units", "Commissions"];

  useEffect(() => {
    setConfigStartAction();
    _getReports(COMMON_DATE_RANGE);
  }, []);

  const _getReports = async (dateRange: DateRangeInterface) => {

    setIsLoading(true);

    const { startDate, endDate } = dateRange;
    const start = dateToLocaleDateString(new Date(startDate));
    // .toLocaleDateString("en-CA");
    const end = dateToLocaleDateString(new Date(endDate));
    // .toLocaleDateString("en-CA");

    const request: AxiosRequestConfig = {
      method: "POST",
      data: { startDate: start, endDate: end },
      url: "/api/admin/reportings/dashboard",
      headers: { Authorization: `JWT ${token}` }
    };

    try {
    
        const {
          data: { artistsReport, submissionsReport, commissionsReport, topSellersReport, topArtistssReport },
        } = await axios(request);
        
        // console.log(submissionsReport);

        setSubmissionsReport([submissionsReport]);
        setArtistsReport([artistsReport]);
        setCommissionsReport([commissionsReport]);
        setTopSellersReport(topSellersReport);
        setTopArtistsReport(topArtistssReport);

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

    setIsLoading(false);
  };

const handleDateFilter = async ({ startDate, endDate }: DateRangeInterface) => {
    try {

      COMMON_DATE_RANGE.startDate = new Date(startDate + " 00:00:00");
      COMMON_DATE_RANGE.endDate = new Date(endDate + " 00:00:00");
      
      _getReports(COMMON_DATE_RANGE);
      
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

    return;
  }

  const open = Boolean(popoverAnchorEl);
  const id = open ? 'simple-popover' : undefined;

  return <SubmissionContainer style={{padding: "20px", marginTop: "0px"}}>
      <h2>Teefury Artist Dashboard </h2>

      
        

        <Popover
          id={id}
          open={open}
          anchorEl={popoverAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ul style={{ paddingRight: "20px" }}>
              <li><b>Total</b> - an overall number of artists in AD.</li>
              <li><b>Joined</b> - newly registered artists in a specific time frame.</li>
              <li><b>Active</b> - artists who have more than <b>1 submission</b> in a specific time frame.</li>
              <li><b>Productive</b> - artists who have more than <b>1 sold unit</b> in a specific time frame.</li>
          </ul>
        </Popover>
      
        <TableQueries
          handleDateFilter={handleDateFilter}
          globalStartDate={COMMON_DATE_RANGE.startDate}
          globalEndDate={COMMON_DATE_RANGE.endDate}
          isDateOpened={COMMON_DATE_PROP.isDateOpened}
          note="Note: Default is Month to date."
        />
        { isLoading ? <Loader/> : (
          <>
          <ReportsContainer>
            <Report title="Artists" headings={artistsReportHeadings} list={artistsReport} width={32} popover={handleClick} />
            <Report title="Submissions" headings={submmissionsReportHeadings} list={submissionsReport} width={32} popover={null}/>
            <Report title="Commissions" headings={commissionsReportHeadings} list={commissionsReport} width={32} popover={null}/>
          </ReportsContainer>

          <ReportsContainer style={{ marginTop: "50px" }}>
            <Report title="TOP - 20 SELLERS" headings={topSelllerssReportHeadings} list={topSellersReport} width={48} popover={null}/>
            <Report title="TOP - 20 ARTISTS" headings={topArtistsReportHeadings} list={topArtistsReport} width={48} popover={null}/>
          </ReportsContainer>
        </>)}
    </SubmissionContainer>;
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  userAccount: selectUserAccount,
});

const mapDispatchToProps = (dispatch: any) => ({
  logOut: () => dispatch(logoutStart()),
  setConfigStartAction: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
