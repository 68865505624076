import React, { useState, useEffect } from "react";
import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import {
  CardFooter,
} from "../ArtCards//art-submissions-card.styles";

import { HomeCardContainer, HomeImgCard, HomeCardWrapper } from "./artist-home-submissions-card.styles";

const ArtistHomeArtCard = ({
  token,
  previewArt,
  status,
  id,
  index,
  title,
  openSubmissionsEdit,
}) => {
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const fetchImage = () => {
      const thumbImg = `/api/art-submissions-thumb/?src=${previewArt.substring(
        20
      )}&w=180`;
      fetch(thumbImg, { signal, headers: { Authorization: `JWT ${token}` } })
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          setImageSrc(URL.createObjectURL(blob));
        })
        .catch((error) => {
          // console.error(error);
        });
    };
    
    if (!previewArt) {
      setImageSrc(teefuryBirdLogo);
    } else {
      fetchImage();
    }

    return () => {
      controller.abort();
    };
  }, [token, previewArt]);

  return (
    <HomeCardContainer>
      <HomeCardWrapper id={id} data-index={index} onClick={openSubmissionsEdit}>
        <HomeImgCard
          src={imageSrc ? imageSrc : teefuryBirdLogo}
          alt={title}
          loaded={imageSrc ? "true" : ""}
        />
        {/* <p className="title">{title}</p> */}
        <CardFooter>{status}</CardFooter>
      </HomeCardWrapper>
    </HomeCardContainer>
  );
};

export default ArtistHomeArtCard;
