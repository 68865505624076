import React from "react";
import Nav from "../../components/Nav";

import { PageContainer, PageWrapper } from "../SharedStyle/style";
import AdminCalendar from "../../components/AdminCalendar/admin-calendar.component";

const AdminCalendarPage = () => {
  return (
    <PageContainer>
      <Nav />
      <AdminCalendar />
    </PageContainer>
  );
};

export default AdminCalendarPage;