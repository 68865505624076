import React from "react";

import ExcludeProductSettings from "./exclude-product-settings.component";
import ColorSettings from "./color-settings.component";
import ScheduleSettings from "./schedule-settings.component";
import ArtStatusSettings from "./art-status-settings.component";

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabArea,
  TabSubLink,
  TabSubTitle,
} from "../SharedStyle/styled";

const ConfigurationSettings = () => {
  return (
    <SubmissionContainer>
      <TabHeader>
        <TabSubLink to={`/admin/settings`}>
          <TabSubTitle>Admin Settings</TabSubTitle>
        </TabSubLink>
        <TabTitle>Configuration Settings</TabTitle>
        <TabSubLink to={`/admin/settings/dashboard`}>
          <TabSubTitle>Dashboard Settings</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/settings/designer-tips`}>
          <TabSubTitle>Designer Tips</TabSubTitle>
        </TabSubLink>
      </TabHeader>
      <TabArea>
        <ExcludeProductSettings />
        <ColorSettings />
        <ScheduleSettings />
        <ArtStatusSettings />
      </TabArea>
    </SubmissionContainer>
  );
};

export default ConfigurationSettings;