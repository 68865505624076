import React from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";

import Nav from "../../components/Nav/nav.component";
// import AdminSubmitArt from "../../components/AdminSubmitArt";
import ArtistSubmitArt from "../../components/ArtistSubmitArt";

import AdminArtSubmissionsGrid from "../../components/AdminArtSubmissions";
import AdminArtSubmissionsThumb from "../../components/AdminArtSubmissions/admin-art-submissions-thumb.component";

import {
  ArtistContainer,
  SubmissionWrapper,
} from "./admin-art-submissions.styles";

const AdminArtSubmissionsPage = () => {
  const { path } = useRouteMatch();

  let { search } = useLocation();
  const query = new URLSearchParams(search);

  return (
    <ArtistContainer>
      <Nav />
      <Switch>
        <Route exact path={path}>
          <SubmissionWrapper>
            <ArtistSubmitArt byAdmin={true} artistName={query.get('artist')}/>
          </SubmissionWrapper>
        </Route>
        <Route path={`${path}/grid/:status/:id?`}>
          <SubmissionWrapper>
            <AdminArtSubmissionsGrid />
          </SubmissionWrapper>
        </Route>
        <Route path={`${path}/thumb/:status`}>
          <SubmissionWrapper>
            <AdminArtSubmissionsThumb />
          </SubmissionWrapper>
        </Route>
      </Switch>
    </ArtistContainer>
  );
};

export default AdminArtSubmissionsPage;
