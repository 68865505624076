import React, { CSSProperties } from "react";
import { useState, useEffect } from "react";
import { Slider, Checkbox, withStyles, Backdrop } from "@material-ui/core";
import { MainButton } from "../Button"
import { BottomInstruction } from "./artist-submit-art.styles";
import { ArtworkType } from "../../constants";
import { ArtLargePrview } from "../Dialog/dialog.component"
import { Point, Dimension } from '../AdminArtSubmissions/admin-art-submission-types';
import TransparentImage from "../../assets/transparent.jpg";
import { Loader } from "../Common";

const TealCheckbox = withStyles({
    root: {
      color: "#0a7c80",
      "&$checked": {
        color: "#0a7c80",
      },
    },
    checked: {},
  })((props: any) => <Checkbox color="default" {...props} />);

const CustomSlider = withStyles({
    root: { color: "#0a7c80" },
    track: { height: "5px" },
    rail: { height: "5px" },
    thumb: { width: "15px", height: "15px" }
})((props: any) => <Slider style={{width: "50%"}} {...props} />);

interface SelectButtonInterrface {
    label: string;
    onClick: Function;
    checked: boolean;
}

const SelectButton = ({label, onClick, checked} : SelectButtonInterrface) => (
    <MainButton type="button" style={{width: "200px", backgroundColor: "#c3e1e3", color: "#0a7c80" }} onClick={onClick}>
        <TealCheckbox checked={checked}></TealCheckbox>{label}
    </MainButton>
);

interface ArtistCanvasInterface {
    artworkType: string;
    imgSrc: string;
    backgroundColor: string;
    handleArtDimentionChange: Function;
    handleExit: Function;
    handleRevertToOriginal: Function;
    isEdit: boolean;
    onChangeImage?: Function;
    isHideUpdateButton?: boolean;
}

export const ArtistCanvas = ({ artworkType, imgSrc, backgroundColor, handleArtDimentionChange, onChangeImage, handleExit, handleRevertToOriginal, isEdit, isHideUpdateButton }: ArtistCanvasInterface) => {

    const canvas = React.createRef<HTMLCanvasElement>();

    // Fixed canvas dimention to 12x16 for Classic and 12x20 //  max 2x = 1152 x 1536 as 12"x16"
    const canvasDimention = (artworkType == ArtworkType.classic) ? { width: 576, height: 768  } : { width: 600, height: 1000  } as Dimension;
    const maxAxisPrint = (artworkType == ArtworkType.classic) ? { x: 12, y: 16  } : { x: 60, y: 100 } as Point;
    // const imageDimention = { width: 0, height: 0  } as Dimension;

    const [context, setContext] = useState<CanvasRenderingContext2D|null>();
    const [currentPosition, setCurrentPosition] = useState<Point>({x: 0, y: 0});
    const [initialArtDimention, setInitialArtDimention] = useState<Dimension>({width: 0, height: 0});
    const [artDimention, setArtDimention] = useState<Dimension>({width: 0, height: 0});
    const [maxArtDimention, setMaxArtDimention] = useState<Dimension>({width: 0, height: 0});
    const [imageDimention, setImageDimention] = useState<Dimension>({width: 0, height: 0});
    const [artImage, setArtImage] = useState<HTMLImageElement>();
    const [isArtImageLoaded, setIsArtImageLoaded] = useState(false);
    const [transparentImage, setTransparentImage] = useState<HTMLImageElement>();
    const [transparentDimention, setTransparentDimention] = useState<Dimension>({width: 0, height: 0});
    const [imageDataUrl, setImageDataUrl] = useState("");
    const [zoomPercentage, setZoomPercentage] = useState(0);
    const [isOpenPreviewDialog, setIsOpenPreviewDialog ] = useState(false);

    // const [alignCenter, setAlignCenter] = useState(true);
    // const [alignTop, setAlignTop] = useState(false);
    // const [isDraggable, setIsDraggable] = useState(false);

    useEffect(() => {

        // Check if reset

        // Draw image on canvas
        // if(canvas.current && imgSrc) {
        if(imgSrc) {

            console.log("imgSrc: ");
            console.log(imgSrc)
            setIsArtImageLoaded(false);

            // const ctx = canvas.current.getContext('2d');

            const selectedImage = new Image();
            selectedImage.src = imgSrc;
            selectedImage.onload = () => {

                setArtImage(selectedImage);
                setIsArtImageLoaded(true);

                const { width, height } = selectedImage;
                console.log({ width, height });
                setImageDimention({ width, height });

                const currentRatio = calculateAspectRatioFit(width, height);
                setArtDimention(currentRatio);
                setInitialArtDimention(currentRatio);

                // Set max zoom ratio (used while zooming)
                if(currentRatio.width == canvasDimention.width || currentRatio.height == canvasDimention.height) {
                    setMaxArtDimention(currentRatio);
                }
            }

            const tImage = new Image();
            tImage.src = TransparentImage;
            tImage.onload = () => {
                setTransparentImage(tImage);

                const { width, height } = tImage;
                setTransparentDimention({ width, height });
            }

            // setContext(ctx);
            
        } else {
            if(canvas.current && context) {
                context.clearRect(0, 0, canvas.current.width, canvas.current.height);
            }
        }
    }, [imgSrc]);

    useEffect(() => {
        if(canvas.current && artImage && context && transparentImage) {
            context.clearRect(0, 0, canvas.current.width, canvas.current.height);

            // Set position (Always top center)
            const centerX = (canvasDimention.width - artDimention.width) / 2;

            if(backgroundColor) {
                console.log("background color");
                context.fillStyle = backgroundColor;
                context.fillRect(0, 0, canvas.current.width, canvas.current.height);
            } else {
                console.log("background transparent");
                context.fillRect(0, 0, canvas.current.width, canvas.current.height);

                // Set transparent background as image
                context.drawImage(transparentImage, 
                    0, 0, transparentDimention.width, transparentDimention.height,  
                    0, 0, canvasDimention.width, canvasDimention.height);     
            }

            console.log(artImage);

            
            context.drawImage(artImage, 
                0, 0, imageDimention.width, imageDimention.height,  // always full image content
                centerX, currentPosition.y, artDimention.width, artDimention.height); // Adjust display size to fixed dimention of 12x16.

            handleArtDimentionChange(artDimention);
        }
    }, [currentPosition, artDimention, backgroundColor, transparentDimention]);

    /*
    const _zoomImage = (event: React.ChangeEvent<{}>, value: number | number[]) => {

        let { width, height } = initialArtDimention;

        const factor = (100 - (value as number)) / 100;
        if(factor != 0) {
            const absoluteFactor = Math.abs(factor);

            if(factor < 0) { // Zoom Out
                width = width * absoluteFactor;
                height = height * absoluteFactor;
            } else { // Zoom In
                width = width / absoluteFactor;
                height = height / absoluteFactor;
            }   

            // check if size is srinked upto max size
            if(width <= canvasDimention.width && height <= canvasDimention.height) { // zoomed size is smalled than max size
                setArtDimention({ width, height });
            } else { // zoomed size is bigger than max size
                // This ratio will be max so let's save so that no need to calculate again
                if(maxArtDimention.width == 0) {
                    const maxRatio = calculateAspectRatioFit(width, height);
                    setArtDimention(maxRatio);
                    setMaxArtDimention(maxRatio);
                } else {
                    setArtDimention(maxArtDimention);
                }
            }
        }
        setZoomPercentage(value as number);
    };
    */
    const calculateAspectRatioFit = (srcWidth: number, srcHeight: number) : Dimension => {

        const { width: maxWidth, height: maxHeight } = canvasDimention;

        // Check if image dimention is smaller than max dimention
        // if(srcWidth <= maxWidth && srcHeight <= maxHeight) {
        //     return { width: srcWidth, height: srcHeight };
        // }

        const  ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

        return { width: srcWidth * ratio, height: srcHeight * ratio };
    }

    /*
    const setTop = () => {
        if(alignTop) { 
            setCurrentPosition({ x: 0, y: 0 });
            setAlignTop(false);
        } else {
            setCurrentPosition({ x: 0, y: 0 });
            setAlignTop(true);
        }
    }

    const setCenter = () => {
        if(alignCenter) {
            setCurrentPosition({ x: 0, y: 0 });
            setAlignCenter(false);
        } else {
            setCurrentPosition(getCenterPosition());
            setAlignCenter(true);
        }
    }
    */

    const getCenterPosition = () : Point => {
        if(canvas.current) {

            const positionX = (canvas.current.width / 2)
            const positionY = (canvas.current.height / 2)

            return { x: 0, y: 0 };
        }

        return { x: 0, y: 0 };
    }

    const openPreviewDialog = () => {
        setIsOpenPreviewDialog(true);
        if(canvas.current) {
            setImageDataUrl(canvas.current.toDataURL());
        }
    }

    const closePreviewDialog = () => {
        setIsOpenPreviewDialog(false);
    }
    
    // const onSubmit = (event: any) => {
    //     event.preventDefault();

    //     // console.log("Going to submit form...");

    //     if(canvas.current) {
    //         handleSubmit(canvas.current.toDataURL());
    //     }
    // }

    const canvasStyle = {
        border: "3px dashed #AAA", 
        margin: "auto", 
        display: "block", 
        maxWidth: "100%",
        width: `${canvasDimention.width}px`, 
        height: `${canvasDimention.height}px`,
        textAlign: "center",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    } as CSSProperties;

    if(backgroundColor) {
        canvasStyle.backgroundColor = backgroundColor;
    } else {
        canvasStyle.backgroundImage = 'url("'+TransparentImage+'")';
    }

    const canvasImageStyle = {
        width: `${artDimention.width}px`, 
        height: `${artDimention.height}px`, 
        maxWidth: "100%", 
        maxHeight: "100%",
    } as CSSProperties;

    const revertToOriginal = () => {
        setArtDimention(calculateAspectRatioFit(imageDimention.width, imageDimention.height));
        setCurrentPosition({x: 0, y: 0});
        // setAlignTop(false);
        // setAlignCenter(false);
        setIsOpenPreviewDialog(false);
        setZoomPercentage(0);

        handleRevertToOriginal();
    }

    const changeImage = () => {
        if (onChangeImage) {
            onChangeImage();
            setIsArtImageLoaded(false);
        }
    }

    const onExit = () => {
        // setIsArtImageLoaded(false);
        // revertToOriginal();
        console.log('onExit 0')
        handleExit();
    }
    
    return <div style={{width: "45%"}}>
        <div style={{position: "relative"}}>
            { !isArtImageLoaded ? <Loader /> : (
                <div>
                    {/* <canvas 
                        ref={canvas} 
                        width={canvasDimention.width} 
                        height={canvasDimention.height} 
                        style={{border: "3px dashed #AAA", margin: "auto", display: "block", maxWidth: "100%"}}
                    >
                    </canvas> */}

                    <div style={canvasStyle}>
                        <img style={canvasImageStyle} src={imgSrc} />
                    </div>

                    <button type="button" style={{position: "absolute", top: "10px", right: "10px", padding: "10px"}} onClick={openPreviewDialog}>Zoom</button>

                    <div>Max {maxAxisPrint.x}” ---&gt;</div>
                    <div style={{
                        transform: "rotate(270deg)", 
                        width: "105px", 
                        position: "absolute",
                        left: "-65px", 
                        bottom: "65px",
                }}>Max {maxAxisPrint.y}” ---&gt;</div>
            </div>
            )}
        </div>
    {/* <BottomInstruction style={{width: "100%", padding: "10px 0px", display: "flex", justifyContent: "space-around", color: "#0a7c80", fontWeight: "bold", marginTop: "15px"}}>
        <div>Adjust Scale</div>
        <CustomSlider min={-100} max={100} onChange={_zoomImage} value={zoomPercentage} />
        
        <div>{zoomPercentage}%</div>
    </BottomInstruction> */}

    { artworkType == ArtworkType.classic ? (
        <div>
            {/* <div style={{display: "flex", justifyContent: "space-between"}}>
                <SelectButton label="Align Top" onClick={setTop} checked={alignTop} />
                <SelectButton label="Align Center" onClick={setCenter} checked={alignCenter} />
            </div> 
            <p>Center and align artwork to the top of bounding box. Expand to view resolution.</p>
            */}
        </div>
    ) : (
        <p>Artwork must fill the entire bounding box. Expand to view resolution.</p>
    )}

    { isEdit &&
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <MainButton type="button" style={{width: "200px"}} onClick={changeImage}>Change Image</MainButton>
        </div>
    }

    { !isEdit ?
        <div style={{display: "flex", justifyContent: "space-between"}}>
            <MainButton type="button" style={{width: "200px"}} onClick={revertToOriginal}>Revert to original</MainButton>
        </div>
    : 
        <div style={{position: "absolute", right: "60px", bottom: "20px", display: "flex" }}>
            { !isHideUpdateButton &&
                <MainButton type="submit" textAlign="right" style={{width: "140px", marginRight: "10px" }}>
                    Update
                </MainButton>
            }
            <MainButton type="button" style={{width: "200px"}} onClick={onExit}>
                Back
            </MainButton>
        </div>
    }

    <ArtLargePrview backgroundColor={backgroundColor} imageDataUrl={imgSrc} isOpen={isOpenPreviewDialog} handleClose={closePreviewDialog}/>

  </div>
}
