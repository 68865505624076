import * as React from 'react';
import { Button, Typography, Popover } from '@material-ui/core';
import InfoOutlinedfrom from '@material-ui/icons/InfoOutlined';

interface CustomPopoverInterface {
    text: string;
}

export default function CustomPopover({ text }: CustomPopoverInterface ) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Button onClick={handleClick}><InfoOutlinedfrom /></Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography style={{padding: '10px'}}>{text}</Typography>
      </Popover>
    </>
  );
}