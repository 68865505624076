import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";

import { selectUserJWTToken, selectUserAccount } from "../../redux/user/user.selector";
import { COMMON_DATE_RANGE, Loader, TableColumnProp } from "../Common";

import { AdminTable } from "../Table";
import { stausReportTable } from "../../utils/fetchCommForTable";
import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabArea,
  TabSubLink,
  TabSubTitle,
} from "../SharedStyle/styled";

export const TABLE_COLUMNS: TableColumnProp[] = [
  {
    Header: "Database Row Id",
    accessor: "dbRowId",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Date",
    accessor: "order_created_at",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Order #",
    accessor: "order",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Title",
    accessor: "product_title",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Artist",
    accessor: "artist",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Product Type",
    accessor: "product_type",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Error",
    accessor: "error_message",
    disableFilters: true,
    disableSortBy: true,
  },
];

interface AdminReportingProp { 
  token: String 
};

const AdminCommissionsErrors = (props: AdminReportingProp) => {
  const [tableData, setTableData] = useState([]);
  const [isCommLoading, setIsCommLoading] = useState(true);
  const { token } = props;

  useEffect(
    () => {
      _getStatusReport();
    },
    //eslint-disable-next-line
    []
  );

  const _getStatusReport = async () => {

    const reqBody = {
      method: "GET",
      data: {},
      url: "/api/admin/commissions/errors"
    };

    try {
      const tableData = await stausReportTable(reqBody, token);
      console.log("tableData");
      console.log(tableData);

      setIsCommLoading(false);
      setTableData(tableData);

      return tableData;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
      return;
    }
  };

  
  return (
    <SubmissionContainer>
      <TabHeader>
        <TabSubLink to={`/admin/commissions`}>
          <TabSubTitle>Commissions by Order</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/commissions/by-artist`}>
          <TabSubTitle>Commission by Artist</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/commissions/payouts`}>
          <TabSubTitle>Payouts</TabSubTitle>
        </TabSubLink>
        <TabTitle>Commissions Errors</TabTitle>
      </TabHeader>
      <TabArea>
        {isCommLoading ? <Loader/> : tableData.length > 0 ? (
          <AdminTable
            columns={TABLE_COLUMNS}
            setTableData={setTableData}
            data={tableData}
            token={token}
          />
        ) : (
          <h2> No Records Found </h2>
        )}
      </TabArea>
    </SubmissionContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(AdminCommissionsErrors);
