import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";

import { SITE_BACKEND_API_URL } from "../../server";

import { ArtistTable } from "../Table";
import DashboardCalender from "../Common/dashboard-calender.component";
import { artistFetchCommForTable } from "../../utils/fetchCommForTable";
import { SelectColumnFilter } from "../../libs/table";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";
import { SubmissionContainer, TabArea } from "../SharedStyle/styled";
import { MainButton } from "../Button";

import NoCommissionsImage from "../../assets/no-commissions.png";
import {
  NoRecordImgWrapper,
  NoRecordImg,
} from "../ArtistHome/artist-home.styles";
import { dateToLocaleDateString } from "../../utils/cleanData";

const TABLE_COLUMNS = [
  {
    Header: "Date",
    accessor: "order_created_at",
    disableFilters: true,
  },
  {
    Header: "Title",
    accessor: "product_title",
    filter: "fuzzyText",
  },
  {
    Header: "Product Type",
    accessor: "product_type",
    Filter: SelectColumnFilter,
    filter: "equals",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
    disableFilters: true,
  },
  {
    Header: "Commissions Amount",
    accessor: "commissions_amount",
    disableFilters: true,
  },
  {
    Header: "Donation Amount",
    accessor: "tip_amount",
    disableFilters: true,
  },
  // {
  //   Header: "Paid or Unpaid",
  //   accessor: "is_commissions_paid",
  //   Filter: SelectColumnFilter,
  //   filter: "includes",
  // },
];

class ArtistCommissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      errorMsg: "",
      loading: true,
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  componentDidMount() {

    // Set first date on the month
    const { startDate } = this.state;
    startDate.setDate(1);
    
    this.setState({ startDate });

    this.getAllCommissions();
  }

  setTableData = (data) => {
    this.setState({ tableData: data });
  };

  getAllCommissions = async () => {
    try {
      const { token } = this.props;
      const { startDate, endDate } = this.state;

      const reqBody = {
//        url: "/api/artist/commissions",
        url: SITE_BACKEND_API_URL+"/artist/commissions/list",
        method: "POST",
        data: {
          startDate: dateToLocaleDateString(startDate), 
          // .toLocaleDateString("en-CA"),
          endDate: dateToLocaleDateString(endDate), 
          // .toLocaleDateString("en-CA"),
        },
      };

      const tableData = await artistFetchCommForTable(reqBody, token);

      this.setState({ tableData, loading: false });
    } catch (error) {
      console.log(error);
      this.setState({
        errorMsg:
          "We could not find any records. Let us know if its a mistake.",
      });
    }
  };

  handleDateFilter = async ({ startDate, endDate }) => {
    const { token } = this.props;

    // let formatedStartDate = startDate;
    // let formatedEndDate = endDate;

    // This is solution for my local system to working correct date format
    // if (startDate.includes('/')) {

    //   const [month1, date1, year1] = startDate.split('/');
    //   formatedStartDate = `${year1}-${month1}-${date1}`;

    //   const [month2, date2, year2] = endDate.split('/');
    //   formatedEndDate = `${year2}-${month2}-${date2}`;
    // }

    const reqBody = {
//      url: "/api/artist/commissions/dates",
      url: SITE_BACKEND_API_URL+"/artist/commissions/list",
      method: "POST",
      data: {
        startDate,
        endDate,
      },
    };
    try {
      const tableData = await artistFetchCommForTable(reqBody, token);

      this.setState({
        tableData,
        startDate,
        endDate,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        errorMsg:
          "We could not find any records. Let us know if its a mistake.",
      });
    }
  };

  render() {
    const { tableData, errorMsg, loading, startDate, endDate } = this.state;
    return (
      <SubmissionContainer>
        <h1 style={{color: 'rgb(108, 182, 187)'}}>Most Recent Commissions</h1>
        <DashboardCalender
            handleDateFilter={this.handleDateFilter}
            globalStartDate={startDate}
            globalEndDate={endDate}
          />
        <TabArea>
          {tableData.length > 1 ? (
            <ArtistTable columns={TABLE_COLUMNS} data={tableData} />
          ) : errorMsg ? (
            <h2>{errorMsg}</h2>
          ) : (
            <>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "50px",
                  }}
                >
                  <img
                    className="rotating"
                    src={teefuryBirdLogo}
                    alt="tee bird"
                    style={{ height: "100px", width: "100px" }}
                  />
                </div>
              ) : (
                <NoRecordImgWrapper>
                  <NoRecordImg src={NoCommissionsImage}/>
                </NoRecordImgWrapper>
              )}
            </>
          )}
        </TabArea>
      </SubmissionContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(ArtistCommissions);
