import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import matchSorter from "match-sorter";
import { maxWidth } from "@material-ui/system";

// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  return getFilterOptions(filterValue, setFilter, options);
};

export const SelectColumnFilterGroup = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = ["Gallery", "ODAD", "Weekly"]; // To-Do read from database
  return getFilterOptions(filterValue, setFilter, options);
};

export const SelectColumnFilterStatus = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = ['ORDERED', 'SHIPPED', 'CANCELED', 'PARTIALLY_REFUNDED', 'REFUNDED']; // To-Do read from database
  return getFilterOptions(filterValue, setFilter, options);
};

export const SelectColumnFilterProductType = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {

  // To-Do read from database
  const options = ["Blanket","Canvas","Face Mask","Gallery","Grab Bag","Leggings","Mug","none","ODAD-Canvas","ODAD-Face Mask","ODAD-Mug","ODAD-Poster","ODAD-Sticker","ODAD-Sweatshirt","ODAD-Tank","ODAD-Tee","Pin","Poster","Sticker","Sweatshirt","Tank","Tee","Towel","Weekly-Face Mask","Weekly-Mug","Weekly-Poster","Weekly-Sticker","Weekly-Sweatshirt","Weekly-Tank","Weekly-Tee"];
  return getFilterOptions(filterValue, setFilter, options);
};

export const SelectColumnFilterPaymentType = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = ["Paid", "Unpaid"]; // To-Do read from database
  return getFilterOptions(filterValue, setFilter, options);
};

const getFilterOptions = (filterValue, setFilter, options)  => {
  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={{ maxWidth: "100px" }}
    >
      <option value="">All</option>
      {options.map((option, i) =>
        option ? (
          <option key={i} value={option}>
            {option}
          </option>
        ) : (
          ""
        )
      )}
    </select>
  );
}

// Define a default UI for filtering
export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  // const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search records...`}
      style={{ maxWidth: "100px" }}
    />
  );
};

// Selection
export const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    );
  }
);

// Fuzzy find by a library
export const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

export const startWithFn = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue !== undefined
      ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
      : true;
  });
};
