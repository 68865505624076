import React, { useState, useEffect } from "react";

import { FilterHeader } from "../SharedStyle/art-submissions.styles";
import { BtnArtSubmit } from "../Button";
import { MenuItem, Select } from "@material-ui/core";
import { DatePicker } from "../FormInput/date-picker.component";
import { dateToLocaleDateString } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { ProductCreationTypes } from "../../constants";
import { buttonAndTextFontStyle, filterDropdownStyle } from "./common-styles";

const TopFilterTitle = (props: { title: String }) => {
    const { title } = props;
    return <div style={{fontWeight: "bold", fontSize: "18px", color: "#AAA", padding: "10px 0"}}><span style={{color: "#000"}}>{title}</span></div>
}
interface AdminArtAutomationFormInterface {
    selectedSubmissionIds: number[];
    token: string;
    onUpdate: Function;
    isSelectedAllSubmission: Boolean;
}

export const AdminArtAutomationForm = ({selectedSubmissionIds, token, onUpdate, isSelectedAllSubmission}: AdminArtAutomationFormInterface) => {

    const [creationType, setCreationType] = useState<string | null>(null);
    const [scheduleDate, setScheduleDate] = useState(null);
    const [odadPosition, setODADPosition] = useState<string | null>(null);

    const saveAutomation = async () => {

        try {
    
          if(!creationType) {
            Swal.fire({
              icon: "error",
              title: "Creation type can't be empty!",
            });
    
            return false;
          }
    
          // Validate Creation type
          if (['ODAD', 'WEEKLY'].includes(creationType) && !scheduleDate) {
            Swal.fire({
              icon: "error",
              title: "Schedule Date can't be empty for ODAD and Weekly creation type!",
            });
    
            return false;
          }
    
          if (creationType === 'ODAD' && !odadPosition) {
            Swal.fire({
              icon: "error",
              title: "Please select ODAD Position!",
            });
            return false;
          }
    
          await axios({
            url: `/api/admin/submissions/save-automation`,
            method: "POST",
            data: {
              selectedSubmissionIds,
              isSelectedAllSubmission,
              creationType,
              scheduleDate: scheduleDate ? dateToLocaleDateString(scheduleDate) : null,
              odadPosition
            },
            headers: { Authorization: `JWT ${token}` },
          });
    
          Swal.fire({
            icon: "success",
            title: "Arts updated successfully.",
          });
    
          // Reset
          setCreationType(null);
          setScheduleDate(null);
          setODADPosition(null);

          onUpdate();
    
        } catch (error) {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Something went wrong. Please try again.",
          });
        }
    }

    return (
        <FilterHeader>
          <div style={{display: "flex", margin: "30px 0"}}>
            <div>
              <TopFilterTitle title="Creation Type"/>
              <Select
                name="creationType"
                disableUnderline
                displayEmpty
                placeholder="Creation Type"
                onChange={(e) => setCreationType(e.target.value as string) }
                style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
              >
                {ProductCreationTypes.map((cType) => (
                    <MenuItem key={cType} value={cType} style={buttonAndTextFontStyle}>{cType}</MenuItem>
                ))}
              </Select>
            </div>

            { creationType && ['ODAD', 'WEEKLY'].includes(creationType) &&
              <div style={{marginLeft: "40px"}}>
                <TopFilterTitle title="Schedule Date"/>
                <DatePicker
                  value={null}
                  onChange={(d: any) => { setScheduleDate(d) }}
                  fullForm={false}
                />
              </div>
            }

            { creationType && creationType === 'ODAD' &&
              <div style={{marginLeft: "40px"}}>
                <TopFilterTitle title="ODAD Position"/>
                <Select
                  name="odadPosition"
                  disableUnderline
                  displayEmpty
                  placeholder="Creation Type"
                  onChange={(e) => setODADPosition(e.target.value as string) }
                  style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
                >
                  {['D1', 'D2'].map((cType) => (
                      <MenuItem key={cType} value={cType} style={buttonAndTextFontStyle}>{cType}</MenuItem>
                  ))}
                </Select>
              </div>
            }
            <BtnArtSubmit
              type="submit"
              textAlign="right"
              style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
              onClick={saveAutomation}
            >
              Save
            </BtnArtSubmit>
            
          </div>
        </FilterHeader>
    )
}