import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";

import { ArtworkType } from "../../constants";
import { SITE_BACKEND_API_URL } from "../../server";

import { selectArtistProfile } from "../../redux/artist/artist.selector";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import {
  selectSubmissionsErrorAlert,
  selectSubmissionsSuccessAlert,
} from "../../redux/submissions/submissions.selector";

import './../Common/suggested-tags-text-styles.css';

import { setConfigStart } from "../../redux/settings/settings.action";

import {
  submissionCreateStart,
  submissionErrorAlertClear,
  submissionSuccessAlertClear,
} from "../../redux/submissions/submissions.action";

import { ReactComponent as Upload } from "../../assets/upload.svg";
import { ReactComponent as Loading } from "../../assets/loading.svg";
import { InputArtPreview } from "../Button";
import { MainButton } from "../Button"
import { ColorsListInterface, Point, Dimension, ArtFormData } from '../AdminArtSubmissions/admin-art-submission-types';

import { FileRequirements } from "./file-requirements.component";
import { DesignerTips } from "./designer-tips.component";
import { ArtworkTypeSelector } from "./artwork-type-selector.component";
import { ArtworkForm } from "./artwork-form.component";

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabSubTitle,
  TabSubLink,
  TabArea,
  SubTitle,
  FormArtistSubmit,
  SubmitCard,
  FieldSection,
  ArtPreview,
  IconContainer,
  IconTopSubtitle,
  PreviewImageWrapper,
  PreviewImage,
} from "./artist-submit-art.styles";
import successIcon from "../../assets/success-icon.png";

import axios from "axios";

const COLORS = axios.get(SITE_BACKEND_API_URL+ '/common/api/getcolors');

interface ArtFile {
  // artFileName: string;
  artPreviewImg: string;
  artTrimmedImgURL: string;
  artDimension: Dimension,
}

interface ArtistSubmitArtState {
  artFiles: ArtFile[],
  artFormData: ArtFormData[],
  artworkType: string;
  artHasSubmitted: boolean;
  isDisableSubmit: boolean;
  colorsList: ColorsListInterface[];
  isDialogOpen: boolean;
  isAllOverExDialogOpen: boolean;
  openedDialogArtworkType: string;
  stepNumber: 1 | 2 | 3;
  currentArtFileIndex: number;
  isFinalSubmit: Boolean;
}

interface ArtistSubmitArtProps {
  submissionSuccessMsg: string;
  submissionSuccessAlertClear: Function;
  submissionErrorMsg: string;
  submissionErrorAlertClear: Function;
  setConfigStartAction: Function;
  submissionCreateStart: Function;
  artistProfile: {
    artistName: string;
  }
  token: string;
  byAdmin?: boolean;
  artistName?: string;
}

class ArtistSubmitArt extends Component<ArtistSubmitArtProps, ArtistSubmitArtState> {

  private artworkSubmissionForm: React.RefObject<HTMLFormElement>;
  public state: Readonly<ArtistSubmitArtState>;

  constructor(props: any) {
    super(props);
    
    this.artworkSubmissionForm = React.createRef();

    this.state = {
      artworkType: ArtworkType.classic,
      artFiles: [],
      artFormData: [],
      artHasSubmitted: false,
      isDisableSubmit: false,
      colorsList: [],
      isDialogOpen: false,
      isAllOverExDialogOpen: false,
      openedDialogArtworkType: ArtworkType.classic,
      stepNumber: 1,
      currentArtFileIndex: 0,
      isFinalSubmit: false,
    };

  }

  static getDerivedStateFromProps(props: ArtistSubmitArtProps) {
    const {
      artistProfile: { artistName },
    } = props;

    return {
      artistName,
    };
  }

  componentDidMount() {
      COLORS.then((res) => {
        this.setState({colorsList : res.data });
      });

      const { setConfigStartAction } = this.props;

      setConfigStartAction();
  }

  componentDidUpdate() {
    this._submissionMessages();
  }
  
  handleSubmit = (event: any) => {
    
    event.preventDefault();
    // console.log("handleSubmit");
    // this.setState({ canvasImageData });

    // Todo: form validation is not working here

    this._submitArtwork();


    this.setState({
      isDisableSubmit: true,
    });
  };

  onChangeArtFile = async (event: any) => {

    const { artworkType } = this.state;

    console.log('onChangeArtFile --> 1');

    console.log(event.target.files);
    


    let [firstFile] = event.target.files;
    if (!firstFile) return;

    const { files } = event.target;

    for(let i = 0; i < files.length; i += 1) {
      const file = files[i];
    
      console.log(file.name);
      
      const fileExtention = file.name.split('.').pop();
      const expectedFileExtentions = (artworkType === ArtworkType.classic) ? ['png'] : ['jpg','jpeg'];
  //    const expectedFileExtentionNames = (artworkType === ArtworkType.classic) ? ['psd, ai OR png'] : ['ai, jpeg, png OR psd'];
      
      if(!expectedFileExtentions.includes(fileExtention.toLowerCase())) {
          Swal.fire({
              icon: "error",
              text:
      //          "Art file type must be "+expectedFileExtentionNames+".",
                  "Does not meet requirements. See below for file types accepted.",
              showConfirmButton: true,
          });
          
          event.target.value = null;

        return;
      }
      
      // Make sure `file.size` does not 0 size
      if (file.size < 5) {
        Swal.fire({
          icon: "error",
          text: "Sorry your file is too small! Please make sure your image size is greater than 0MB.",
          showConfirmButton: true,
        });
        
        event.target.value = null;

        return;
      }
      
  //    console.log(file.size);
      
      // Make sure `file.size` does not exceed 15MB
      if (file.size > 15000000) {
        Swal.fire({
          icon: "error",
          text:
            "Sorry your file is too large! Please limit your image size to less than 15MB.",
          showConfirmButton: true,
        });
        
        // event.target.value = null;

        return;
      }

      // Check the image size
      const artFile = {
        artPreviewImg: '',
        artTrimmedImgURL: '',
        artDimension: {},
      } as ArtFile;

      if(artworkType == ArtworkType.classic) {
        // Send to trim image
        this._trimImage(file.size, i);
      }

      const artPreviewDetails = await this._generatePreviewImg(file) as { data: string, dimension: Dimension };
      const { width, height } = artPreviewDetails.dimension;

      // Validate dimention as 60x100 
      if(artworkType == ArtworkType.allOver) {

          if(width/height != 0.6) {  // ==> 60 / 100 = 0.6

            Swal.fire({
              icon: "error",
              text: "A JPEG file of 60 inches by 100 inches is required for submission.",
              showConfirmButton: true,
            });

            // event.target.value = null;

            return;
          }

          artFile.artTrimmedImgURL = artPreviewDetails.data;

          // this.setState({ artTrimmedImgURL: artPreviewDetails.data });  
      }

      artFile.artPreviewImg = artPreviewDetails.data;

      // const canvasHeight = (height / width) * fixedCanvasWidth;
      // this.setState({ artPreviewImg: artPreviewDetails.data, isDisableSubmit: false });

      const { artFiles } = this.state;
      artFiles.push(artFile);

      this.setState({ artFiles });
    }
  };

  _submitArtwork = () => {

    if(!this.artworkSubmissionForm.current) {
      return;
    }

    const { artFormData, artFiles, currentArtFileIndex } = this.state;

    // for(let i = 0; i < artFormData.length; i++) {
    const { elements } = this.artworkSubmissionForm.current;

    const { title, description, mainInspiration, mainColor, additionalColors, suggestedTags, primaryTag, } = artFormData[currentArtFileIndex];
    const { artworkType } = this.state;
    const { artDimension, artTrimmedImgURL } = artFiles[currentArtFileIndex];

    if (!title) {
      Swal.fire({
        icon: "error",
        text: 'Please enter Title!',
      });
      return true;
    }

    if (!description) {
      Swal.fire({
        icon: "error",
        text: 'Please enter Description!',
      });
      return true;
    }

    if (!mainInspiration) {
      Swal.fire({
        icon: "error",
        text: 'Please enter Main Inspiration!',
      });
      return true;
    }

    if (artworkType == ArtworkType.classic && (!mainColor || mainColor === 0)) {
      Swal.fire({
        icon: "error",
        text: 'Please Choose Main Color!',
      });
      return true;
    }

    if (!artTrimmedImgURL) {
      Swal.fire({
        icon: "error",
        text: 'Please wait until art is being processed!',
      });
      return true;
    }

    const inputsDOM = Array.from(elements) as HTMLInputElement[];

    const formData = new FormData();

    inputsDOM.forEach((el) => {
      console.log(el);

      const { files, name, value } = el ;
      if (files) {
        console.log("Files found: ");
        console.log(files);
        formData.append(name, files[currentArtFileIndex]);
      } else if (value) {
        console.log(name);
        formData.append(name, value);
      }
    });

    formData.append('primaryTag', primaryTag);
    formData.append('suggestedTags', suggestedTags.join(','));
    formData.append('previewArtInfo', JSON.stringify({ artDimension }));
    formData.append('mainColor', mainColor.toString());
    formData.append('additionalColors', JSON.stringify(additionalColors.map(c => parseInt(c.toString())))); // Save list of integers

    if (artworkType == ArtworkType.classic) {
      formData.append('artTrimmedImgURL', artTrimmedImgURL);
    }

    if(this.props.byAdmin && this.props.artistName) {
      formData.append('artistName', this.props.artistName);
    }

    // console.log(formData);
    // return false;

    const { submissionCreateStart } = this.props;
    submissionCreateStart(formData);

    if (currentArtFileIndex == artFiles.length - 1) {
      Swal.fire({
        icon: "warning",
        text:
          "Please be patient!!! Allow 1 - 3 minutes for upload. Don't close this window these are large files.",
        showConfirmButton: false,
      });

      this.setState({ isFinalSubmit: true });
    } else {
      this._selectNextArt();

      Swal.fire({
        icon: "warning",
        text:
          "Started upload art file "+(currentArtFileIndex + 1) +". Please continue submiting next art.",
        showConfirmButton: true,
      });
    }

    // this.setState({ artHasSubmitted: true });
  };

  _trimImage = async (fileSize: number, fileIndex: number) => {

    console.log("fileSize: ");
    console.log(fileSize);

    if(this.artworkSubmissionForm.current) {
      const { elements } = this.artworkSubmissionForm.current;

      const inputsDOM = Array.from(elements) as HTMLInputElement[];
      const formData = new FormData();

      inputsDOM.forEach((el) => {
        const { files, name, value } = el ;
        if (files) {
          formData.append(name, files[fileIndex]);
        }
      });

      const { token } = this.props;

      try {
        const {
          data: { path },
        } = await axios.post("/api/artist/trim-artwork", formData, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        });

        console.log('Path: '+path);

        const { artFiles } = this.state;
        artFiles[fileIndex].artTrimmedImgURL = path;

        this.setState({ artFiles });  
          
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: error.response.data.error ?? "Something went wrong. Please try again.",
        });

        this._resetForm();
      }
    }
  }

  _generatePreviewImg = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const loadImg = () => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);

        const image = new Image();
        image.src = reader.result as string;

        image.onload = function() {
            // access image size here 
            const dimension = { width: image.width, height: image.height } as Dimension;

            resolve({ data: reader.result, dimension });
        };
      };

      const loadError = (event: any) => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        reject(event);
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", loadImg);
      reader.addEventListener("error", loadError);
    });
  };

  _resetForm = () => {
    const {
      artistProfile: { artistName },
    } = this.props;

    if(this.artworkSubmissionForm.current) {
      this.artworkSubmissionForm.current.reset();
    }

    this.setState({
      artFiles: [],
      artFormData: [],
      artworkType: ArtworkType.classic,
      artHasSubmitted: false,
      isDisableSubmit: false,
      stepNumber: 1,
      isFinalSubmit: false,
    });
  };

  // handleMainColorChange() {

  // }

  _submissionMessages() {
    // Check Redux for messages

    const {
      submissionSuccessMsg,
      submissionSuccessAlertClear,
      submissionErrorMsg,
      submissionErrorAlertClear,
    } = this.props;

    if (!!submissionSuccessMsg) {
      // Swal.fire({
      //   icon: "success",
      //   text: submissionSuccessMsg,
      //   showConfirmButton: false,
      // });

      Swal.fire({
        showConfirmButton: false,
        html: `<h1>${submissionSuccessMsg}</h1>
        <div>
          <img src="${successIcon}" style="height: 80px; width: 80px"/>
        </div>`
      });

      setTimeout(() => {
        submissionSuccessAlertClear();

        if (this.state.isFinalSubmit) {
          this._resetForm();
        }
      }, 2000);
    } else if (!!submissionErrorMsg) {
      Swal.fire({
        icon: "error",
        text: submissionErrorMsg,
        showConfirmButton: false,
      });

      setTimeout(() => {
        submissionErrorAlertClear();
        this.setState({ artHasSubmitted: false });
      }, 2000);
    }
  }

  _nextStep = () => {
    this.setState({stepNumber: 3});
  }

  _saveArtFormData = (index: number, formData: ArtFormData) => {

    const { artFormData } = this.state;
    artFormData[index] = formData;

    this.setState({ artFormData })

    this._submitArtwork();
  }

  _selectNextArt = () => {

    const { currentArtFileIndex, artFiles } = this.state;

    if (currentArtFileIndex < artFiles.length - 1) {
      this.setState({ currentArtFileIndex: currentArtFileIndex + 1 })
    }
  }

  _selectPreviousArt = () => {
    const { currentArtFileIndex } = this.state;

    if (currentArtFileIndex > 0) {
      this.setState({ currentArtFileIndex: currentArtFileIndex - 1 })
    }
  }

  _handleArtTypeChange = (type: string) => {
    console.log('_handleArtTypeChange');
    console.log(type);
    this.setState({ artworkType: type })
  }

  render() {
    const {
      artFiles,
      artFormData,
      artworkType,
      colorsList,
      stepNumber,
      currentArtFileIndex,
    } = this.state;

    return (
      <SubmissionContainer>
        <TabHeader>
          <TabTitle>Submit Artwork</TabTitle>

          { !this.props.byAdmin &&
          <TabSubLink to={`/artist/submissions/new`}>
            <TabSubTitle>Submissions</TabSubTitle>
          </TabSubLink>
        }

        </TabHeader>
        <TabArea style={{position: "relative"}}>
          <SubTitle>UPLOAD YOUR ARTWORK</SubTitle>
          <FormArtistSubmit
            onSubmit={this.handleSubmit}
            ref={this.artworkSubmissionForm}
          >
            <input type="hidden" name="artworkType" value={artworkType}/>
            { stepNumber < 3 && <ArtworkTypeSelector onChange={this._handleArtTypeChange} /> }
            <SubmitCard>
            <FieldSection>
              
              <div style={{display: (stepNumber < 2) ? 'block': 'none'}}>

                <p style={{ 
                  maxWidth: "80%", 
                  margin: 'auto', 
                  textAlign: 'center', 
                  fontWeight: 'bold', 
                  fontSize: "20px", 
                  color: '#555' 
                }}>
                  <a href="https://teefury.com/blogs/artist-resources" target="_blank" style={{ color: 'inherit' }}>
                    Need help uploading
                  </a>
                </p>

                <div style={{ display: "flex" }}>
                  { artFiles.map((artFile) => ( 
                    <PreviewImageWrapper key={artFile.artPreviewImg} style={{display: artFile.artPreviewImg ? 'block' : 'none', textAlign: "center"}}>
                      <PreviewImage style={{maxHeight: "250px"}}
                        src={artFile.artPreviewImg}
                        alt="Art Preview"
                      />
                    </PreviewImageWrapper>
                  ))}
                </div>
                
                <label htmlFor="preview-art" style={{display: artFiles.length > 0 ? 'none' : 'block'}}>
                  <InputArtPreview
                    id="preview-art"
                    type="file"
                    name="artFile"
                    onChange={this.onChangeArtFile}
                    textAlign="center"
                    required
                    multiple
                    accept={(artworkType == ArtworkType.classic) ? 'image/png' : 'image/jpeg'}
                  >
                      <ArtPreview>
                        <IconContainer>
                          <Upload />
                        </IconContainer>
                        <IconTopSubtitle>Click to upload images</IconTopSubtitle>
                      </ArtPreview>
                  </InputArtPreview>
                  </label>

                  {artFiles.length > 0 ? (
                    stepNumber == 3 ? (
                      null
                    ) : (
                      <MainButton type="button" textAlign="center" style={{width: "140px", marginTop: 0 }} onClick={this._nextStep}>Continue</MainButton>
                    )
                  ) : (
                    <MainButton type="button" textAlign="center" style={{width: "140px", marginTop: 0, backgroundColor: "lightgray", cursor: "default" }}>Continue</MainButton>
                  )}

                  <FileRequirements artworkType={artworkType} />
                  <DesignerTips artworkType={artworkType} token={this.props.token} />
                </div>
                
                <ArtworkForm 
                  artistName={this.props.artistName ?? this.props.artistProfile.artistName} 
                  artworkType={artworkType} 
                  colorsList={colorsList} 
                  stepNumber={stepNumber}
                  onResetForm={this._resetForm}
                  artTrimmedImgURL={artFiles.length > 0 ? artFiles[currentArtFileIndex].artTrimmedImgURL: ''}
                  hasNextArt={currentArtFileIndex < artFiles.length - 1 }
                  fileIndex={currentArtFileIndex}
                  selectPreviousArt={this._selectPreviousArt}
                  selectNextArt={this._selectNextArt}
                  resetForm={this._resetForm}
                  saveArtFormData={this._saveArtFormData}
                  token={this.props.token}
                  // artFormData={artFormData.length > 0 && artFormData[currentArtFileIndex] ? artFormData[currentArtFileIndex] : null}
                /> 
              </FieldSection>
            </SubmitCard>

            {artFiles.length > 0 ? (
              stepNumber == 3 ? (
                null
              ) : (
                currentArtFileIndex < artFiles.length - 1 ?
                  <MainButton type="button" textAlign="right" style={{width: "140px" }} onClick={this._selectNextArt}>Next</MainButton>
                : 
                  <MainButton type="button" textAlign="right" style={{width: "140px" }} onClick={this._nextStep}>Continue</MainButton>
              )
            ) : (
              <MainButton type="button" textAlign="right" style={{width: "140px", backgroundColor: "lightgray", cursor: "default" }}>Continue</MainButton>
            )}
          </FormArtistSubmit>
        </TabArea>
      </SubmissionContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  artistProfile: selectArtistProfile,
  submissionErrorMsg: selectSubmissionsErrorAlert,
  submissionSuccessMsg: selectSubmissionsSuccessAlert,
  token: selectUserJWTToken,
});

const mapDispatchToProps = (dispatch: any) => ({
  submissionCreateStart: (formData: any) =>
    dispatch(submissionCreateStart({ formData })),
  submissionErrorAlertClear: () => dispatch(submissionErrorAlertClear()),
  submissionSuccessAlertClear: () => dispatch(submissionSuccessAlertClear()),
  setConfigStartAction: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistSubmitArt);
