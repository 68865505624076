import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import axios from "axios";

import TrendingNow from "./DashboardSettings/trending-now.component";
// import ComingSoon from "./DashboardSettings/coming-soon.component";
import ComingSoonHalloween from "./DashboardSettings/coming-soon-halloween.component";
import FanFavorites from "./DashboardSettings/fan-favorites.component";

import type { IFanFavorite, IComingSoon, ITrend } from './DashboardSettings/types';

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabArea,
  TabSubLink,
  TabSubTitle,
} from "../SharedStyle/styled";

import { DashboardWrapper, WhatsSectionWrapper } from "./artist-dashboard.styles";
import { Loader } from "../Common";

const DashboardSettings = (props: any) => {

  const [loading, setLoading] = useState(true);
  const [favorites, setFavorites] = useState<IFanFavorite[]>([]);
  const [trendings, setTrendings] = useState<ITrend[]>([]);
  const [comingSoon, setComingSoon] = useState<IComingSoon[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [haloweenSettings, setHaloweenSettings] = useState<string[]>([]);

  useEffect(() => {
    loadSettings();
  }, [])

  const loadSettings = () => {
    
    setLoading(true);

    axios.get("api/admin/settings/dashboard/list", {
      headers: { Authorization: `JWT ${props.token}` },
    }).then((res) => {
      setTrendings(res.data.trendings);
      setComingSoon(res.data.comingSoon);
      setFavorites(res.data.favorites);
      setCategories(res.data.categories);
      setHaloweenSettings(res.data.haloweenSettings[0]);
      setLoading(false);
    });
  }

  const refresh = () => {
    loadSettings();
  }

  const onLoading = (isLoading = true) => {
    setLoading(isLoading);
  }

  return (
    <SubmissionContainer>
      <TabHeader>
        <TabSubLink to={`/admin/settings`}>
          <TabSubTitle>Admin Settings</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/settings/configuration`}>
          <TabSubTitle>Configuration Settings</TabSubTitle>
        </TabSubLink>
        <TabTitle>Dashboard Settings</TabTitle>
        <TabSubLink to={`/admin/settings/designer-tips`}>
          <TabSubTitle>Designer Tips</TabSubTitle>
        </TabSubLink>
      </TabHeader>
      <TabArea>
        <DashboardWrapper>
          { loading ? <Loader /> : <>
          <WhatsSectionWrapper>
            <TrendingNow trendings={trendings} token={props.token} onRefresh={refresh} onLoading={onLoading}/>
            {/* <ComingSoon comingSoon={comingSoon} categories={categories} token={props.token} onRefresh={refresh} onLoading={onLoading}/> */}
            <ComingSoonHalloween haloweenSettings={haloweenSettings} token={props.token} onRefresh={refresh} onLoading={onLoading} />
          </WhatsSectionWrapper>
          <FanFavorites fanFavorites={favorites} token={props.token} onRefresh={refresh} onLoading={onLoading}/>
          </>
        }
        </DashboardWrapper>
      </TabArea>
    </SubmissionContainer>
  );
};



const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(DashboardSettings);