import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { selectStatusList } from "../../redux/settings/settings.selector";
import { setConfigStart } from "../../redux/settings/settings.action";

import { ArtStatusInterface } from "../AdminArtSubmissions/admin-art-submission-types";
import { MainButton } from "../Button";
import axios from "axios";
import Swal from "sweetalert2";

interface ArtStatusExternalSettingsInterface {
  token: string;
  statusList: ArtStatusInterface[];
  refreshSettings: Function;
} 

const ArtStatusExternalSettings = (props: ArtStatusExternalSettingsInterface) => {
  const { token, statusList, refreshSettings } = props;
  const [ artStatusList, setArtStatusList ] = useState(statusList);
  
  useEffect(() => {

  }, []);

  const onChange = (statusId: number, value: string) => {
    const newStatusList = artStatusList.map(s => { 
      if(s.id == statusId) {
        s.external_status_name = value;
      }
      return s;
    });
    
    setArtStatusList(newStatusList);
  }

  const saveChanges = async () => {
    console.log(artStatusList);

    try {
      await axios.put(`api/admin/settings/art-statuses/update-external`, { updates : { ...artStatusList } }, {
         headers: { Authorization: `JWT ${token}` },
       }
     );

     Swal.fire({
       icon: "success",
       title: "Art status updated successfully.",
     });
     
     refreshSettings();

   } catch (error) {
     console.log(error);
     Swal.fire({
       icon: "error",
       title: "Something went wrong. Please try again.",
     });
   }
  }

  const inputStyles = {
    padding: "7px",
    fontWeight: 600,
    color: "#666"
  }

  const tdStyles = {
    padding: "7px",
  }

  return (
    <div>
      <h1>Art Status</h1>
      <table>
        <tr>
          <td style={{...tdStyles, fontWeight: "bold"}}>Status Name</td>
          <td style={{...tdStyles, fontWeight: "bold"}}>External Status (To Artist)</td>
        </tr>
        {artStatusList.map(status => (
          <tr>
            <td style={tdStyles}>
              <input 
                style={inputStyles} 
                type="text" 
                readOnly={true} 
                value={status.status_name}
                />
            </td>
            <td style={tdStyles}>
              <input 
                style={inputStyles} 
                type="text" 
                value={status.external_status_name} 
                onChange={
                  (e: any) => onChange(status.id, e.target.value)
                }
                />
            </td>
          </tr>
        ))}
      </table>

      <MainButton style={{ width: "125px", height: "52px", marginLeft: "5px", borderRadius: "4px", }} onClick={saveChanges} >
        Save Changes
      </MainButton>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  statusList: selectStatusList,
});

const mapDispatchToProps = (dispatch: any) => ({
  refreshSettings: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtStatusExternalSettings);
