import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectSuggestedTags } from "../../redux/settings/settings.selector";
import { capitalizeSentence } from "../../utils";

interface DisplayTagsProps {
    tags: string;
    isDeletable?: boolean;
    suggestedTags: {
        id: number; 
        tag_name: string;
    }[]; 
    handleOnDelete?: Function;
    handleOnClick?: Function;
}

const DisplayTags = ({tags, isDeletable, suggestedTags, handleOnDelete, handleOnClick}: DisplayTagsProps) => {

    const isKnownTag = (tag: string) => {
        return suggestedTags.find((st => st.tag_name.toLowerCase() == tag.toLowerCase()));
    }

    const onClickTag = (tagName: string) => {
        return () => {
            if (isKnownTag(tagName) && handleOnClick) {
                handleOnClick(tagName)
            }
        }
    }

    const getAdditionalClasses = (tag: string) => {
        let classes = [];

        if(isKnownTag(tag)) {
            classes.push('known');
        }

        if(handleOnClick) {
            classes.push('clickable');
        }

        return classes.join(' ');
    }

    return (
        <div className="ReactTags__selected custom">
            {tags && 
                tags.split(',')
                .filter(t => t)
                .map(t => t.trim())
                .map((t, i) => 
                <span className={`tag-wrapper ReactTags__tag `+ getAdditionalClasses(t)} onClick={onClickTag(t)}>
                    {capitalizeSentence(t)}
                    { isDeletable && handleOnDelete && <button className="ReactTags__remove" type="button" onClick={() => handleOnDelete(i)} >×</button> }
                </span>
            )}
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    suggestedTags: selectSuggestedTags,
});

export default connect(mapStateToProps)(DisplayTags);