import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectUserAccount,
  selectUserJWTToken,
} from "../../redux/user/user.selector";

import { useParams } from "react-router-dom";

import { selectScheduleTags, selectStatusList } from "../../redux/settings/settings.selector";
import { SITE_BACKEND_API_URL, SITE_BACKEND_FULL_URL } from "../../server";

import { FilterHeader } from "../SharedStyle/art-submissions.styles";
import { BtnArtSubmit } from "../Button";
import { MenuItem, Select } from "@material-ui/core";
import { DatePicker } from "../FormInput/date-picker.component";
import { dateToLocaleDateString } from "../../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { ProductCreationTypes } from "../../constants";
import { buttonAndTextFontStyle, filterDropdownStyle } from "./common-styles";
import { AutomationAllowedInStatus } from "../../constants";
import { generateProducts } from "../../utils/generateProducts";

import { 
  SubmissionInterface, 
  ArtStatusInterface,
  ScheduleTagInterface,
  EmailTemplateInterface,
} from "./admin-art-submission-types";
import { B } from "@fullcalendar/core/internal-common";

interface GetEmailTemplateInterface {
  emailTemplatesArr: EmailTemplateInterface[], 
};

const TopFilterTitle = (props: { title: String }) => {
    const { title } = props;
    return <div style={{fontWeight: "bold", fontSize: "18px", color: "#AAA", padding: "10px 0"}}>Update <span style={{color: "#000"}}>{title}</span></div>
}
interface AdminArtAutomationFormInterface {
    token: string;
    scheduleTags: ScheduleTagInterface[];
    artStatusList: ArtStatusInterface[];
    selectedSubmissionIds: number[];
    submissionsArr: SubmissionInterface[];
    onEmailTemplateSelector: Function;
    isSelectedAllSubmission: Boolean;
    onImageGeneration: Function;
}

interface SubmissionUpdateInterface {
  status: string;
  scheduleType: string;
  emailTemplateId: number;
}

const AdminArtHeaderForm = (props: AdminArtAutomationFormInterface) => {

  const {
    token,
    scheduleTags,
    artStatusList,
    selectedSubmissionIds,
    submissionsArr,
    onEmailTemplateSelector,
    isSelectedAllSubmission,
    onImageGeneration,
  } = props;

  const [emailTemplates, setEmailTemplates] = useState<EmailTemplateInterface[]>([]);
  const [submissionUpdate, setSubmissionUpdate] = useState<SubmissionUpdateInterface>({
    status: 'NEW',
    scheduleType: "",
    emailTemplateId: 0
  });

  type Params = { status: string, id: string };
  const params = useParams<Params>();
  
  useEffect(() => {
    axios.get<GetEmailTemplateInterface>(`/api/admin/settings/email-templates`, {
      headers: { Authorization: `JWT ${token}` },
    }).then((res) => {
        setEmailTemplates(res.data.emailTemplatesArr);
    });
    
    setSubmissionUpdate({...submissionUpdate, status: params.status.toUpperCase()});
    
  }, []);

  const updateStatus = (event: any) => {

    type InputData = { 
      name: keyof SubmissionUpdateInterface, 
      value: string | number;
    };

    const { name, value } = event.target as InputData;

    const newSubmissionUpdate = { ...submissionUpdate };

    switch(name) {
      case 'status':
      case 'scheduleType' :
        newSubmissionUpdate[name] = value as string;
        break;
      case 'emailTemplateId' :
        newSubmissionUpdate[name] = value as number;
        break;
    }

    setSubmissionUpdate(newSubmissionUpdate);
  }

  const exportSubmission = async () => {
    const {
        data: { csvPath },
    } = await axios({
        url: SITE_BACKEND_API_URL+"/admin/commissions/exportCSVSubmissions",
        method: "POST",
        data: { selectedSubmissionIds, isSelectedAllSubmission },
        headers: { Authorization: `JWT ${token}` },
    });

    console.log(csvPath);

    window.open(SITE_BACKEND_FULL_URL+"/"+csvPath, '_blank');
  }

  const generateSubmission = async () => {

    // setState({...state, loading: true });
    const isSuccess = await generateProducts(submissionsArr, selectedSubmissionIds, token);
    // setState({...state, loading: false });

    if (isSuccess) {
      setTimeout(() => {
        // _getSubmissions(status);
      }, 1000);
    }
  }

  const updateSubmission = async () => {
    if(submissionUpdate.emailTemplateId) {
      const selectedEmailTemplate = emailTemplates.find(e => e.id == submissionUpdate.emailTemplateId) as EmailTemplateInterface;
      onEmailTemplateSelector(selectedEmailTemplate, submissionUpdate);
    } else {

      try {
        await axios.put(`/api/admin/submissions/update-status`, { ...submissionUpdate, ids: selectedSubmissionIds, isSelectedAllSubmission }, {
          headers: { Authorization: `JWT ${token}` },
          }
        );

        Swal.fire({
          icon: "success",
          title: "Arts updated successfully.",
        });

        // setSelectedSubmissionIds([]);

        // Refresh page to update changes
        setTimeout(function(){
            window.location.reload();
          }, 2000);

      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Something went wrong. Please try again.",
        });
      }
    }
  }

    return (
      <FilterHeader style={{margin: "30px 0"}}>
        <div>
          <TopFilterTitle title="Status"/>
          <Select
              name="status"
              value={submissionUpdate.status}
              onChange={updateStatus}
              disableUnderline
              displayEmpty
              style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
            >
              {artStatusList.map((artStatus) => (
                  <MenuItem key={artStatus.id} value={artStatus.status_name} style={buttonAndTextFontStyle}>{artStatus.status_name}</MenuItem>
              ))}
            
          </Select>
        </div>
        <div style={{marginLeft: "40px"}}>
          <TopFilterTitle title="Schedule Type"/>
          <Select
              id="scheduleTypeSelect"
              name="scheduleType"
              value={submissionUpdate.scheduleType}
              onChange={updateStatus}
              disableUnderline
              displayEmpty
              style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
            >
              <MenuItem key="none" value="" style={buttonAndTextFontStyle}>None</MenuItem>

              {scheduleTags.map((scheduleTag) => (
                  <MenuItem key={scheduleTag.id} value={scheduleTag.tag_name} style={buttonAndTextFontStyle}>{scheduleTag.tag_name}</MenuItem>
              ))}
            
          </Select>
        </div>
        <div style={{marginLeft: "40px"}}>
          <TopFilterTitle title="Send Email"/>
          <Select
              id="emailTemplateId"
              name="emailTemplateId"
              value={submissionUpdate.emailTemplateId}
              onChange={updateStatus}
              disableUnderline
              displayEmpty
              style={{...buttonAndTextFontStyle, ...filterDropdownStyle}}
            >
              <MenuItem key={0} value={0} style={buttonAndTextFontStyle}></MenuItem>
              {emailTemplates.map((emailTemplate) => (
                  <MenuItem key={emailTemplate.id} value={emailTemplate.id} style={buttonAndTextFontStyle}>{emailTemplate.templateLabel}</MenuItem>
              ))}
            
          </Select>
        </div>

        <BtnArtSubmit
            type="submit"
            textAlign="right"
            style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
            onClick={updateSubmission}
          >
            Save Changes
          </BtnArtSubmit>
        {/* <BtnArtSubmit
              type="submit"
              textAlign="right"
              style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
              onClick={exportSubmission}
            >
              Export
          </BtnArtSubmit> */}
          { (AutomationAllowedInStatus.includes(submissionUpdate.status)) &&
            <>
              <BtnArtSubmit
                type="submit"
                textAlign="right"
                style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
                onClick={generateSubmission}
              >
                Generate
              </BtnArtSubmit>
            
              <BtnArtSubmit
                type="submit"
                textAlign="right"
                style={{ backgroundColor: "#0B7C80", cursor: "pointer", marginLeft: "20px" }}
                onClick={() => onImageGeneration(false, false)}
              >
                Image Creation
              </BtnArtSubmit>
            </>
          }
      </FilterHeader>
    )
}


const mapStateToProps = createStructuredSelector<any, { 
  userAccount: any, 
  token: string, 
  scheduleTags: ScheduleTagInterface[], 
  artStatusList: ArtStatusInterface[],
}>({
  userAccount: selectUserAccount,
  token: selectUserJWTToken,
  scheduleTags: selectScheduleTags,
  artStatusList: selectStatusList,
});

export default connect(mapStateToProps)(AdminArtHeaderForm);