import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";

import { selectUserJWTToken, selectUserAccount } from "../../redux/user/user.selector";
import { COMMON_DATE_RANGE, DateRangeInterface, COMMON_DATE_PROP, TableColumnProp } from "../Common";

import { AdminTable } from "../Table";
import TableQueries from "../Table/table-queries.component";
import { stausReportTable } from "../../utils/fetchCommForTable";
import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabArea,
  TabSubLink,
  TabSubTitle,
} from "../SharedStyle/styled";
import { dateToLocaleDateString } from "../../utils";

export const TABLE_COLUMNS: TableColumnProp[] = [
  {
    Header: "Status",
    accessor: "status_name",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Count",
    accessor: "submission_count",
    disableFilters: true,
    disableSortBy: true,
  }
];

interface AdminReportingProp { 
  token: String 
};

const AdminReporting = (props: AdminReportingProp) => {
  const [tableData, setTableData] = useState([]);
  const [dateRange, setDateRange] = useState(COMMON_DATE_RANGE);
  const [isCommLoading, setIsCommLoading] = useState(true);
  const { startDate, endDate } = dateRange;
  const { token } = props;

  useEffect(
    () => {
      // const start = new Date(startDate);
      // const end = new Date(endDate);

      // setDateRange({
      //   startDate: start,
      //   endDate: end,
      //   isDateOpened
      // });
      _getStatusReport(startDate, endDate);
    },
    //eslint-disable-next-line
    []
  );

  const _getStatusReport = async (
    startDate: Date ,
    endDate: Date,
  ) => {

    const start = dateToLocaleDateString(new Date(startDate));
    // .toLocaleDateString("en-CA");
    const end = dateToLocaleDateString(new Date(endDate));
    // .toLocaleDateString("en-CA");

    const reqBody = {
      method: "POST",
      data: {
        start,
        end,
      },
      url: "/api/admin/reportings/art-review-report"
    };

    try {
      const tableData = await stausReportTable(
        reqBody,
        token
      );

      setIsCommLoading(false);
      setTableData(tableData);

      return tableData;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
      return;
    }
  };

  const handleDateFilter = async ({ startDate, endDate }: DateRangeInterface) => {
    try {
        
        COMMON_DATE_RANGE.startDate = new Date(startDate + " 00:00:00");
        COMMON_DATE_RANGE.endDate = new Date(endDate + " 00:00:00");
        
      const tableData = await _getStatusReport(
        startDate,
        endDate
      );

      setTableData(tableData);
      setDateRange({
        startDate,
        endDate,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong. Please try again.",
      });
    }

    return;
  };
  
  return (
    <SubmissionContainer>
      <TabHeader>
        <TabTitle>Art Review reports</TabTitle>

        <TabSubLink to={`/admin/reporting/artists`}>
          <TabSubTitle>Artists</TabSubTitle>
        </TabSubLink>

      </TabHeader>
      <TabArea>
        <TableQueries
          handleDateFilter={handleDateFilter}
          globalStartDate={startDate}
          globalEndDate={endDate}
          isDateOpened={COMMON_DATE_PROP.isDateOpened}
          note="Note: Default is today."
        />
        {isCommLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "50px",
            }}
          >
            <img
              className="rotating"
              src={teefuryBirdLogo}
              alt="tee bird"
              style={{ height: "100px", width: "100px" }}
            />
          </div>
        ) : tableData.length > 1 ? (
          <AdminTable
            columns={TABLE_COLUMNS}
            setTableData={setTableData}
            startDate={startDate}
            endDate={endDate}
            data={tableData}
            token={token}
          />
        ) : (
          <h2> No Records Found </h2>
        )}
      </TabArea>
    </SubmissionContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(AdminReporting);
