import React, { useState, useEffect } from "react";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";

import MailOutlineIcon from "@material-ui/icons/MailOutline";

import {
  CardContainer,
  CardWrapper,
  ImgCard,
  CardFooter,
  Figcaption,
  ArtTitle,
  Caption,
  ArtHeaders,
} from "./art-submissions-card.styles";

import { withStyles } from "@material-ui/core/styles";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { dateToLocaleDateString } from "../../utils";
import { ApprovedFlag, NewArtistFlag } from "../Common";

const AdminArtCard = (props: any) => {
  const {
    token,
    previewArt,
    id,
    index,
    artistName,
    isApprovedArtist,
    internalTitle,
    emailColor,
    createdAt,
    handleSelectArtCard,
    openAdminArtApproval,
    backgroundColorCode,
    isSelected,
    creationType,
    isNewArtist,
  } = props;
  const [imageSrc, setImageSrc] = useState("");
  const [emailStatusColor, setEmailStatusColor] = useState({
    color: "#6a6a6a",
    opacity: undefined as number | undefined,
  });

  useEffect(
    () => {
      const controller = new AbortController();
      const signal = controller.signal;
      const fetchImage = () => {
        const thumbImg = `/api/art-submissions-thumb/?src=${previewArt.substring(
          20
        )}`;
        fetch(thumbImg, { signal, headers: { Authorization: `JWT ${token}` } })
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            setImageSrc(URL.createObjectURL(blob));
          })
          .catch((error) => {
            // console.error(error);
          });
      };
      
      if (!previewArt) {
        setImageSrc(teefuryBirdLogo);
      } else {
        fetchImage();
      }

      _changeEmailStatusColor();
      return () => {
        controller.abort();
      };
    },
    //eslint-disable-next-line
    [token, previewArt]
  );

  const _changeEmailStatusColor = () => {
    const emailStatusColor = JSON.parse(emailColor);
    if (emailStatusColor.color === "#6a6a6a") {
      setEmailStatusColor({ color: "white", opacity: 0 });
    } else {
      setEmailStatusColor(emailStatusColor);
    }
  };

  const StyledCircleCheckedFilled = withStyles({
    root: {
        width: "25px",
        height: "25px",
        borderRadius: "30px",
        color: "#007FA3",
        position: "absolute",
        right: "10px",
        top: "10px",
    }  
})(CircleCheckedFilled);

  return (
    <CardContainer>
      <CardWrapper
        onClick={handleSelectArtCard}
        id={id}
        style={{ margin: "18px 15px" }}
        data-selected={isSelected}
      >
        <ImgCard
          style={{ backgroundColor: `#${backgroundColorCode}` }}
          src={imageSrc ? imageSrc : teefuryBirdLogo}
          alt={internalTitle}
          loaded={imageSrc ? "true" : ""}
        />
        <Figcaption>

        {isSelected ? ( <StyledCircleCheckedFilled/> ) : null }

          <ArtTitle style={{ fontSize: "16px", marginTop: "20px" }}>
            {internalTitle.toUpperCase()}
          </ArtTitle>
          <Caption>Artist:</Caption>
          <ArtHeaders>
            {artistName}
            {isApprovedArtist == 'YES' && <ApprovedFlag />}
            {isNewArtist == 'YES' && <NewArtistFlag />}
          </ArtHeaders>
          <Caption>Submitted on:</Caption>
          <ArtHeaders style={{ fontSize: "14px" }}>
            { dateToLocaleDateString(new Date(createdAt))
            //.toLocaleDateString()
            }
          </ArtHeaders>
          <Caption>Creation Type:</Caption>
          <ArtHeaders>{creationType ?? 'None'}</ArtHeaders>
          <MailOutlineIcon style={emailStatusColor} />
        </Figcaption>
        <CardFooter id={id} data-index={index} onClick={openAdminArtApproval}>
          Review Artwork
        </CardFooter>
      </CardWrapper>
    </CardContainer>
  );
};

export default AdminArtCard;
