import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserJWTToken } from "../../redux/user/user.selector";

import axios from "axios";
import Swal from "sweetalert2";
import MUIRichTextEditor from "mui-rte";
import { Loader } from "../Common";

import {
  MuiThemeProvider,
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { BtnArtSubmitLoading } from "../Button";
import { ReactComponent as LoadingIcon } from "../../assets/loading.svg";

import { TabArea, FilterHeader, AdjustableIconWrapper, MockEmailContainer, SelectWrapper } from "../AdminArtApproval/admin-art-approval.styles";
import { EmailTemplateInterface, SubmissionInterface, SubmissionLogInterface } from "./admin-art-submission-types";
import { formatedDateString } from "../../utils";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#64add1",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 300,
  },
  emailForm: {
    width: "100%",
  },
}));

const defaultTheme = createMuiTheme();
const focusTheme = createMuiTheme();

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 20,
        marginBottom: 20,
        minHeight: 500,
        width: "calc(100% - 30px)",
        padding: "0px 14px 18.5px 14px",
        border: "1px solid #c4c4c4",
        borderRadius: 4,
      },
    },
  },
});

Object.assign(focusTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 20,
        marginBottom: 20,
        minHeight: 500,
        width: "calc(100% - 30px)",
        padding: "0px 14px 18.5px 14px",
        border: "1px solid #64add1",
        borderRadius: 4,
      },
    },
  },
});

interface BulkEmailInterface {
    emailTemplate: EmailTemplateInterface,
    token: string;
    closeAdminArtDecline: Function;
    submissionsArr: SubmissionInterface[],
    selectedSubmissionIds: number[],
    contactEmail: string;
    onSentBulkEmail: Function;
    newStatus: string;
}

interface ArtUpdateInterface {
  id: number;
  logsData: string;
}

const BulkEmail = (props: BulkEmailInterface) => {
  const {
    token,
    emailTemplate,
    closeAdminArtDecline,
    submissionsArr,
    selectedSubmissionIds,
    contactEmail,
    onSentBulkEmail,
    newStatus,
  } = props;

  const ref = useRef<any>(null);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  // const [whichBtn, setWhichBtn] = useState("");

  // console.log("emailTemplate: ");
  // console.log(emailTemplate);

  const {
    emailSubject,
    emailBody,
    templateLabel,
    templateColor,
  } = emailTemplate;

  const _changeBorderColor = (event: any) => {
    event === "focus" ? setIsFocused(true) : setIsFocused(false);
  };

  const _handleInputChange = (event: any) => {
    const { name, value } = event.target;


  };

  const _setBtnRef = async (event: any) => {
    // await setWhichBtn(event.target.textContent);
    if(ref.current) {
      ref.current.save();
    }
  };


  const sendEmailAllSelected = async (data: string) => {

      // alert('sendEmailAllSelected');
      // return;

      setIsLoading(true);
      
      // Parse email body
      const { blocks } = JSON.parse(data);
      const textArr = blocks.map((block: any) => block.text);

      for (let i = 0; i < textArr.length; i++) {
        textArr[i] = textArr[i].split("\n\n").join("<br/><br/>");
      }

      const emailBody = textArr.join("<br/>");

        const timeOptions = {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        } as Intl.DateTimeFormatOptions;

        const selectedSubmissionsArr = submissionsArr.filter(
          (submission) => selectedSubmissionIds.includes(submission.id)
        );

          // console.log(selectedSubmissionsArr);
          // return true;

        // Group arts by aritst and list all arts in single email
        let groupingViaCommonArtists = Object.values(
            selectedSubmissionsArr.reduce((arts, current) => {
              arts[current.artistName] = arts[current.artistName] ?? [];
              arts[current.artistName].push(current);
                return arts;
            }, {} as Record<string, SubmissionInterface[]>)
        );
        console.log(groupingViaCommonArtists);
        // return;

        for (const submissions of groupingViaCommonArtists) {

          let finalTitle = '';

          if (submissions.length > 1) {
            // Get list of titles
            const titles = submissions.map((s) => s.internalTitle);
            // const lastTitle = titles.pop();
            finalTitle = titles.join(',<br/>'); // + ' and ' + lastTitle
          } else {
            finalTitle = ` "${submissions[0].internalTitle}"`;
          }

          const { artistName, artistEmail, scheduleDate } = submissions[0];

          const finalEmailSubject = emailSubject
              .replace("{submission_title}", finalTitle)
              .replace("{artist_name}", `${artistName}`)
              .replace("{schedule_date}", scheduleDate ? `${formatedDateString(scheduleDate)}` : 'NA');

            const finalEmailBody = emailBody
              .replace("{submission_title}", finalTitle)
              .replace("{artist_name}", `${artistName}`)
              .replace("{schedule_date}", scheduleDate ? `${formatedDateString(scheduleDate)}` : 'NA');

          const artUpdates = [] as ArtUpdateInterface[];

          for (const submission of submissions) {

            const { artistName, artistEmail, emailStatus, id, status } = submission;
            let { logs: submissionLogs } = submission;

            const currentEmailStatus = !!emailStatus ? emailStatus : 'Not Emailed';

            let logs: SubmissionLogInterface[] = []

            if (submissionLogs !== null) {
              logs = JSON.parse(submissionLogs);
            } else {
              logs = [];
            }

            if(status != newStatus) {
              logs.push(
                {
                  logInfo: `${contactEmail} changed status from "${status}" to "${newStatus}"`,
                  logTimestamp: new Date().toLocaleTimeString("en-US", timeOptions),
                },
              );
            }

            logs.push(
              {
                type: "emailStatusChange",
                logInfo: `${contactEmail} changed emailStatus from "${currentEmailStatus}" to "${templateLabel}"`,
                logTimestamp: new Date().toLocaleTimeString("en-US", timeOptions),
              },
              {
                type: "emailContentChange",
                logInfo: `${contactEmail} sent "${templateLabel}" email to ${artistName}`,
                emailContent: {
                  artistEmail,
                  emailSubject: finalEmailSubject,
                  emailBody: finalEmailBody,
                },
                logTimestamp: new Date().toLocaleTimeString("en-US", timeOptions),
              }
            );

            const logsData = JSON.stringify(logs);
            // submission.logs = logsData;

            artUpdates.push({ id, logsData })
          }

          // console.log(logs);
          
          console.log(finalEmailBody);

          await _sendEmail(artistEmail, artUpdates, finalEmailSubject, finalEmailBody);
        }


        Swal.fire({
          title: "The status updated and emails have been sent!",
          icon: "success",
        });

        setIsLoading(false);
        closeAdminArtDecline();
        onSentBulkEmail();
  };

  const _sendEmail = async (
    artistEmail: string, 
    artUpdates: ArtUpdateInterface[],
    // id: number, 
    // logs: any, 
    emailSubject: string, 
    emailBody: string
  ) => {
    const reqBody = {
      artistEmail,
      subject: emailSubject,
      emailStatus: templateLabel,
      emailColor: { color: templateColor },
      htmlContent: emailBody,
      artUpdates,
    };

    try {
      await axios.put(`/api/admin/email-send`, reqBody, {
        headers: { Authorization: `JWT ${token}` },
      });
    } catch (error) {
      setIsLoading(false);
      Swal.fire("Email(s) not sent. Please try again.");
    }
  };

  return (
      <TabArea>
        <FilterHeader>
          <AdjustableIconWrapper onClick={closeAdminArtDecline}>
            <HighlightOffIcon />
          </AdjustableIconWrapper>
        </FilterHeader>
    { isLoading ? ( <Loader /> ) : (

    <MockEmailContainer>
        <FormControl className={classes.emailForm}>
          <ThemeProvider theme={theme}>
            <TextField
              id="emailSubject"
              label="Email Subject Line"
              name="emailSubject"
              value={emailSubject}
              variant="outlined"
              style={{ marginTop: "20px" }}
              onChange={_handleInputChange}
            />
          </ThemeProvider>
          {isFocused ? (
            <MuiThemeProvider theme={focusTheme}>
              <MUIRichTextEditor
                label="Start typing..."
                defaultValue={emailBody}
                ref={ref}
                controls={[
                  "title",
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "highlight",
                  "undo",
                  "redo",
                  "link",
                  "numberList",
                  "bulletList",
                ]}
                inlineToolbar={true}
                onSave={sendEmailAllSelected}
                onBlur={() => _changeBorderColor("blur")}
              />
            </MuiThemeProvider>
          ) : (
            <MuiThemeProvider theme={defaultTheme}>
              <MUIRichTextEditor
                label="Start typing..."
                defaultValue={emailBody}
                ref={ref}
                controls={[
                  "title",
                  "bold",
                  "italic",
                  "underline",
                  "strikethrough",
                  "highlight",
                  "undo",
                  "redo",
                  "link",
                  "numberList",
                  "bulletList",
                ]}
                inlineToolbar={true}
                onSave={sendEmailAllSelected}
                onFocus={() => _changeBorderColor("focus")}
              />
            </MuiThemeProvider>
          )}
        </FormControl>
        
          <h2>Are you sure?</h2>
          <p>The status will update and emails will be sent to the corresponding artists.</p>
        <BtnArtSubmitLoading
          type="button"
          textAlign="center"
          style={{ width: "300px", height: "45px" }}
          onClick={_setBtnRef}
        >
          {isLoading ? <LoadingIcon /> : "Update Status and Send Email"}
        </BtnArtSubmitLoading>
    </MockEmailContainer>
    )}
    </TabArea>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(BulkEmail);
