import React from "react";
import { useState, useEffect } from "react";
import { ArtworkType } from "../../constants";
import DesignerTipBanner from "../../assets/dashboard/banner2.jpg"
import { SITE_BACKEND_FULL_URL } from '../../server'
import axios from "axios";

import { ImageCard, InstructionCard } from "../AdminSettings/artist-dashboard.styles"

interface IDesignerTip {
  id: number;
  description: string;
  image_path: string;
}

interface DesignerTipsProps {
  artworkType: String,
  token: String,
}

export const DesignerTips = ({artworkType, token}: DesignerTipsProps) => {

  const [designerTips, setDesignerTips] = useState<IDesignerTip[]>([])

  useEffect(() => {
    // Load designer-tips
    axios.get("api/admin/settings/designer-tips/list", {
      headers: { Authorization: `JWT ${token}` },
    }).then((res) => {
      setDesignerTips(res.data.designerTips);
    });
  }, [])

  const _getImageSrcURL = (imgFilename: string) => {
    const serverURL =  SITE_BACKEND_FULL_URL.replace('/backend', '');
    return serverURL+'/uploads/designer-tips/'+imgFilename;
  }

  return artworkType == ArtworkType.classic ? <div style={{ marginTop: '50px' }}>
    <p style={{ maxWidth: "80%",margin: 'auto', fontWeight: 'bold', fontSize: "20px", color: '#555' }}>Tips & Tricks</p>
    <div style={{ textAlign: 'center' }}>
      <img src={DesignerTipBanner} style={{maxWidth: "80%", margin: 'auto', borderRadius: "30px", marginTop: "20px"}}/>
    </div>

    <div style={{ maxWidth: "60%", margin: 'auto'}}>
      { designerTips.map((designerTip) => ( 
          <div style={{marginTop: "20px"}} key={designerTip.id}>
            <div>
              <ImageCard src={_getImageSrcURL(designerTip.image_path)}/>
            </div>
            <InstructionCard dangerouslySetInnerHTML={{__html: designerTip.description.replace(/(?:\r\n|\r|\n)/g, '<br/>')}}></InstructionCard>
          </div>
      ))}
    </div>
  </div> : <></>
}