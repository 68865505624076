import React, { useEffect, useState } from "react";
import { IComingSoon } from "./types";
import Swal from "sweetalert2";
import { TextAreaStyledEditable, FormInputTitleEditable } from "../../AdminArtApproval/admin-art-approval.styles";
import { SITE_BACKEND_FULL_URL } from '../../../server'
import { 
  WhatsSection,
  WhatsSectionTitle,
  WhatsSectionTile,
} from "../artist-dashboard.styles"
import { ReactComponent as Upload } from "../../../assets/upload.svg";

import {
  ArtPreview,
  IconContainer,
  IconTopSubtitle,
  PreviewImageWrapperWider,
  PreviewImageWider,
  FormArtistSubmit,
} from "../../ArtistSubmitArt/artist-submit-art.styles";

import { 
  DialogTitle,
  DialogContent,
} from '../../Dialog/dialog.component';
import { MainButton, ButtonMd, ButtonSm, InputArtPreview } from "../../Button";
import halloweenImage from "../../../assets/halloween.png";
import Dialog from '@material-ui/core/Dialog';
import axios, { AxiosRequestConfig } from "axios";

interface ComingSoonProps {
  haloweenSettings: any,
  onRefresh: Function;
  onLoading: Function;
  token: String,
}

const ComingSoonHalloween = (props: ComingSoonProps) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [artPreviewImg, setArtPreviewImg] = useState('');
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const artworkSubmissionForm: React.RefObject<HTMLFormElement> = React.createRef();
  const [ctaButtonText, setCTAButtonText] = useState('');
  const [description, setDescription] = useState('');

  const handleClose = function() {
    setIsOpen(false);
  }

  const { onRefresh, onLoading, haloweenSettings, token } = props;

  useEffect(() => {
    setCTAButtonText(haloweenSettings.cta_button_text);
    setDescription(haloweenSettings.description_text);
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if(!isFileUploaded) {

      // Update without image
      const formData = {
        description,
        cta_text: ctaButtonText
      };

      const {
        data: { res },
      } = await axios.post(`api/admin/settings/dashboard/update-haloween-details-without-file`, formData, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })

      showSuccessAndRefresh();

    } else {

      if(!artworkSubmissionForm.current) {
        return;
      }

      const { elements } = artworkSubmissionForm.current;
      const inputsDOM = Array.from(elements) as HTMLInputElement[];

      const formData = new FormData();

      inputsDOM.forEach((el) => {
        console.log(el);

        const { files, name, value } = el ;
        if (files) {
          formData.append(name, files[0]);
        } else if (value) {
          formData.append(name, value);
        }
      });

      const {
        data: { res },
      } = await axios.post(`api/admin/settings/dashboard/update-haloween-details`, formData, {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
      
      showSuccessAndRefresh();
    }
  }

  const showSuccessAndRefresh = () => {

    console.log('showSuccessAndRefresh');

    setIsOpen(false);
    onRefresh();

    Swal.fire({
      icon: "success",
      text: "Halloween settings saved successfully.",
      showConfirmButton: true,
    });
  }

  const getImageSrcURL = (imgFilename: string) => {
    const serverURL =  SITE_BACKEND_FULL_URL.replace('/backend', '');
    return serverURL+'/uploads/favorites/'+imgFilename;
  }

  const clearImage = () => {
    setArtPreviewImg('');
    setIsFileUploaded(false);
  }

  
  const editComingSoon = (id: number) => {
      setIsOpen(true);

      setArtPreviewImg(getImageSrcURL(haloweenSettings.banner_image));
      setIsFileUploaded(false);
  }

  const saveTemplate = async (data: any) => {
    
  }

  const onChangeArtFile = async (event: any) => {
    //
    let [file] = event.target.files;
    if (!file) return;
    
    console.log(file.name);
    
    const fileExtention = file.name.split('.').pop();
    const expectedFileExtentions = ['png','jpg','jpeg'];

    if(!expectedFileExtentions.includes(fileExtention.toLowerCase())) {
        Swal.fire({
            icon: "error",
            text: "Does not meet requirements. PNG and JPG file types accepted.",
            showConfirmButton: true,
        });
        
        event.target.value = null;

      return;
    }
    
    // Make sure `file.size` does not 0 size
    if (file.size < 5) {
      Swal.fire({
        icon: "error",
        text: "Sorry your file is too small! Please make sure your image size is greater than 0MB.",
        showConfirmButton: true,
      });
      
      event.target.value = null;

      return;
    }
    
//    console.log(file.size);
    
    // Make sure `file.size` does not exceed 15MB
    if (file.size > 15000000) {
      Swal.fire({
        icon: "error",
        text: "Sorry your file is too large! Please limit your image size to less than 15MB.",
        showConfirmButton: true,
      });
      
      // event.target.value = null;

      return;
    }

    const artPreviewDetails = await _generatePreviewImg(file) as { data: string };
    setArtPreviewImg(artPreviewDetails.data);
    setIsFileUploaded(true);
  };

  

  const _generatePreviewImg = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const loadImg = () => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);

        const image = new Image();
        image.src = reader.result as string;

        image.onload = function() {
            // access image size here 
            // const dimension = { width: image.width, height: image.height } as Dimension;

            resolve({ data: reader.result });
        };
      };

      const loadError = (event: any) => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        reject(event);
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", loadImg);
      reader.addEventListener("error", loadError);
    });
  };

  const handleCTATextChange = (event: any) => {
    setCTAButtonText(event.target.value);
  }

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  }

  return <WhatsSection>
        <WhatsSectionTitle>WHAT'S COMING SOON</WhatsSectionTitle>
        <WhatsSectionTile>
          <div style={{padding: '0 20px'}}>
            <img src={getImageSrcURL(haloweenSettings.banner_image)} alt="halloween" style={{ width: "100%" }} />
            <div className="content" dangerouslySetInnerHTML={{__html: haloweenSettings.description_text.replace(/(?:\r\n|\r|\n)/g, '<br/>')}}></div>

            <ButtonMd onClick={() => {}} style={{cursor: 'pointer'}}>
              <span style={{color: "#000"}}>{ haloweenSettings.cta_button_text }</span>
            </ButtonMd>
          </div>
          <div style={{textAlign: 'center'}}>
            <MainButton 
                type="button" 
                style={{width: "200px", cursor: "default", fontSize: "18px", cussor: 'pointer', margin: 'auto' }} 
                onClick={editComingSoon}>
                  Edit
              </MainButton>
            </div>
        </WhatsSectionTile>

          <Dialog onClose={handleClose} open={isOpen}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
            <DialogContent dividers style={{textAlign: 'center'}}>
              <h2 style={{marginTop: "-20px"}}></h2>

              <FormArtistSubmit
                onSubmit={handleSubmit}
                ref={artworkSubmissionForm}
              >
                <h2>Banner Image</h2>
                <PreviewImageWrapperWider style={{display: artPreviewImg ? 'block' : 'none', textAlign: "center", width: '500px'}}>
                    <PreviewImageWider style={{maxHeight: "250px", width: "100%", maxWidth: 'auto'}}
                      src={ artPreviewImg }
                      alt="Art Preview"
                    />
                </PreviewImageWrapperWider>
                <label htmlFor="preview-art" style={{display: artPreviewImg ? 'none' : 'block'}}>
                  <InputArtPreview
                    id="preview-art"
                    type="file"
                    name="artFile"
                    onChange={onChangeArtFile}
                    textAlign="center"
                    accept="image/png,image/jpeg"
                  >
                  <ArtPreview style={{width: '500px'}}>
                    <IconContainer>
                      <Upload />
                    </IconContainer>
                    <IconTopSubtitle>Click to upload images</IconTopSubtitle>
                  </ArtPreview>
              </InputArtPreview>
              </label>

              <ButtonSm type="button" style={{ display: artPreviewImg ? 'initial' : 'none', marginBottom: '20px' }} onClick={clearImage}>Clear Image</ButtonSm>

              <h2>Description</h2>
              <TextAreaStyledEditable
                type="text"
                name="description"
                label="Description"
                placeholder=""
                data-lpignore="true"
                autoComplete="off"
                handleChange={handleDescriptionChange}
                value={description}
                required
                style={{height: '300px'}}
              />

              <h2>CTA button text</h2>
              <FormInputTitleEditable
                type="text"
                name="cta_text"
                label="title"
                data-lpignore="true"
                autoComplete="off"
                handleChange={handleCTATextChange}
                value={ctaButtonText}
                maxlength="180"
                required
              />

              <MainButton 
                type="submit" 
                style={{width: "200px", cursor: "default", fontSize: "24px", cussor: 'pointer', marginBottom: "20px" }}>
                  Save
              </MainButton>
            </FormArtistSubmit>
            </DialogContent>
          </Dialog>
      </WhatsSection>
  };

export default ComingSoonHalloween;
