
import Swal from "sweetalert2";
import axios from "axios";
import { SITE_BACKEND_API_URL, SITE_BACKEND_FULL_URL } from "../server";

export function isSameCreationTypeSelected(submissionsArr, selectedSubmissionIds) {
  const selectedSubmissions = submissionsArr.filter((s) => selectedSubmissionIds.includes(s.id));
    if (!selectedSubmissions) {
      return false;
    }

    // Only same creation type should be processed in one file. 
    const selectedCreationTypes = new Set(selectedSubmissions.map(s => s.creationType));
    if (selectedCreationTypes.size > 1) {
      Swal.fire({
        icon: "error",
        title: "Only same creation type should be processed. Please check creation type!",
      });

      return false;
    }

    const [first] = selectedCreationTypes;
    return first;
}


export async function generateProducts(submissionsArr, selectedSubmissionIds, token) {
    const invalidSubmissions = submissionsArr.find((s) => selectedSubmissionIds.includes(s.id) && !s.creationType);

    if(invalidSubmissions) {
      Swal.fire({
        icon: "error",
        title: "To generate product, 'Creation Type' can't be empty for art: '"+invalidSubmissions.title+"'",
      });

      return false;
    }

    // const selectedSubmissions = submissionsArr.filter((s) => selectedSubmissionIds.includes(s.id));
    // if (!selectedSubmissions) {
    //   return false;
    // }

    // // Only same creation type should be processed in one file. 
    // const selectedCreationTypes = new Set(selectedSubmissions.map(s => s.creationType));
    // if (selectedCreationTypes.size > 1) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Only same creation type should be processed. Please check creation type!",
    //   });

    //   return false;
    // }

    if (!isSameCreationTypeSelected(submissionsArr, selectedSubmissionIds)) {
      return false;
    }

    const {
        data: { status: responseStatus, text, data: { csvURL }},
    } = await axios({
        url: SITE_BACKEND_API_URL+"/admin/odad/generate?ids="+selectedSubmissionIds.join(','),
        method: "GET",
        headers: { Authorization: `JWT ${token}` },
    });

    if (responseStatus != 'success') {
      Swal.fire({
        icon: "error",
        title: text,
      });

      return false;

    } else {
      if (!csvURL) {
        Swal.fire({
          icon: "error",
          title: 'Error in generating product!',
        });
  
        return false;
      } else {
        window.open(csvURL, '_blank');

        Swal.fire({
          icon: "success",
          title: "Product generated and downloaded successfully.",
        });

        return true;
      }
    }
}

export const exportToDesktop = async (showOnlyArtistTypes, scheduleType, token) => {
  const {
      data: filePaths,
  } = await axios({
      url: SITE_BACKEND_API_URL+"/admin/odad/exportAllArts",
      method: "POST",
      data: { showOnlyArtistTypes, scheduleType, selectedSubmissionIds: []},
      headers: { Authorization: `JWT ${token}` },
  });

  window.open(SITE_BACKEND_FULL_URL+"/"+filePaths.zipPath, '_blank');

  // Push to print server (Don't use await to push to server, this creates unneccessarily waiting)
  axios({
    url: SITE_BACKEND_API_URL+"/admin/odad/pushArtsToPrintServer",
    method: "POST",
    data: { ...filePaths, sendToImageProgram: 'NO' },
    headers: { Authorization: `JWT ${token}` },
  });
}