import React, { useState } from "react";
import { MainButton } from "../Button";
import { DateRange } from "react-date-range";
import { ReactComponent as LoadingIcon } from "../../assets/loading.svg";

import CloseIcon from "@material-ui/icons/Close";
import { COMMON_DATE_RANGE, COMMON_DATE_PROP } from ".";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css f

import { FilterDateBtnsWrapper } from "../Table/table.styles.jsx";
import { MainButtonStyled } from "../Button/button.styles";
import { dateToLocaleDateString } from "../../utils";

const calendarBtnStyles = {
  height: "40px",
  fontSize: "17px", 
  fontFamily: "Arial",
  color: "#fff",
};

interface TableQueriesInterface {
  handleDateFilter: Function;
  globalStartDate: Date;
  globalEndDate: Date;
  note: String;
  isDateOpened: Boolean;
  isDateClearable?: Boolean;
  calenderLabel?: String;
}

const DashboardCalender = ({
  handleDateFilter,
  globalStartDate,
  globalEndDate,
  note,
  isDateOpened,
  isDateClearable=false,
  calenderLabel="Open Calendar",
}: TableQueriesInterface) => {
  const [dates, setDates] = useState({
    startDate: globalStartDate,
    endDate: globalEndDate,
    key: "selection",
  });

  const [state, setState] = useState({
    isDateOpen: isDateOpened,
    isLoading: false,
    isDateClear: false,
  });

  const { isDateOpen, isLoading, isDateClear } = state;

  const handleChange = (item: any) => {
    setDates(item.selection);
  };

  const handleClick = async () => {

    setState({ ...state, isLoading: true, isDateClear: false, isDateOpen: false });
    const { startDate, endDate } = dates;
    
    // To fix date range selection issue due to timezone change
    const start = dateToLocaleDateString(startDate);
    const end = dateToLocaleDateString(endDate);
    
    try {
      await handleDateFilter({
        startDate: start,
        endDate: end,
      });
      setState({ ...state, isLoading: false,  isDateClear: false, isDateOpen: false });
      
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleOpen = async () => {
      COMMON_DATE_PROP.isDateOpened = true;
      setState({ ...state, isDateOpen: COMMON_DATE_PROP.isDateOpened });
  };
  
  const handleClose = async () => {

      // reseting initial date range
      COMMON_DATE_RANGE.startDate = globalStartDate;
      COMMON_DATE_RANGE.endDate = new Date();
      COMMON_DATE_PROP.isDateOpened = false;
      
      setDates({
          startDate: COMMON_DATE_RANGE.startDate,
          endDate: COMMON_DATE_RANGE.endDate,
          key: "selection"
      });
      
      // Reload result after reseting initial date range
      setState({ ...state, isLoading: true});
      
      await handleDateFilter({
        startDate: dateToLocaleDateString(new Date(COMMON_DATE_RANGE.startDate)),
        // .toLocaleDateString("en-CA"),
        endDate: dateToLocaleDateString(new Date(COMMON_DATE_RANGE.endDate)),
        // .toLocaleDateString("en-CA"),
      });
      
      setState({ ...state, isDateOpen: COMMON_DATE_PROP.isDateOpened, isLoading: false, isDateClear: false });
  };

  const handleClear = async () => {
    COMMON_DATE_PROP.isDateOpened = false;
    
    // Reload result after reseting initial date range
    setState({ ...state, isLoading: true});
    
    await handleDateFilter(null);
    
    setState({ ...state, isDateOpen: COMMON_DATE_PROP.isDateOpened, isLoading: false, isDateClear: true });
};

const getFormatedDate = (date: Date): string => {
  var options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  return dateToLocaleDateString(date, options).replace(".", "");
  // .toLocaleDateString("en-CA", options).replace(".", "");
}

  return (
    <>
      {isDateOpen ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            paddingBottom: "25px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DateRange
              editableDateInputs={true}
              onChange={handleChange}
              moveRangeOnFirstSelection={false}
              ranges={[dates]}
            />
            <FilterDateBtnsWrapper>
              <MainButton
                style={{
                  ...calendarBtnStyles,
                  width: "138px",
                  marginLeft: "20px",
                }}
                onClick={handleClick}
              >
                {isLoading ? <LoadingIcon /> : "Filter by Date"}
              </MainButton>
              <MainButton
                style={{
                  ...calendarBtnStyles,
                  width: "138px",
                  marginLeft: "20px",
                }}
                onClick={handleClose}
              >
                Default
              </MainButton>

              { isDateClearable ? 
              <MainButton
                style={{
                  ...calendarBtnStyles,
                  width: "138px",
                  marginLeft: "20px",
                }}
                onClick={handleClear}
              >
                Clear Date
              </MainButton>
              : ""}
            </FilterDateBtnsWrapper>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex"}}>
          <div style={{ display: "flex", alignItems: "center"}}>
            <p style={{ textAlign: "center" }}> 
              { isDateClear ? "No date range applied." : 
                getFormatedDate(dates.startDate)+" - "+getFormatedDate(dates.endDate)
              }
              { !isDateClear && isDateClearable ?
                <MainButton
                    style={{
                      ...calendarBtnStyles,
                      width: "138px"
                    }}
                    onClick={handleClear}
                  >
                    Clear Date
                </MainButton>
              : "" }
            </p>
            <MainButtonStyled
              textAlign="right"
              style={{
                ...calendarBtnStyles,
                width: "151px",
                marginLeft: "10px"
              }}
              onClick={handleOpen}
            >
              { calenderLabel }
            </MainButtonStyled>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardCalender;
