import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Swal from "sweetalert2";

import { 
  WhatsSection,
  WhatsSectionTitle,
  WhatsSectionTile,
  TrendingList,
  Trending,
} from "../artist-dashboard.styles"

import { ITrend } from './types';
import { 
  DialogTitle,
  DialogContent,
} from '../../Dialog/dialog.component';
import { MainButton } from "../../Button";

import Dialog from '@material-ui/core/Dialog';

import {
  FormInputArtistStyled,
} from "../../ArtistSubmissionsEdit/artist-submissions-edit.styles";
import axios, { AxiosRequestConfig } from "axios";

interface TrendingNowProps {
  trendings: ITrend[];
  token: string;
  onRefresh: Function;
  onLoading: Function;
}

const TrendingNow = (props: TrendingNowProps) => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [selectedTrading, setSelectedTrading] = useState<ITrend|null>(null);

  const handleClose = function() {
    setIsOpen(false);
  }

  const { trendings, token, onRefresh, onLoading } = props;

  const editTrend = (id: number) => {

    setIsNew(false);
    const selectedTrend = trendings.find(t => t.id == id);
    if(selectedTrend) {
      setSelectedTrading(selectedTrend)
      setIsOpen(true);
    }
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target as { name: string; value: string };

    if(selectedTrading) {
      setSelectedTrading({ id: selectedTrading.id, trend_title: value });
    } 
  };

  
  const addTranding = () => {
    setIsNew(true);
    setSelectedTrading({ trend_title: '', id: 0 });
    setIsOpen(true);
  }

  const getDialogTitle = () => {
    return (isNew ? 'Add' : 'Edit') + ' Trending';
  }

  const updateTreding = async () => {
    if(!selectedTrading || selectedTrading.trend_title === "") {
        Swal.fire({icon: "error", text: "Trending name can not be empty!"});
        return false;
    }
      
    onLoading();

    if(isNew) {
      const reqBody = {
        url: "api/admin/settings/dashboard/add-treding",
        method: "POST",
        headers: { Authorization: `JWT ${token}` },
        data: selectedTrading,
      } as AxiosRequestConfig;

      const {
        data: { tableRowData },
      } = await axios(reqBody);
      
    } else {
      const reqBody = {
        url: "api/admin/settings/dashboard/update-treding",
        method: "PUT",
        headers: { Authorization: `JWT ${token}` },
        data: selectedTrading,
      } as AxiosRequestConfig;

      const {
        data: { tableRowData },
      } = await axios(reqBody);
    }

    setIsOpen(false);
    onRefresh();

    Swal.fire({
      icon: "success",
      text: "Trending saved successfully.",
      showConfirmButton: true,
    });
  }

  const deleteTreding = async (id: number) => {

    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this Trend?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (response) => {
      if (response.value) {

        onLoading();

        const reqBody = {
          url: "api/admin/settings/dashboard/delete-treding",
          method: "DELETE",
          headers: { Authorization: `JWT ${token}` },
          data: { id },
        } as AxiosRequestConfig;
        
        const {
          data: { tableRowData },
        } = await axios(reqBody);

        onRefresh();

        Swal.fire({
          icon: "success",
          text: "Trending deleted successfully.",
          showConfirmButton: true,
        });
      }
    });
  }

  return <WhatsSection>
      <WhatsSectionTitle>WHAT'S TRENDING NOW</WhatsSectionTitle>
      <WhatsSectionTile>
        <TrendingList>
        { props.trendings.map((trending) => ( 
            <Trending>
              {trending.trend_title} 
              <div style={{ display: 'inline-block', marginLeft: "10px" }}>
                <EditIcon style={{ cursor: 'pointer' }} onClick={() => editTrend(trending.id)}/>
                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteTreding(trending.id)}/>
              </div>
            </Trending>
          ))}
        </TrendingList>

          <div style={{textAlign: 'center'}}>
          <MainButton 
              type="button" 
              style={{width: "200px", cursor: "default", fontSize: "18px", cussor: 'pointer', margin: 'auto' }} 
              onClick={addTranding}>
                Add New
            </MainButton>
          </div>
      </WhatsSectionTile>

      <Dialog onClose={handleClose} open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
        <DialogContent dividers style={{textAlign: 'center'}}>
          <h2 style={{marginTop: "-20px"}}>{ getDialogTitle() }</h2>

          <FormInputArtistStyled
            type="text"
            name="treding"
            label="treding"
            value={selectedTrading?.trend_title}
            style={{ fontSize: '16px', width: "500px", cursor: "default" }}
            onChange={handleChange}
            placeholder="Please enter what's trending now..."
          />

          <MainButton 
            type="button" 
            style={{width: "200px", cursor: "default", fontSize: "24px", cussor: 'pointer', marginBottom: "20px" }} 
            onClick={updateTreding}>
              Save
          </MainButton>
        </DialogContent>
      </Dialog>
    </WhatsSection>
};

export default TrendingNow;
