import React, { useState, useEffect } from "react";
import axios from "axios";
import { SITE_BACKEND_API_URL } from "../../server";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { selectColors } from "../../redux/settings/settings.selector";
import { setConfigStart } from "../../redux/settings/settings.action";

import PayoutTable from "../AdminPayout/payoutTable.component";
import { Loader } from "../Common";
import Swal from "sweetalert2";

const ColorSettings = ({ token, colors, refreshSettings }) => {
  
  const tableColumn = [
    { title: "Color Name", field: "color_name", validate: rowData => rowData.color_name !== '' },
  ];

  const handleRowAdd = async (rowData) => {
      
      if(!rowData.hasOwnProperty("color_name")) {
          Swal.fire({icon: "error", text: "Color name can not be empty!"});
          return false;
      }
      
    const reqBody = {
      url: "api/admin/settings/colors/add",
      method: "POST",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    const {
      data: { tableRowData },
    } = await axios(reqBody);

    refreshSettings();

    return tableRowData;
  };

  const handleRowUpdate = async (rowData) => {
      
      if(rowData.color_name === "") {
          Swal.fire({icon: "error", text: "Color name can not be empty!"});
          return false;
      }
      
    const reqBody = {
      url: "api/admin/settings/colors/update",
      method: "PUT",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    const {
      data: { tableRowData },
    } = await axios(reqBody);

    refreshSettings();

    return tableRowData;
  };

  const handleRowDelete = async (rowData) => {
    const reqBody = {
      url: "api/admin/settings/colors/delete",
      method: "DELETE",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    await axios(reqBody);
    
    refreshSettings();

    return true;
  };

  return (
    <div style={{marginTop: "20px"}}>
        {colors.length ? (
            <PayoutTable
              tableRowsData={colors}
              tableColumn={tableColumn}
              handleRowUpdate={handleRowUpdate}
              handleRowDelete={handleRowDelete}
              handleRowAdd={handleRowAdd}
              title="Colors"
            />
          ) : null
        }
    </div>
    
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  colors: selectColors
});

const mapDispatchToProps = (dispatch) => ({
  refreshSettings: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorSettings);
