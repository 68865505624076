import React, { useState } from "react";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import axios, { AxiosRequestConfig } from "axios";
import Swal from "sweetalert2";

import type { IFanFavorite } from './types';
import { ReactComponent as Upload } from "../../../assets/upload.svg";
import { SITE_BACKEND_FULL_URL } from '../../../server'

import { 
  DialogTitle,
  DialogContent,
} from '../../Dialog/dialog.component';
import { MainButton, ButtonSm, InputArtPreview } from "../../Button";

import Dialog from '@material-ui/core/Dialog';
import {
  FormInputArtistStyled,
} from "../../ArtistSubmissionsEdit/artist-submissions-edit.styles";

import {
  ArtPreview,
  IconContainer,
  IconTopSubtitle,
  PreviewImageWrapper,
  PreviewImage,
  FormArtistSubmit,
} from "../../ArtistSubmitArt/artist-submit-art.styles";

import { 
  FavoriteSection,
  FavoriteTile,
  WhatsSectionTitle,
  ImageCard,
} from "../artist-dashboard.styles"

interface FanFavoritePorps {
  fanFavorites: IFanFavorite[];
  token: string;
  onRefresh: Function;
  onLoading: Function;
}

const FanFavorite = (props: FanFavoritePorps) => {

  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [selectedFanFavorite, setSelectedFanFavorite] = useState<IFanFavorite|null>(null);
  const [artPreviewImg, setArtPreviewImg] = useState('');
  
  const handleClose = function() {
    setIsOpen(false);
  }

  const artworkSubmissionForm: React.RefObject<HTMLFormElement> = React.createRef();

  const { fanFavorites, token, onRefresh, onLoading } = props;

  const getImageSrcURL = (imgFilename: string) => {
    const serverURL =  SITE_BACKEND_FULL_URL.replace('/backend', '');
    return serverURL+'/uploads/favorites/'+imgFilename;
  }

  const editFanFavorite = (id: number) => {

    setIsNew(false);
    const selectedTrend = fanFavorites.find(t => t.id == id);
    if(selectedTrend) {
      setSelectedFanFavorite(selectedTrend)
      setArtPreviewImg(getImageSrcURL(selectedTrend.image_path));
      setIsFileUploaded(false);
      
      setIsOpen(true);
    }
  }

  const handleChange = (event: any) => {
    const { name, value } = event.target as { name: keyof IFanFavorite; value: string };

    if(selectedFanFavorite) {

      const newSelectedFanFavorite = { ...selectedFanFavorite };

      switch(name) {
        case 'artist_name' :
          newSelectedFanFavorite.artist_name = value;
          break;
        case 'title' :
          newSelectedFanFavorite.title = value;
          break;
      }

      setSelectedFanFavorite(newSelectedFanFavorite);
    } 
  };
  
  const addFanFavorite = () => {
    setIsNew(true);
    setSelectedFanFavorite({ title: '', artist_name: '', image_path: '', id: 0 });
    setArtPreviewImg('');
    setIsFileUploaded(false);
    setIsOpen(true);
  }

  const getDialogTitle = () => {
    return (isNew ? 'Add' : 'Edit') + ' Fan Favorite';
  }

  const updateFanFavorite = async () => {
    if(!selectedFanFavorite || selectedFanFavorite.title === "") {
        Swal.fire({icon: "error", text: "Title can not be empty!"});
        return false;
    }
      
    const reqBody = {
      url: "api/admin/settings/dashboard/update-fan-favorite",
      method: "PUT",
      headers: { Authorization: `JWT ${token}` },
      data: selectedFanFavorite,
    } as AxiosRequestConfig;

    onLoading();

    const {
      data: { tableRowData },
    } = await axios(reqBody);

    setIsOpen(false);
    onRefresh();

    Swal.fire({
      icon: "success",
      text: "Fan favorite saved successfully.",
      showConfirmButton: true,
    });
  }

  const deleteFanFavorite = async (id: number) => {

    Swal.fire({
      title: "",
      text: "Are you sure you want to delete this fan favorite?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
    }).then(async (response) => {
      if (response.value) {
        const reqBody = {
          url: "api/admin/settings/dashboard/delete-fan-favorite",
          method: "DELETE",
          headers: { Authorization: `JWT ${token}` },
          data: { id },
        } as AxiosRequestConfig;
        
        onLoading();

        const {
          data: { tableRowData },
        } = await axios(reqBody);

        onRefresh();

        Swal.fire({
          icon: "success",
          text: "Fan favorite deleted successfully.",
          showConfirmButton: true,
        });
      }
    });
  }
    
  const onChangeArtFile = async (event: any) => {

    console.log('onChangeArtFile --> 1');

    let [file] = event.target.files;
    if (!file) return;
    
    console.log(file.name);
    
    const fileExtention = file.name.split('.').pop();
    const expectedFileExtentions = ['png','jpg','jpeg'];

    if(!expectedFileExtentions.includes(fileExtention.toLowerCase())) {
        Swal.fire({
            icon: "error",
            text: "Does not meet requirements. PNG and JPG file types accepted.",
            showConfirmButton: true,
        });
        
        event.target.value = null;

      return;
    }
    
    // Make sure `file.size` does not 0 size
    if (file.size < 5) {
      Swal.fire({
        icon: "error",
        text: "Sorry your file is too small! Please make sure your image size is greater than 0MB.",
        showConfirmButton: true,
      });
      
      event.target.value = null;

      return;
    }
    
//    console.log(file.size);
    
    // Make sure `file.size` does not exceed 15MB
    if (file.size > 15000000) {
      Swal.fire({
        icon: "error",
        text: "Sorry your file is too large! Please limit your image size to less than 15MB.",
        showConfirmButton: true,
      });
      
      // event.target.value = null;

      return;
    }

    const artPreviewDetails = await _generatePreviewImg(file) as { data: string };
    setArtPreviewImg(artPreviewDetails.data);
    setIsFileUploaded(true);
  };

  const _generatePreviewImg = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const loadImg = () => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);

        const image = new Image();
        image.src = reader.result as string;

        image.onload = function() {
            // access image size here 
            // const dimension = { width: image.width, height: image.height } as Dimension;

            resolve({ data: reader.result });
        };
      };

      const loadError = (event: any) => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        reject(event);
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", loadImg);
      reader.addEventListener("error", loadError);
    });
  };

  const clearImage = () => {
    setArtPreviewImg('');
    setIsFileUploaded(false);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if(!artworkSubmissionForm.current) {
      return;
    }

    const { elements } = artworkSubmissionForm.current;
    const inputsDOM = Array.from(elements) as HTMLInputElement[];

    const formData = new FormData();

    inputsDOM.forEach((el) => {
      console.log(el);

      const { files, name, value } = el ;
      if (files) {
        formData.append(name, files[0]);
      } else if (value) {
        formData.append(name, value);
      }
    });

    onLoading();

    if(isNew) {
      try {
        const {
          data: { res },
        } = await axios.post(`api/admin/settings/dashboard/add-fan-favorite`, formData, {
          headers: {
            Authorization: `JWT ${token}`,
          },
        })

        showSuccessAndRefresh();

      } catch(e: any) {
        showErrorMassage(e.response.data.message);
      }
    } else {
      if(!isFileUploaded) {
        updateFanFavorite();
      } else {

        if(selectedFanFavorite) {
          formData.append('id', selectedFanFavorite.id.toString());
        }

        try {
          const {
            data: { res },
          } = await axios.post(`api/admin/settings/dashboard/update-fan-favorite-file`, formData, {
            headers: {
              Authorization: `JWT ${token}`,
            },
          });

          showSuccessAndRefresh();
        } catch(e: any) {
          showErrorMassage(e.response.data.message);
        }
      }
    }
  }

  const showErrorMassage = (msg: string) => {
    Swal.fire({
      icon: "error",
      text: msg,
      showConfirmButton: true,
    });

    onLoading(false);
  }

  const showSuccessAndRefresh = () => {
    setIsOpen(false);
    onRefresh();

    Swal.fire({
      icon: "success",
      text: "Fan favorite saved successfully.",
      showConfirmButton: true,
    });
  }

  // const images = require.context('../../../../../uploads/favorites/', true);

  return <>
    <WhatsSectionTitle style={{marginTop: "50px"}}>RECENT FAN FAVORITES</WhatsSectionTitle>
    <FavoriteSection>
      { props.fanFavorites.map((favorite) => ( 
          <FavoriteTile>
            <div>
              <ImageCard src={getImageSrcURL(favorite.image_path)}/>
            </div>
            <div style={{padding: "10px 10px 0px 10px", fontWeight: "bold", color: "#555", wordBreak: "break-word"}}>{favorite.title}</div>
            <div style={{padding: "0 10px", color: "#6CB6BB", fontWeight: "bold", display: 'inline-block', wordBreak: "break-word" }}>{favorite.artist_name}</div>
            <div style={{ display: 'inline-block', marginLeft: "10px" }}>
                <EditIcon style={{ cursor: 'pointer' }} onClick={() => editFanFavorite(favorite.id)}/>
                <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => deleteFanFavorite(favorite.id)}/>
              </div>
          </FavoriteTile>
      ))}
    </FavoriteSection>

    <div style={{textAlign: 'center', marginTop: '60px'}}>
      <MainButton 
          type="button" 
          style={{width: "200px", cursor: "default", fontSize: "18px", cussor: 'pointer', margin: 'auto' }} 
          onClick={addFanFavorite}>
            Add New
        </MainButton>
    </div>

    <Dialog onClose={handleClose} open={isOpen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}></DialogTitle>
        <DialogContent dividers style={{textAlign: 'center'}}>
          <h2 style={{marginTop: "-20px"}}>{ getDialogTitle() }</h2>

          <FormArtistSubmit
            onSubmit={handleSubmit}
            ref={artworkSubmissionForm}
          >
            <PreviewImageWrapper style={{display: artPreviewImg ? 'block' : 'none', textAlign: "center"}}>
                <PreviewImage style={{maxHeight: "250px"}}
                  src={ artPreviewImg }
                  alt="Art Preview"
                />
            </PreviewImageWrapper>
            <label htmlFor="preview-art" style={{display: artPreviewImg ? 'none' : 'block'}}>
              <InputArtPreview
                id="preview-art"
                type="file"
                name="artFile"
                onChange={onChangeArtFile}
                textAlign="center"
                accept="image/png,image/jpeg"
              >
              <ArtPreview>
                <IconContainer>
                  <Upload />
                </IconContainer>
                <IconTopSubtitle>Click to upload images</IconTopSubtitle>
              </ArtPreview>
          </InputArtPreview>
          </label>

          <ButtonSm type="button" style={{ display: artPreviewImg ? 'initial' : 'none', marginBottom: '20px' }} onClick={clearImage}>Clear Image</ButtonSm>

          <FormInputArtistStyled
            type="text"
            name="title"
            label="title"
            value={selectedFanFavorite?.title}
            style={{ fontSize: '16px', width: "500px", cursor: "default" }}
            onChange={handleChange}
            placeholder="Please enter title..."
          />

          <FormInputArtistStyled
            type="text"
            name="artist_name"
            label="artist_name"
            value={selectedFanFavorite?.artist_name}
            style={{ fontSize: '16px', width: "500px", cursor: "default" }}
            onChange={handleChange}
            placeholder="Please enter artist name..."
          />

          <MainButton 
            type="submit" 
            style={{width: "200px", cursor: "default", fontSize: "24px", cussor: 'pointer', marginBottom: "20px" }}>
              Save
          </MainButton>
        </FormArtistSubmit>
      </DialogContent>
    </Dialog>
  </>;
};

export default FanFavorite;
