import React, { useState, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken, selectUserAccount } from "../../redux/user/user.selector";
import { ButtonSm, SettingsButton } from "../Button";
import { SITE_BACKEND_API_URL } from "../../server";
import Swal from "sweetalert2";
import { Loader } from "../Common";
import './styles.css';

import {
  SubmissionContainer,
  TabArea,
  TabHeader,
  TabSubLink,
  TabSubTitle,
  TabTitle,
} from "../SharedStyle/styled";
import { UserRole } from "../../constants";


const payoutElements = [
  { lable: '$0.00 - $10.00', key: 'price_0_10' },
  { lable: '$11.00 - $20.00', key: 'price_11_20' },
  { lable: '$21.00 - $40.00', key: 'price_21_40' },
  { lable: '$41.00 - $60.00', key: 'price_41_60' },
  { lable: '$61.00 - $80.00', key: 'price_61_80' },
  { lable: '$81.00 +', key: 'price_81_plus' },
];

interface AdminPayoutProps {
  token: string;
}

type TPayout = Record<string, number>;
  
const AdminPayout = ({ token }: AdminPayoutProps) => {
  const [payouts, setPayouts] = useState<TPayout>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCommissionsPayout = async () => {
      const reqBody = {
        url: "api/admin/settings/payouts/list",
        method: "GET",
        headers: { Authorization: `JWT ${token}` },
      } as AxiosRequestConfig;

      const {
        data: { payouts },
      } = await axios(reqBody);

      setPayouts(payouts);
      setIsLoading(false);

    };
    getCommissionsPayout();
  }, [token]);

  const handleOnChange = (event: any, key: string) => {
    setPayouts({...payouts, [key]: parseFloat(event.target.value) });
  }

  const handleUpdate = async (rowData: any) => {
    setIsLoading(true);

    const reqBody = {
      url: "api/admin/settings/payouts/update",
      method: "PUT",
      headers: { Authorization: `JWT ${token}` },
      data: { payouts },
    } as AxiosRequestConfig;

    await axios(reqBody);

    setIsLoading(false);
    Swal.fire({
      icon: "success",
      text: "Payout updated successfully.",
      showConfirmButton: true,
    });
  };

  return (
    <SubmissionContainer>
      <TabHeader>
        <TabSubLink to={`/admin/commissions`}>
          <TabSubTitle>Commissions by Order</TabSubTitle>
        </TabSubLink>
        <TabSubLink to={`/admin/commissions/by-artist`}>
          <TabSubTitle>Commissions by Artist</TabSubTitle>
        </TabSubLink>
        <TabTitle>Payouts</TabTitle>
        <TabSubLink to={`/admin/commissions/errors`}>
          <TabSubTitle>Commissions Errors</TabSubTitle>
        </TabSubLink>
      </TabHeader>
      <TabArea>
        <h2 style={{textAlign: 'center'}}>Commissions Payout Data Mapping</h2>
        {isLoading ? <Loader /> : 
          <form>
            <table className="payout-price-table">
              <tr>
                <th>Price Paid</th>
                <th>Commission Amount</th>
              </tr>
              { payoutElements.map((p, i) => <tr>
                <td>{p.lable}</td>
                <td>
                    <input 
                      className="input-payout" 
                      type="number" 
                      value={payouts[p.key]} 
                      min="0" 
                      max="20" 
                      maxLength={2}
                      onChange={(e) => handleOnChange(e, p.key)}
                    ></input>
                  </td>
              </tr>
               )}
            </table>

            <SettingsButton
              textAlign='center'
              type="button"
              style={{
                width: "200px",
                borderRadius: "4px",
                marginTop: '20px',
                fontSize: "20px"
              }}
              onClick={handleUpdate}
            >
                Save
            </SettingsButton>
          </form>
          }
      </TabArea>
    </SubmissionContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(AdminPayout);
