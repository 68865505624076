import { all, call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";

import SettingActionTypes from "./settings.types";
import { setConfigSuccess } from "./settings.action";
import { selectUserJWTToken } from "../user/user.selector";
import { ODAD_AUTOMATION_API_URL } from "../../server";

export function* getConfig() {

  const token = yield select(selectUserJWTToken);
  
  const { data } = yield axios.get("api/admin/settings/common/list", {
    headers: { Authorization: `JWT ${token}` },
  });

  const dataTags = yield axios.get(ODAD_AUTOMATION_API_URL+"/getAllTags");
  data.suggestedTags = dataTags.data.tags;

  yield put(setConfigSuccess(data));
}

export function* onSetConfigStart() {
  yield takeLatest(SettingActionTypes.SET_CONFIG_START, getConfig);
}

export function* settingsSaga() {
  yield all([
    call(onSetConfigStart),
  ]);
}
