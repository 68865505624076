import React from "react";
import {
  FormStyled,
  FormInputContainer,
  InputStyled,
  TextAreaStyled,
  SerialNumberStyled
} from "./form-input.styles";

import { Select, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const Form = ({ children, ...props }) => (
  <FormStyled {...props}>{children}</FormStyled>
);

export const Input = ({ ...props }) => <InputStyled {...props} />;

export const FormInput = ({
  handleChange,
  label,
  isShowLabel,
  ...otherProps
}) => (
  <FormInputContainer>
    {label ? (
      <label className={isShowLabel ? "form-label" : "display-none"}>
        {label}
      </label>
    ) : null}
    <InputStyled
      className="form-input"
      onChange={handleChange}
      {...otherProps}
    />
  </FormInputContainer>
);

export const TextArea = ({
  handleChange,
  label,
  isShowLabel,
  ...otherProps
}) => (
  <FormInputContainer>
    {label ? (
      <label className={isShowLabel ? "form-label" : "display-none"}>
        {label}
      </label>
    ) : null}
    <TextAreaStyled
      className="form-input"
      onChange={handleChange}
      {...otherProps}
    />
  </FormInputContainer>
);

export const SectionHeading = ({ serialNumber, label }) => (
    <h4 style={{ color: "#6A6A6A", display: "inline-block" }}>
        <SerialNumberStyled>{serialNumber}</SerialNumberStyled>
        {label}
    </h4> 
); 

export const StyledSelect = withStyles({
  root: {
    borderRadius: "20px!important",
    border: "2px solid #6CB6BB",
    padding: "5px 12px",
  }
  
})(Select);

export const StyledMultiSelectCheckbox = withStyles({
  root: {
    padding: "0px 5px",
  },
  
  colorSecondary: {
    color: "#6CB6BB!important"
  }
  
})(Checkbox);