import React, { AnchorHTMLAttributes, DetailedHTMLProps } from "react";
import { ReportContainer, ReportHeading } from "./admin-dashboard.styles"
import { Button } from "@material-ui/core";
import InfoOutlinedfrom from '@material-ui/icons/InfoOutlined';

interface ReportInterface {
    title: string;
    headings: string[];
    list: { [key: string]: string; }[];
    width: number;
    popover: any;
}

export const Report = ({title, headings, list, width, popover}: ReportInterface) => {
    return <ReportContainer style={{ width: width+"%" }}>
        <ReportHeading>
            {title}
            { popover ? (<Button onClick={popover} style={{color: "#fff"}}><InfoOutlinedfrom /></Button>) : null }
        </ReportHeading>

        <table style={{ width: "100%", backgroundColor: "#DDD"}}>
            <tr>{ headings.map((heading) =><th>{heading}</th>)}</tr>
            
            { list.length ?
             list.map((row) => {
                return <tr style={{textAlign: "center"}}>
                     {Object.keys(row).map(function(key) {
                        return <td>{row[key]}</td>;
                    })}
                </tr>
            }): <tr><td colSpan={10} style={{textAlign: "center", color: "red"}}>No Record Found</td></tr>}
        </table>
    </ReportContainer>
}