import React from "react";
import { Switch, Route } from "react-router-dom";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectUserAccount } from "./redux/user/user.selector";

import {
  SignUpPage,
  SignInPage,
  CreateArtistPage,
  ForgotPasswordPage,
  ResetPasswordPage,
} from "./pages/SigninSignupPage/signin-signup-page.component";
import ArtistProfilePage from "./pages/ArtistProfilePage/artist-profile-page.component";
import ArtistSubmissionPage from "./pages/ArtistSubmissionPage";
import ArtistCommissions from "./pages/ArtistCommissions";
import HomePage from "./pages/HomePage";
import DashboardPage from "./pages/ArtistDashboardPage";
import HelpPage from "./pages/HelpPage";
import TermsAndConditions from "./pages/TermsConditionPage";

import AdminProfile from "./pages/AdminProfilePage";
import AdminArtSubmissionsPage from "./pages/AdminArtSubmissions";
import AdminCommissionsPage from "./pages/AdminCommissions";
import AdminReportingPage from "./pages/AdminReporting";
import AdminArtistsProfilesPage from "./pages/AdminArtistsProfilesPage";
import AdminSettingsPage from "./pages/AdminSettingsPage";
import AdminCalendarPage from "./pages/AdminCalendarPage";
import PrivateRoute from "./components/PrivateRoute";

import { GlobalStyle } from "./GlobalStyle";

function App(props) {

  const authed = props.userAccount ? true : false;

  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/signin" component={SignInPage} />
        <Route exact path="/signin/:token" component={SignInPage} />
        <Route exact path="/artist/dashboard" component={DashboardPage} />

        <Route exact path="/signup" component={SignUpPage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
        <Route exact path="/help" component={HelpPage} />
        <Route path="/reset-password/:token" component={ResetPasswordPage} />

        {/* Artist Routes */}
        <Route exact path="/artist/create" component={CreateArtistPage} />
        <Route exact path="/artist/profile" component={ArtistProfilePage} />

        {/*<Route path="/artist/submissions" component={ArtistSubmissionPage} />*/}
        <PrivateRoute authed={authed} path='/artist/submissions' component={ArtistSubmissionPage} />

        <Route exact path="/artist/commissions" component={ArtistCommissions} />
        <Route exact path="/artist/notifications" component={ArtistProfilePage} />

        {/* Admin Routes TODO: Secure Routes */}
        {/* <Route path="/admin/profile" component={AdminProfile} /> */}
        <PrivateRoute authed={authed} path='/admin/profile' component={AdminProfile} />

        {/* <Route path="/admin/art-submissions" component={AdminArtSubmissionsPage}/> */}
        <PrivateRoute authed={authed} path='/admin/art-submissions' component={AdminArtSubmissionsPage} />

        {/* <Route path="/admin/commissions" component={AdminCommissionsPage} /> */}
        <PrivateRoute authed={authed} path='/admin/commissions' component={AdminCommissionsPage} />

        {/* <Route path="/admin/reporting" component={AdminReportingPage} /> */}
        <PrivateRoute authed={authed} path='/admin/reporting' component={AdminReportingPage} />

        {/* <Route path="/admin/artists-profiles" component={AdminArtistsProfilesPage} /> */}
        <PrivateRoute authed={authed} path='/admin/artists-profiles' component={AdminArtistsProfilesPage} />

        {/* <Route path="/admin/settings" component={AdminSettingsPage} /> */}
        <PrivateRoute authed={authed} path='/admin/settings' component={AdminSettingsPage} />

        <PrivateRoute authed={authed} path='/admin/calendar' component={AdminCalendarPage} />

      </Switch>
    </>
  );
}

// export default App;

const mapStateToProps = createStructuredSelector({
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(App);
