import SettingActionTypes from "./settings.types";

export const setConfigStart = () => ({
  type: SettingActionTypes.SET_CONFIG_START
});

export const setConfigSuccess = (data) => ({
  type: SettingActionTypes.SET_CONFIG_SUCCESS,
  payload: data
});

export const clearAllConfig = () => ({
  type: SettingActionTypes.CLEAR_ALL_CONFIG
});