import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useParams } from "react-router-dom";

import { SITE_BACKEND_API_URL } from "../../server";

import { selectUserJWTToken } from "../../redux/user/user.selector";
import { selectSubmissionsError } from "../../redux/submissions/submissions.selector";

import teefuryBirdLogo from "../../assets/teefury-bird.jpg";
import HomeArtCard from "./artist-home-submissions-card.component";
import ArtistSubmissionsEdit from "../ArtistSubmissionsView";
import { ButtonLgCenterGray } from "../Button";
import { Loader } from "../Common";
import NoRecentSubmissionsImage from "../../assets/no-recent-submissions.png";
import {
  NoRecordImgWrapper,
  NoRecordImg,
} from "./artist-home.styles";

import {
  SmallSubmissionContainer,
  TabHeader,
  TabSubLink,
  HomeTabArea,
  ArtCardContainer,
} from "../SharedStyle/art-submissions.styles";

const ArtistHomeSubmissions = (props) => {
    
  const { token } = props;
    
  const params = useParams();
  const [state, setState] = useState({
    search: "",
    isShowingFilter: false,
    status: "NEW",
    imageSrc: teefuryBirdLogo,
    submissionsArr: [],
    submissionsArrIndex: 0,
    submissionCard: null,
    id: 0,
    isSubmissionsEdit: false,
    isFlipLeftDisabled: false,
    isFlipRightDisabled: false,
    loading: true,
  });
  const {
    status,
    submissionsArr,
    submissionsArrIndex,
    id,
    isSubmissionsEdit,
    isFlipLeftDisabled,
    isFlipRightDisabled,
    loading,
  } = state;

  useEffect(
    () => {
      setState({
        ...state,
        submissionsArr: [],
      });

      const status = _getCurrentPath();
      _getSubmissions(status);
    },
    // eslint-disable-next-line
    [params]
  );

  const _getCurrentPath = () => {
    // const status = params.status.toUpperCase();

    return status;
  };

  // const toggleFilterArea = () => {
  //   const { isShowingFilter } = state;
  //   setState({ ...state, isShowingFilter: !isShowingFilter });
  // };

  const _getSubmissions = async (status) => {
    const {
      data: { submissionsDetailsArr },
    } = await axios.get(SITE_BACKEND_API_URL+`/artist/submissions/list/${status}`, {
      headers: { Authorization: `JWT ${token}` },
    });

    setState({
      ...state,
      status,
      isShowingFilter: false,
      submissionsArr: submissionsDetailsArr,
      isSubmissionsEdit: false,
      loading: false
    });
    
    props.openViewArtHandler(false);
  };

  const openSubmissionsEdit = (event) => {
    const { id } = event.currentTarget;
    const index = parseInt(event.target.getAttribute("data-index"), 10);
    const isFlipLeftDisabled = index === 0 ? true : false;
    const isFlipRightDisabled =
      index === submissionsArr.length - 1 ? true : false;

    setState({
      ...state,
      id: id,
      submissionsArrIndex: index,
      isSubmissionsEdit: true,
      isFlipLeftDisabled,
      isFlipRightDisabled,
    });
    
    props.openViewArtHandler(true);
  };

  const closeSubmissionsView = () => {
    setState({
      ...state,
      isSubmissionsEdit: false,
      submissionsArr: [],
    });
    
    props.openViewArtHandler(false);

    const status = _getCurrentPath();
    _getSubmissions(status);
  };

  const flipLeft = () => {
    if (submissionsArrIndex > 0) {
      let previousIndex = submissionsArrIndex - 1;
      const newCardId = submissionsArr[previousIndex].id;
      let isFlipLeftDisabled = previousIndex === 0 ? true : false;

      setState({
        ...state,
        id: newCardId,
        submissionsArrIndex: previousIndex,
        isFlipLeftDisabled,
        isFlipRightDisabled: false,
      });
    }
  };

  const flipRight = () => {
    if (submissionsArrIndex < submissionsArr.length - 1) {
      let nextIndex = submissionsArrIndex + 1;
      const newCardId = submissionsArr[nextIndex].id;
      const isFlipRightDisabled =
        nextIndex === submissionsArr.length - 1 ? true : false;

      setState({
        ...state,
        id: newCardId,
        submissionsArrIndex: nextIndex,
        isFlipLeftDisabled: false,
        isFlipRightDisabled,
      });
    }
  };

  const size = 9;
  const newArray = submissionsArr ? submissionsArr.slice(0, size) : [];
  return (
      <HomeTabArea style={{ 
        padding: "0px", 
        paddingBottom: "30px", 
        height: "90%", 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center' 
        }}>
        {isSubmissionsEdit ? (
          <ArtistSubmissionsEdit
            id={id}
            closeSubmissionsView={closeSubmissionsView}
            flipLeft={flipLeft}
            flipRight={flipRight}
            isFlipLeftDisabled={isFlipLeftDisabled}
            isFlipRightDisabled={isFlipRightDisabled}
          />
        ) : (
          <ArtCardContainer>
            {submissionsArr && submissionsArr.length > 0 ? (
              newArray.map((submissionDetails, i) => {
                return (
                  <HomeArtCard
                    key={i}
                    {...submissionDetails}
                    token={token}
                    index={i}
                    openSubmissionsEdit={openSubmissionsEdit}
                  />
                );
              })
            ) : loading ? ( <Loader /> ) :(
              <TabSubLink to={`/artist/submissions`}>
                <NoRecordImgWrapper>
                  <NoRecordImg src={NoRecentSubmissionsImage}/>
                </NoRecordImgWrapper>
              </TabSubLink>
            )}
          </ArtCardContainer>
        )}
      {submissionsArr && submissionsArr.length > 0 ? (
        <ButtonLgCenterGray style={{ padding: '10px 40px', borderRadius: "40px", marginTop: "20px", backgroundColor: "#cde6e8" }}>
          <TabSubLink to={`/artist/submissions/new`} style={{color: '#6CB6BB'}}>
            View All
          </TabSubLink>
        </ButtonLgCenterGray>
      ) : null}
      </HomeTabArea>
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  submissionsError: selectSubmissionsError,
});

export default connect(mapStateToProps)(ArtistHomeSubmissions);
