import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserAccount } from "../../redux/user/user.selector";

import clsx from "clsx";

import CheckIcon from "@material-ui/icons/Check";
import NotInterestedRoundedIcon from "@material-ui/icons/NotInterestedRounded";
import IconButton from "@material-ui/core/IconButton";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { UserRole } from "../../constants";
import { DownloadButton } from "../Common";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  iconPaid: {
    color: "green",
  },
}));

const TealCheckbox = withStyles({
  root: {
    color: "#64add1",
    "&$checked": {
      color: "#64add1",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const TableToolbar = ({
  numSelected,
  markedAsPaid,
  markedAsUnpaid,
  markedAllAsPaid,
  markedAllAsUnpaid,
  exportCSV,
  totalRecords,
  checkedExcludeTags,
  handleCheckboxChange,
  userAccount,
  isMainCommissionTable,
}) => {

  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {userAccount.userRole !== UserRole.ADMIN_VIEW_ONLY && numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Commissions: <small>{`${totalRecords} Total Records`}</small>
          {totalRecords ? (
          <>
            <Tooltip title="Paid All">
              <IconButton style={{fontSize: "16px"}} aria-label="paid" onClick={markedAllAsPaid}>
                <CheckIcon fontSize={"medium"} className={classes.iconPaid} />Paid All
              </IconButton>
            </Tooltip>
            <Tooltip title="Unpaid All">
              <IconButton style={{fontSize: "16px"}} aria-label="unpaid" onClick={markedAllAsUnpaid}>
                <NotInterestedRoundedIcon fontSize={"medium"} color={"error"} />Unpaid All
              </IconButton>
            </Tooltip>
            </>
          ) : (null)}
        </Typography>
      )}

      {userAccount.userRole !== UserRole.ADMIN_VIEW_ONLY && numSelected > 0 ? (
        <>
          <Tooltip title="Paid">
            <IconButton aria-label="paid" onClick={markedAsPaid}>
              <CheckIcon fontSize={"large"} className={classes.iconPaid} /> Paid
            </IconButton>
          </Tooltip>
          <Tooltip title="Unpaid">
            <IconButton aria-label="unpaid" onClick={markedAsUnpaid}>
              <NotInterestedRoundedIcon fontSize={"large"} color={"error"} />
              Unpaid
            </IconButton>
          </Tooltip>
        </>
      ) : (
        isMainCommissionTable ? (
        <div
          style={{ display: "flex", flexDirection: "row", minWidth: "300px" }}
        >
          <FormControlLabel
            control={
              <TealCheckbox
                checked={checkedExcludeTags}
                onChange={handleCheckboxChange}
                name="checkedExcludeTags"
              />
            }
            label="Exclude Tags"
            style={{ minWidth: "150px" }}
          />
          {totalRecords ? (
          <Tooltip title="CSV">
            <DownloadButton title="Export CSV" handleDownload={exportCSV}/>
          </Tooltip>
          ) : (null)}
        </div>
        ) : ""
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  markedAsPaid: PropTypes.func.isRequired,
};

// export default TableToolbar;

const mapStateToProps = createStructuredSelector({
  userAccount: selectUserAccount,
});

export default connect(mapStateToProps)(TableToolbar);
