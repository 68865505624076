import React, { useState, useEffect } from "react";
import axios from "axios";
import { SITE_BACKEND_API_URL } from "../../server";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";
import { selectExcludeProducts } from "../../redux/settings/settings.selector";
import { setConfigStart } from "../../redux/settings/settings.action";

import PayoutTable from "../AdminPayout/payoutTable.component";
import Swal from "sweetalert2";

const ExcludeProductSettings = ({ token, excludeProducts, refreshSettings }) => {

  const tableColumn = [
    { title: "Product Name", field: "product_name", validate: rowData => rowData.product_name !== '' },
  ];

  const handleRowAdd = async (rowData) => {
      
      if(!rowData.hasOwnProperty("product_name")) {
          Swal.fire({icon: "error", text: "Product name can not be empty!"});
          return false;
      }
      
    const reqBody = {
      url: "api/admin/settings/exclude-products/add",
      method: "POST",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    const {
      data: { tableRowData },
    } = await axios(reqBody);

    refreshSettings();

    return tableRowData;
  };

  const handleRowUpdate = async (rowData) => {
      
      if(rowData.product_name === "") {
          Swal.fire({icon: "error", text: "Product name can not be empty!"});
          return false;
      }
      
    const reqBody = {
      url: "api/admin/settings/exclude-products/update",
      method: "PUT",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    const {
      data: { tableRowData },
    } = await axios(reqBody);

    refreshSettings();

    return tableRowData;
  };

  const handleRowDelete = async (rowData) => {
    const reqBody = {
      url: "api/admin/settings/exclude-products/delete",
      method: "DELETE",
      headers: { Authorization: `JWT ${token}` },
      data: {
        rowData,
      },
    };

    await axios(reqBody);
    
    refreshSettings();

    return true;
  };

  return (
    <div>
      <PayoutTable
        tableRowsData={excludeProducts}
        tableColumn={tableColumn}
        handleRowUpdate={handleRowUpdate}
        handleRowDelete={handleRowDelete}
        handleRowAdd={handleRowAdd}
        title="Product Exclusions"
      />
    </div>
    
  );
};

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
  excludeProducts: selectExcludeProducts,
});

const mapDispatchToProps = (dispatch) => ({
  refreshSettings: () => dispatch(setConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExcludeProductSettings);
